import { Component, effect, inject, OnInit, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from "@angular/material/icon";
import { TilbyCurrencyPipe } from "@tilby/tilby-ui-lib/pipes/tilby-currency";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { BookingsStateService } from '../../services/bookings.state.service';
import { TilbyDatePipe } from '@tilby/tilby-ui-lib/pipes/tilby-date';
import { MatDatepickerInputEvent, MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

@Component({
    selector: 'app-booking-topbar',
    standalone: true,
    imports: [CommonModule, MatIconModule, TilbyCurrencyPipe, TranslateModule, MatMenuModule, MatButtonModule, MatDatepickerModule, MatFormFieldModule, MatInputModule],
    templateUrl: './booking-topbar.component.html',
    styleUrls: ['./booking-topbar.component.scss'],
    host:{class:'tw-flex tw-flex-auto tw-justify-end'}
})
export class BookingTopbarComponent implements OnInit {

    private readonly bookingsStateService = inject(BookingsStateService);
    private readonly tilbyDatePipe = inject(TilbyDatePipe);
    private readonly translateService = inject(TranslateService);

    period : string = '';

    typeBooking = 1;
    dateBooking : Date = TilbyDatePipe.date({ outputFormat: 'date' });

    labelButtonMenu = '';
    isMobile = signal(this.bookingsStateService.isMobile());

    isActualDate = true;

    constructor() {
        effect(() => {
            if (this.bookingsStateService.selectBookingsData()) {
                this.typeBooking = this.bookingsStateService.selectBookingsData().type;
                this.dateBooking = this.bookingsStateService.selectBookingsData().date;
                this.isActualDate = this.tilbyDatePipe.transform(this.dateBooking,  'dd MMM yyyy', 'UTC') === this.tilbyDatePipe.transform(TilbyDatePipe.date({ outputFormat: 'date' }),  'dd MMM yyyy');
                this.updatePeriod();
                this.updateLabelButtonMenu();
            }
        });
    }

    ngOnInit(): void {
        this.dateBooking = this.bookingsStateService.selectBookingsData().date;
        this.updateData();
    }

    updateData() {
        this.isActualDate = this.tilbyDatePipe.transform(this.dateBooking,  'dd MMM yyyy', 'UTC') === this.tilbyDatePipe.transform(TilbyDatePipe.date({ outputFormat: 'date' }),  'dd MMM yyyy');
        this.updatePeriod();
        this.updateLabelButtonMenu();
        this.bookingsStateService.selectBookingsData.set({
            date: this.dateBooking,
            type: this.typeBooking,
        });
    }

    updatePeriod() {
        if (this.typeBooking === 0) {
            this.period = this.getWeekInfo();
        } else {
            this.period = this.tilbyDatePipe.transform(this.dateBooking,  'dd MMM yyyy', 'UTC') || '';
        }
    }

    updateLabelButtonMenu() {
        this.labelButtonMenu = this.typeBooking === 0 ?  this.translateService.instant("BOOKINGS.TOPBAR_CALENDAR.TIMEGRID_WEEK") : this.typeBooking === 1 ? this.translateService.instant("BOOKINGS.TOPBAR_CALENDAR.LIST") : this.translateService.instant("BOOKINGS.TOPBAR_CALENDAR.TIMELINE") ;
    }

    getWeekInfo() {
        let a = this.getWeekRange(this.dateBooking);
        const isEqualsMonth = a.start.getMonth() === a.end.getMonth();
        const isEqualsYear = a.start.getFullYear() === a.end.getFullYear();
        const patternStartWeek = 'dd' + (!isEqualsMonth ? ' MMM' : '') + (!isEqualsYear ? ' yyyy' : '');
        let start = this.tilbyDatePipe.transform(a.start,  patternStartWeek, 'UTC');
        let end = this.tilbyDatePipe.transform(a.end,  'dd MMM yyyy', 'UTC');

        this.bookingsStateService.weekDaysRange = {
            start: a.start,
            end: a.end
        };
        return start + ' - ' + end;
    }

    getWeekRange(date: Date): { start: Date, end: Date } {
        const dayOfWeek = date.getDay();
        const diffToMonday = dayOfWeek === 0 ? 6 : dayOfWeek - 1;
        const startOfWeek = new Date(date);
        startOfWeek.setDate(date.getDate() - diffToMonday);
        const endOfWeek = new Date(startOfWeek);
        endOfWeek.setDate(startOfWeek.getDate() + 6);

        return {
          start: startOfWeek,
          end: endOfWeek
        };
    }

    menuAction(typeBooking : number) {
        this.typeBooking = typeBooking;
        this.updateData();
    }

    actualDayAction() {
        const currentDate = this.tilbyDatePipe.transform(TilbyDatePipe.date(), 'YYYY-MM-dd');
        this.dateBooking = TilbyDatePipe.date({outputFormat: 'date', date: currentDate || ''});
        this.dateBooking.setUTCHours(0, 0, 0, 0);
        this.updateData();
        this.bookingsStateService.updateBookings();
    }

    previousDayAction() {
        this.dateBooking.setDate(this.dateBooking.getDate() + (this.typeBooking !== 0 ? -1 : -7));
        this.updateData();
        this.bookingsStateService.updateBookings();
    }

    laterDayAction() {
        this.dateBooking.setDate(this.dateBooking.getDate() + (this.typeBooking !== 0 ? +1 : +7));
        this.updateData();
        this.bookingsStateService.updateBookings();
    }

    addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
        this.dateBooking = this.bookingsStateService.convertToUTC(event.value as Date);
        this.updateData();
        this.bookingsStateService.updateBookings();
    }
}
