import { CommonModule } from "@angular/common";
import { Component, Input, effect, inject, signal } from "@angular/core";
import { EnvironmentInfoService, ModuleEvents, ToolbarEventsService } from "src/app/core";
import { BookingListComponent } from "./components";
import { TilbyToolbar } from "src/app/models";
import { OnDestroyService } from "src/app/core/services/on-destroy.service";
import { BookingTopbarComponent } from "./components/booking-topbar";
import { BookingsDataInput, BookingsStateService } from "./services/bookings.state.service";
import { TilbyDatePipe } from "@tilby/tilby-ui-lib/pipes/tilby-date";
import { BookingWeekComponent } from "./components/booking-week";
import { BookingTimelineComponent } from "./components/booking-timeline";
import { Bookings, BookingShifts, Rooms } from "tilby-models";
import { AddEditNewBookingDialogService } from "src/app/dialogs/bookings/add-edit-new-booking-dialog/add-edit-new-booking-dialog.component";
import { DownloadBookingDialogService } from "src/app/dialogs/bookings/download-booking-dialog/download-booking-dialog.component";

@Component({
    selector: 'app-bookings',
    templateUrl: './bookings.component.html',
    styleUrls: ['./bookings.component.scss'],
    imports: [
        CommonModule,
        BookingListComponent,
        BookingWeekComponent,
        BookingTimelineComponent,
        TilbyDatePipe
    ],
    providers: [OnDestroyService],
    standalone: true,
})
export class BookingsComponent implements TilbyToolbar {

    private readonly tilbyDatePipe = inject(TilbyDatePipe);
    private readonly onDestroyService = inject(OnDestroyService);
    private readonly bookingsStateService = inject(BookingsStateService);
    private readonly toolbarEventsService = inject(ToolbarEventsService);
    private readonly environmentInfoService = inject(EnvironmentInfoService);
    private readonly downloadBookingDialogService = inject(DownloadBookingDialogService);
    private readonly addEditBookingDialogService = inject(AddEditNewBookingDialogService);

    @Input() rooms: Rooms[] = [];
    @Input() bookings: Bookings[] = [];
    @Input() bookingShifts: BookingShifts[] = [];

    isMobile = signal(this.bookingsStateService.isMobile());

    data : BookingsDataInput = this.bookingsStateService.selectBookingsData();

    constructor() {
        effect(() => {
            this.data = this.bookingsStateService.selectBookingsData();
            if (this.bookingsStateService.selectBookingsData()) {
                this.createToolbarButtons();
            }
        });
    }

    ngOnInit(): void {
        this.setToolbar();
        this.createToolbarButtons();
        this.bookingsStateService.changeBookingDate(this.initDate());

        //set bookings into booking state service for every components
        if(this.bookings) {
            this.bookingsStateService.bookingData.set(this.bookings);
            this.bookingsStateService.bookings = this.bookings;
        }
    }

    initDate(): Date {
        const currentStringDate = this.tilbyDatePipe.transform(TilbyDatePipe.date(), 'YYYY-MM-dd');
        let currentDate = TilbyDatePipe.date({outputFormat: 'date', date: currentStringDate || ''});
        currentDate.setUTCHours(0, 0, 0, 0);
        return currentDate;
    }

    createToolbarButtons(): void {
        this.toolbarEventsService.buttons$.next({
            barButtons: [
                {
                    isIt: signal(!this.isMobile()),
                    isAccessibility: signal(false),
                    name: 'add_edit_booking',
                    iconType: 'symbols',
                    icon: signal('calendar_add_on'),
                    click: (_: any) => this.toolbarEventsService.events.next({add_edit_booking: true}),
                },
                {
                    isIt: signal(!this.isMobile() && this.bookingsStateService.selectBookingsData().type === 1 && this.environmentInfoService.canDownloadFiles()),
                    isAccessibility: signal(false),
                    name: 'download',
                    icon: signal('download'),
                    click: (_: any) => this.toolbarEventsService.events.next({booking_download: true}),
                },
                {
                    isIt: signal(!this.isMobile()),
                    isAccessibility: signal(false),
                    name: 'settings',
                    icon: signal('settings'),
                    click: (_: any) => this.toolbarEventsService.events.next({bookings_settings: true}),
                }
            ],
            panelButtons: [
                {
                    isIt: signal(this.isMobile()),
                    name: 'view_week',
                    icon: signal('event'),
                    label: signal('BOOKINGS.TOPBAR_CALENDAR.TIMEGRID_WEEK'),
                    click: (_: any) => this.toolbarEventsService.events.next({view_week: true})
                },
                {
                    isIt: signal(this.isMobile()),
                    name: 'view_list',
                    icon: signal('event'),
                    label: signal('BOOKINGS.TOPBAR_CALENDAR.LIST'),
                    click: (_: any) => this.toolbarEventsService.events.next({view_list: true})
                },
                {
                    isIt: signal(this.isMobile()),
                    name: 'view_resource',
                    icon: signal('event'),
                    label: signal('BOOKINGS.TOPBAR_CALENDAR.TIMELINE'),
                    click: (_: any) => this.toolbarEventsService.events.next({view_resource: true})
                },
                {
                    isIt: signal(this.isMobile()),
                    name: 'add_edit_booking',
                    iconType: 'symbols',
                    icon: signal('calendar_add_on'),
                    label: signal('Aggiungi prenotazione'),
                    click: (_: any) => this.toolbarEventsService.events.next({add_edit_booking: true})
                },
                {
                    isIt: signal(this.isMobile() && this.bookingsStateService.selectBookingsData().type === 1 && this.environmentInfoService.canDownloadFiles()),
                    name: 'download',
                    icon: signal('download'),
                    label: signal('Download prenotazioni'),
                    click: (_: any) => this.toolbarEventsService.events.next({booking_download: true})
                }
            ]
        });
    }

    callbackToToolbarClick(event: Partial<ModuleEvents>): void {
        if ('view_week' in event) {this.bookingsStateService.changeType(0)}
        else if ('view_list' in event) this.bookingsStateService.changeType(1);
        else if ('view_resource' in event) this.bookingsStateService.changeType(2);
        else if ('add_edit_booking' in event) this.addNewBooking();
        else if ('booking_download' in event) this.downloadBooking();
    }

    private setToolbar() {
        this.toolbarEventsService.events.pipe(this.onDestroyService.takeUntilDestroy).subscribe(e => this.callbackToToolbarClick(e));
        this.setModuleTitle();
        this.toolbarEventsService.centralToolbarComponent$.next(BookingTopbarComponent);
    }

    private setModuleTitle() {
        this.toolbarEventsService.moduleTitle.next("BOOKINGS");
    }

    addNewBooking(){
        this.addEditBookingDialogService.openDialog({data: {bookings: this.bookings, bookingShifts: this.bookingShifts, rooms: this.rooms, options: []}})
        .then((result) => {
            if(result) {
                const resultDate = this.tilbyDatePipe.transform(TilbyDatePipe.date(result.booking.booked_for), 'YYYY-MM-dd');
                const bookingDate = TilbyDatePipe.date({outputFormat: 'date', date: resultDate || ''});
                
                this.bookingsStateService.addBooking(result.booking, bookingDate);
            }
        });
    }

    async downloadBooking() {
        const currentDate = this.bookingsStateService.selectBookingsData().date;
        const res = await this.downloadBookingDialogService.openDialog({currentDate: currentDate, rooms: this.rooms});

        if (!res || (res.result === '')) return;
    }
}
