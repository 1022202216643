import {
    Component,
    effect,
    Injector,
    inject,
    OnDestroy,
    Input,
    OnInit,
    QueryList,
    ViewChild,
    ViewChildren,
    signal,
} from '@angular/core';

import {
    distinctUntilChanged,
    filter,
    skip,
    Subject,
    takeUntil
} from 'rxjs';

import {
    AllStaticElementsShape,
    AllTablesShape,
    ConfigurationManagerService,
    ConnectionService,
    ModuleEvents,
    STATIC_ELEMENTS,
    StorageManagerService,
    NEW_TABLES,
    TableWithIcon,
    ToolbarEventsService,
    EntityManagerService,
    ALL_ROOM_ELEMENTS,
    STATIC_ELEMENT_PREFIX
} from 'src/app/core';

import {
    Bookings,
    Rooms,
    RoomsTables,
    Sales
} from "tilby-models";

import {
    $state,
    $stateParams
} from 'app/ajs-upgraded-providers';

import {
    MatTabChangeEvent,
    MatTabGroup,
    MatTabsModule
} from "@angular/material/tabs";

import {
    AddEditTable,
    DuplicateTableForm,
    RoomForm,
    RoomsStateService,
    ShapeImage
} from './rooms.state.service';

import {
    ResizeRoom,
    RoomComponent,
    RoomElementComponent
} from './components/room';

import {CustomForm, CustomFormControl} from '@tilby/tilby-ui-lib/components/tilby-magic-form';
import {TilbyToolbar} from 'src/app/models';
import { DevLogger } from 'src/app/shared/dev-logger';

import {
    AlertDialogService,
    BottomSheetItem,
    ConfirmDialogService,
    DeleteSalesDialogService,
    OpenDialogsService
} from 'src/app/dialogs';

import {TableDeliveryComponent} from "./components/delivery";
import {TilbyGesturesDirective} from "@tilby/tilby-ui-lib/directives/tilby-gestures";
import {CommonModule} from "@angular/common";
import { GenericListDialogMessage, GenericListItem } from 'src/app/dialogs/generic-list-dialog/generic-list-dialog.model';
import {TilbyDatePipe} from '@tilby/tilby-ui-lib/pipes/tilby-date';
import {TopbarTablesTotal, TopbarTablesTotalComponent} from './components/topbar-tables-total';
import {TranslateModule} from '@ngx-translate/core';
import {CustomFormGroup} from '@tilby/tilby-ui-lib/components/tilby-magic-form';
import { CdkDrag } from "@angular/cdk/drag-drop";
import { OnDestroyService } from 'src/app/core/services/on-destroy.service';
import { TilbyCurrencyPipe } from '@tilby/tilby-ui-lib/pipes/tilby-currency';
import { groupBy } from 'src/app/shared/utils';

export const ROOM_MULTIPLICATION_FACTOR=80;

type TablesBookingMap = Record<number, Bookings>;
type TablesSalesMap = Record<number, Sales[]>;

export const resizeRoom = (roomSize: number,isBigger:boolean)=> isBigger
    ? Math.ceil((roomSize||0)/ROOM_MULTIPLICATION_FACTOR)
    : Math.floor((roomSize||0)/ROOM_MULTIPLICATION_FACTOR);

@Component({
    selector: 'app-tables',
    templateUrl: './tables.component.html',
    styleUrls: ['./tables.component.scss'],
    providers: [OnDestroyService],
    imports: [
        CommonModule,
        MatTabsModule,
        RoomComponent,
        RoomElementComponent,
        TableDeliveryComponent,
        TilbyGesturesDirective,
        TranslateModule,
        CdkDrag,
        TilbyDatePipe,
        TilbyCurrencyPipe
    ],
    standalone: true,
})
export class TablesComponent implements OnInit, OnDestroy, TilbyToolbar {
    // Injects
    private readonly alertDialogService = inject(AlertDialogService);
    private readonly configurationManagerService = inject(ConfigurationManagerService);
    private readonly confirmDialogService = inject(ConfirmDialogService);
    private readonly connectionService = inject(ConnectionService);
    private readonly entityManagerService = inject(EntityManagerService);
    private readonly injector = inject(Injector);
    private readonly onDestroyService = inject(OnDestroyService);
    private readonly openDialogsService = inject(OpenDialogsService);
    private readonly roomsStateService = inject(RoomsStateService);
    private readonly state = inject($state);
    private readonly stateParams = inject($stateParams);
    private readonly toolbarEventsService = inject(ToolbarEventsService);
    private readonly deleteSalesDialogService = inject(DeleteSalesDialogService);

    private interval?: NodeJS.Timer;
    private isSwipable = false;
    private readonly onDestroy$ = new Subject<void>();
    private readonly timeSlaBooking: number = +(this.configurationManagerService.getPreference('tables.booking_warning') || 30) * 60; // booking sla
    private tabIndex = this.roomsStateService.tabIndex;

    protected bookingPerTableMap: TablesBookingMap = {};
    protected readonly roomMultiplier = ROOM_MULTIPLICATION_FACTOR;
    protected tabIndexValueMatTabsAtInit = this.tabIndex();
    protected tableSalesMap: TablesSalesMap = {};

    protected readonly hangingAlarm = Number(this.configurationManagerService.getPreference('tables.hanging_alarm_after')) || 60;
    protected readonly useLastupdateTimer = !!this.configurationManagerService.getPreference('tables.timer_lastupdate');
    protected readonly disabledToggleShowAmount = !this.configurationManagerService.isFunctionEnabledOptin("tables.show_room_totals");

    public cashRegisterRoute = this.configurationManagerService.isModuleAngular('tables_and_cashregister') ? 'app.new.cashregister.content.showcase' : 'app.cashregister.content.showcase';
    public isEditMode = this.roomsStateService.isEditMode;
    public lastTableSelected?: RoomsTables;

    // Inputs
    @Input() bookings: Bookings[] = [];
    @Input() rooms: Rooms[] = [];
    @Input() sales: Sales[] = [];

    @ViewChild('tabs') tabs?: MatTabGroup;
    @ViewChildren('tables') roomTablesElements?: QueryList<RoomElementComponent> | undefined;

    private get isOffline() {
        return this.toolbarEventsService.isOffline;
    }

    private log(...args: any[]) {
        DevLogger.debug('[ TablesComponent ]', ...args);
    }

    private deselectAllTables() {
        if (this.isEditMode()) {
            this.roomTablesElements?.map(table => table.color.set('grey'));
        }
        this.createToolbarButtons(this.isEditMode());
    }

    private get selectedTables() {
        return this.roomTablesElements?.filter(({color}) => color()=='blue');
    }

    private get selectedTable() {
        return this.selectedTables?.[0].table;
    }

    private set selectedRoom(room: Rooms) {
        this.rooms[this.tabIndex()] = room;
    }

    private get selectedRoom() {
        return this.rooms[this.tabIndex()];
    }

    private setLastTableSelected(tableClicked: RoomsTables) {
        if (this.roomTablesElements?.find(({table, color}) => table?.id == tableClicked.id)) {
            this.lastTableSelected = tableClicked;
        } else if (this.selectedTables?.length == 1) {
            this.lastTableSelected = this.roomTablesElements?.find(({color}) => color() == 'blue')?.table;
        }
    }

    constructor(
    ) {
        this.tilbyEventsListen();
        this.checkConnection();
        effect(()=>{
            this.createToolbarButtons(this.isEditMode());
            this.disabledTabDelivery(this.isEditMode());
            this.showRoomTotals(this.isEditMode());
        });
    }

// START - TILBY EVENTS
    private tilbyEventsListen() {
        StorageManagerService.storageUpdates$.pipe(
            filter((data) => data.entityName == 'sales'),
            takeUntil(this.onDestroy$)
        ).subscribe((data) => {
            this.state.reload('app.new.tables');
        });

        ConnectionService.connectionStatus$.pipe(
            takeUntil(this.onDestroy$)
        ).subscribe((data) => {
            const offline = !data.online;

            if (offline) {
                this.isEditMode.set(false);
            }

            this.toolbarEventsService.isOffline$.next(offline);
            this.createToolbarButtons(false);
        });
    }

    private checkConnection() {
        if (this.connectionService.isOffline()) {
            this.isEditMode.set(false);
            this.toolbarEventsService.isOffline$.next(true);
            this.createToolbarButtons(false);
        }
    }

// END - TILBY EVENTS

// ANGULAR _ LIFECICLE
    ngOnInit(): void {
        this.setToolbar();
        this.isEditMode.set(false);
        this.updateTableSalesMap();
        this.updateTablesBookingMap();
        this.interval = setInterval(() => this.updateTablesBookingMap(), 60000);
        this.selectRoom();
    }

    selectRoom(){
        const selectedTab= this.stateParams.id
            ? this.stateParams.id=='take_away' || this.stateParams.id=='delivery'
                ? this.rooms.length
                : this.rooms.findIndex(room=>room.id==this.stateParams.id)
            : this.roomsStateService.selectedRoom ||0;
        this.tabIndex.set(selectedTab);
        this.tabIndexValueMatTabsAtInit = this.tabIndex();
    }

    ngOnDestroy(): void {
        this.toolbarEventsService.isOffline$.next(false);
        this.roomsStateService.ngOnDestroy();
        clearInterval(this.interval);
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

// START - TOOLBAR
    private setToolbar() {
        this.toolbarEventsService.events.pipe(this.onDestroyService.takeUntilDestroy).subscribe(e => this.callbackToToolbarClick(e));
        this.setModuleTitle();
        this.showRoomTotals(this.isEditMode());
        this.toolbarEventsService.searchBarValue$.next('');
    }

    private disabledTabDelivery(isEditMode : boolean) {
        if (this.tabs && this.tabs._allTabs.length > 0) {
            isEditMode ? this.tabs._allTabs.last.disabled = true : this.tabs._allTabs.last.disabled = false;
        }
    }

    private createTopbarTotals(): TopbarTablesTotal[] {
        const {roomTotal, grandTotal, saleCovers, generalCovers} = this.calculateTotals();
        const {generalTotalCovers, saleTotalCovers} = this.calculateTotalCovers();
        return [
            {
                label: 'TABLES.TOPBAR.GRAND_TOTAL',
                price: grandTotal,
                people: {icon: 'person_filled', covers: generalCovers, totalCovers: generalTotalCovers}
            },
            {
                label: 'TABLES.TOPBAR.ROOM_TOTAL',
                price: roomTotal,
                people: {icon: 'person_filled', covers: saleCovers, totalCovers: saleTotalCovers}
            },
        ];
    }

    private calculateTotals() {
        const roomSelected = this.selectedRoom;
        return this.sales.reduce((s, a) => !!a.table_id ? ({
                grandTotal: s.grandTotal + (a.amount || 0),
                generalCovers: s.generalCovers + (a.covers || 0),
                roomTotal: s.roomTotal + (a.room_id == roomSelected?.id ? (a.amount || 0) : 0),
                saleCovers: s.saleCovers + (+(a.room_id == roomSelected?.id ? (a.covers || 0) : 0))
            }) : s,
            {
                grandTotal: 0,
                roomTotal: 0,
                generalCovers: 0,
                saleCovers: 0,
            });
    }

    private calculateTotalCovers() {
        const roomSelected = this.rooms[this.tabIndex()];
        return this.rooms.reduce((s, a) => {
                const tablesSum = a.tables?.reduce((s, a) => ({
                        generalTotalCovers: s.generalTotalCovers + a.covers,
                        saleTotalCovers: s.saleTotalCovers + (a.room_id == roomSelected?.id ? (a.covers || 0) : 0)
                    }), {
                        generalTotalCovers: 0,
                        saleTotalCovers: 0
                    })
                    || s;
                return {
                    generalTotalCovers: s.generalTotalCovers + tablesSum.generalTotalCovers,
                    saleTotalCovers: s.saleTotalCovers + tablesSum.saleTotalCovers
                }
            },

            {
                generalTotalCovers: 0,
                saleTotalCovers: 0
            }
        );
    }

    private showRoomTotals(isEditMode: boolean) {
        if (this.configurationManagerService.isFunctionEnabledOptin("tables.show_room_totals") && !isEditMode) {
            this.roomsStateService.totalsArray$.next(this.createTopbarTotals());
            this.toolbarEventsService.centralToolbarComponent$.next(TopbarTablesTotalComponent);
        } else
            this.toolbarEventsService.centralToolbarComponent$.next(undefined);
    }

    private setModuleTitle() {
        this.toolbarEventsService.moduleTitle.next("ROOMS");
    }

    private changeZoom(scaleDifference: number, defaultScale: number) {
        const roomBoundary = document.querySelector('.room-boundary');

        if (!(roomBoundary instanceof HTMLElement)) {
            return;
        }

        // Calculate new scale
        const currentScale = roomBoundary.style.transform.match(/scale\((\d\.?\d*)\)/);
        const newScale = currentScale ? parseFloat(currentScale[1]) + scaleDifference : defaultScale;

        if(newScale >= 0.25 && newScale <= 2) {
            // Set new scale
            roomBoundary.style.transform = `scale(${newScale})`;
    
            // Save new scale as user preference
            this.configurationManagerService.setUserPreference("rooms.zoom_factor", newScale);
        }
    }

    createToolbarButtons(isEditMode: boolean) {
        const selectedTables = this.roomTablesElements?.filter(table => table.color() === 'blue');
        const cantManageTables = signal(!this.configurationManagerService.isUserPermitted('tables.manage_tables'));
        const selectedTableLength = selectedTables?.length || 0;

        const hasTableSelected = signal(selectedTableLength > 0);
        const hasNoTableSelected = signal(selectedTableLength == 0);
        const singleTableSelected = signal(selectedTableLength == 1);
        const isRoomSelected = signal(!!this.selectedRoom?.id);
        const canDeleteSale = signal(!this.selectedRoom?.id && this.configurationManagerService.isUserPermitted("cashregister.delete_sale"));

        this.toolbarEventsService.buttons$.next({
            barButtons: isEditMode
                ? [
                    {
                        isIt: hasNoTableSelected,
                        name: 'edit_room',
                        icon: signal('edit'),
                        click: () => this.toolbarEventsService.events.next({edit_room: true})
                    },
                    {
                        isIt: hasNoTableSelected,
                        name: 'new_table',
                        icon: signal('add_circle'),
                        click: () => this.toolbarEventsService.events.next({new_table: true})
                    },
                    {
                        isIt: singleTableSelected,
                        name: 'edit_table',
                        icon: signal('edit'),
                        click: () => this.toolbarEventsService.events.next({edit_table: true})
                    },
                    {
                        isIt: singleTableSelected,
                        name: 'duplicate_table',
                        icon: signal('copy_all'),
                        click: () => this.toolbarEventsService.events.next({duplicate_table: true})
                    },
                    {
                        isIt: hasTableSelected,
                        name: 'move_table',
                        icon: signal('input'),
                        click: () => this.toolbarEventsService.events.next({move_table: true})
                    },
                    {
                        isIt: hasTableSelected,
                        name: 'delete_table',
                        icon: signal('delete'),
                        click: () => this.toolbarEventsService.events.next({delete_table: true})
                    },
                ]
                : [],
            panelButtons: isEditMode || this.connectionService.isOffline()
                ? isEditMode
                    ? [{
                        isIt: signal(true),
                        name: 'back',
                        icon: signal('close'),
                        label: signal('TABLES.TOPBAR.BACK'),
                        click: () => this.isEditMode.set(false)
                    }]
                    : []
                : [
                    {
                        isIt: signal(true),
                        name: 'new_room',
                        label: signal('TABLES.TOPBAR.NEW_ROOM'),
                        click: () => this.toolbarEventsService.events.next({new_room: true}),
                        isDisable: cantManageTables
                    },
                    {
                        isIt: canDeleteSale,
                        name: 'delete_sales',
                        label: signal('TABLES.TOPBAR.DELETE_SALES'),
                        click: () => this.deleteSalesDialogService.openDialog(),
                    },
                    {
                        isIt: isRoomSelected,
                        name: 'edit_room_tables',
                        label: signal('TABLES.TOPBAR.EDIT_ROOM_TABLES'),
                        click: () => this.toolbarEventsService.events.next({edit_room_tables: true}),
                        isDisable: cantManageTables
                    },
                    {
                        isIt: isRoomSelected,
                        name: 'delete_room',
                        label: signal('TABLES.TOPBAR.DELETE_ROOM'),
                        click: () => this.toolbarEventsService.events.next({delete_room: true}),
                        isDisable: cantManageTables
                    },
                    {
                        isIt: isRoomSelected,
                        name: 'zoom_in',
                        label: signal('+'),
                        click: (event) => {
                            event.stopPropagation();
                            this.changeZoom(0.25, 1.25);
                        },
                        isDisable: cantManageTables
                    },
                    {
                        isIt: isRoomSelected,
                        name: 'zoom_out',
                        label: signal('-'),
                        click: (event) => {
                            event.stopPropagation();
                            this.changeZoom(-0.25, 0.75);
                        },
                        isDisable: cantManageTables
                    }
                ]
        });
    }

    callbackToToolbarClick(event: Partial<ModuleEvents>): void {
        // DEFAULT MODE
        if ('new_room' in event) {this.openRoomDialog()}
        else if ('edit_room' in event) this.openRoomDialog(true);
        else if ('edit_room_tables' in event) this.isEditMode.update(isEditMode=>!isEditMode);
        else if ('delete_room' in event) this.deleteRoom();
        // EDIT MODE
        if ('new_table' in event) this.addEditRoomTableElement();
        else if ('edit_table' in event) this.addEditRoomTableElement(true);
        else if ('duplicate_table' in event) this.duplicateTable();
        else if ('move_table' in event) this.moveTables();
        else if ('delete_table' in event) this.deleteTables();
    }

    private async openRoomDialog(isEdit = false) {
        const res = await this.openDialogsService.openMagicFormDialog<string, CustomFormGroup<RoomForm>,Rooms>({
            data: {
                title: isEdit ? 'TABLES.EDIT_ROOM.TITLE' : 'TABLES.NEW_ROOM.TITLE',
                form: this.roomsStateService.createRoomForm(isEdit, isEdit ? this.selectedRoom : undefined),
                buttonCancelLabel: 'TABLES_NEW.DIALOGS.ROOM.BUTTON_CANCEL',
                buttonConfirmLabel: 'TABLES_NEW.DIALOGS.ROOM.BUTTON_CONFIRM'
            },
            panelClass: 'magic-form-dialog',
            disableClose: true
        }).then(res=>res&&({...res,...(res.default_pricelist&&res.default_pricelist<0&&{default_pricelist:undefined})}));
        if (res) {
            if (!isEdit) {
                const room:Rooms = res;
                if (!room.width) room.width = 10;
                if (!room.height) room.height = 10;
                this.entityManagerService.rooms.postOneOnline(room).then((newRoom: Rooms) => {
                    const roomsLength = this.rooms.length;
                    this.rooms = [...this.rooms, newRoom];
                    this.isEditMode.set(true);
                    let intervalId = setInterval(() => {
                        if (roomsLength + 1 === this.rooms.length) { this.tabs!.selectedIndex = this.rooms.length - 1; clearInterval(intervalId); }
                    }, 100);
                });
            } else {
                this.selectedRoom = {...this.selectedRoom,...res};
                const roomUpdated = {
                    ...this.selectedRoom,
                    ...res
                };
                this.updateRoom(roomUpdated);
            }
        }
    }

    private async deleteRoom() {
        if (this.selectedRoom.tables && this.selectedRoom.tables.length > 0) {
            await this.alertDialogService.openDialog({data: {messageLabel: 'TABLES.DELETE_ROOM.HAS_TABLES'}});
            return;
        }

        const res = await this.confirmDialogService.openDialog({
            data: {
                messageLabel: 'TABLES.DELETE_ROOM.CONFIRM',
                messageParams: {roomName: this.selectedRoom.name || ''},
                confirmLabel: 'DIALOG.CUSTOMER_DELETE.CONFIRM',
                cancelLabel: 'DIALOG.CUSTOMER_DELETE.CANCEL'
            }
        });

        if(!res) {
            return;
        }

        await this.entityManagerService.rooms.deleteOneOnline(this.selectedRoom.id!);

        this.rooms = this.rooms.filter(room => room.id != this.selectedRoom.id);
    }

    private async addEditRoomTableElement(isEdit = false) {
        const editRoomTableElement = this.roomTablesElements?.find(table => table.color() === 'blue')?.table
        const tableShapeChosen: BottomSheetItem | undefined = isEdit
            ? {
                label: editRoomTableElement?.name,
                icon: ALL_ROOM_ELEMENTS.find(table => !!table.sizes.find(size => size.shape == editRoomTableElement?.shape))?.shape,
                callback: () => this.log(editRoomTableElement)
            }
            : undefined;

        const addElement = (elementTypeChosen: Pick<RoomsTables, "name" | "shape" | "covers" | "order_type"> | undefined) => {
            const addTable = {
                covers:0,
                order_type:'single',
                name:elementTypeChosen?.shape,
                xpos: 0,
                ypos: 0,
                ...elementTypeChosen,
            } as RoomsTables;
            const tmpRoom = {...this.selectedRoom};
            tmpRoom.tables?.push(addTable);
            this.updateRoom(tmpRoom);
        }
        const tableElementTypeChosen = ALL_ROOM_ELEMENTS.find(table => tableShapeChosen?.icon == table.shape);
        const form = this.roomsStateService.createAddEditTableForm(tableElementTypeChosen, isEdit, isEdit ? editRoomTableElement : undefined);
        const shapeImageControl = form?.controls.shapeImage as CustomFormGroup<CustomForm<ShapeImage>>;
        const tableControl = shapeImageControl.controls.table as CustomFormControl<TableWithIcon<AllTablesShape>>;
        const elementControl = shapeImageControl.controls.element as CustomFormControl<TableWithIcon<AllStaticElementsShape>>;
        const shapeControl = form?.controls.shape;
        const formInElementMode = () => {
            tableControl?.reset();
            form?.disable();
            shapeImageControl?.enable();
        } ;
        if(elementControl?.value){
            formInElementMode();
        }
        tableControl?.valueChanges.pipe(skip(1),distinctUntilChanged()).pipe(takeUntil(this.onDestroy$)).subscribe((tableSelected:TableWithIcon<AllTablesShape>)=> {
            this.log('TABLE_CHOSEN',tableSelected);
            if(!tableSelected) return;
            if(tableControl.value && (<string>form?.controls.shape.customProps.inputInitialValue)?.startsWith(STATIC_ELEMENT_PREFIX)){
                form?.controls.name.reset();
                form?.controls.covers.reset();
            }
            const tableChosen= NEW_TABLES.find(table => table.id == tableSelected.id);
            if(tableControl && tableChosen){
                elementControl?.reset();
                form?.enable();
                const possibleShapes=this.roomsStateService.getTableSizes(tableChosen);
                shapeControl.customProps.inputChoices = possibleShapes;
                shapeControl.setValue(possibleShapes[0].value);
            }
        });
        elementControl?.valueChanges.pipe(skip(1),distinctUntilChanged()).pipe(takeUntil(this.onDestroy$)).subscribe((elementSelected:TableWithIcon<AllStaticElementsShape>)=> {
            this.log('ELEMENT_CHOSEN',elementSelected);
            if(!elementSelected) return;
            const elementChosen= STATIC_ELEMENTS.find(element => element.id == elementSelected.id);
            if(elementControl && elementChosen){
                formInElementMode();
            }
        });
        const res = await this.openDialogsService.openMagicFormDialog<string, CustomFormGroup<CustomForm<AddEditTable>>, AddEditTable>({
            data: {
                title: `TABLES.${isEdit ? 'EDIT_TABLE' : 'ADD_TABLE'}.TITLE`,
                form,
                buttonCancelLabel: 'TABLES_NEW.DIALOGS.SALE.BUTTON_CANCEL',
                buttonConfirmLabel: 'TABLES_NEW.DIALOGS.SALE.BUTTON_CONFIRM'
            },
           ...this.openDialogsService.switchMobileDesktopDimensions({width:'85vw'},{fullScreenForMobile:false})
        });
        if (res) {
            const {shapeImage:{element}={},...formValue} =res;
            const size = ALL_ROOM_ELEMENTS.flatMap(shape => shape.sizes).find(size => size.shape === (formValue!.shape??element?.shape));
            const editedTableElement = {...editRoomTableElement,...formValue,...(element?.shape&&{shape:element.shape,name:element.shape, covers:0}), height: size?.height || 0, width: size?.width || 0} as RoomsTables;
            if (!isEdit) {
                addElement(editedTableElement);
            } else {
                const tmpRoom = {...this.selectedRoom};
                const index = tmpRoom.tables?.findIndex(table => table.id === editedTableElement?.id);
                if (index !== undefined && index !== -1) {
                    const tableToUpdate = tmpRoom.tables![index];
                    const mergeTableToUpdate = {...tableToUpdate, ...editedTableElement};
                    tmpRoom.tables![index] = mergeTableToUpdate;
                    this.updateRoom(tmpRoom);
                    this.setLastTableSelected(mergeTableToUpdate);
                }
            }
        }

    }

    private async duplicateTable() {
        const marginTop = 50;
        const marginLeft = 20;
        if (this.lastTableSelected) {
            const res = await this.openDialogsService.openMagicFormDialog<"TABLES.DUPLICATE_TABLE.TITLE", CustomFormGroup<CustomForm<DuplicateTableForm>>, DuplicateTableForm>({
                data: {
                    title: 'TABLES.DUPLICATE_TABLE.TITLE',
                    form: this.roomsStateService.duplicateTableForm(this.lastTableSelected, this.selectedRoom, marginTop, marginLeft)!,
                    buttonCancelLabel: 'TABLES_NEW.DIALOGS.SALE.BUTTON_CANCEL',
                    buttonConfirmLabel: 'TABLES_NEW.DIALOGS.SALE.BUTTON_CONFIRM'
                }
            });
            this.log('DUPLICATE_TABLE', res);
            this.createDuplicateTables(res, marginTop, marginLeft);
            const updatedRoom = await this.entityManagerService.rooms.putOneOnline(this.selectedRoom);
            this.log('UPDATED_TABLES', updatedRoom);
            this.selectedRoom = updatedRoom;
        }
    }

    private createDuplicateTables(duplicateFormRes: DuplicateTableForm | undefined, marginTop: number, marginLeft: number) {
        if (this.selectedTable) {
            const {created_at, updated_at, id, ...selectedTable} = this.selectedTable;
            const {row_elements: itemPerRow = 1, column_elements: itemPerColumn = 1, new_name} = duplicateFormRes || {};
            let k = (itemPerColumn === 1 && itemPerRow === 1) ? 0 : 2;
            let _table = structuredClone(selectedTable);
            for (let i = 0; i < itemPerColumn; i++) {
                let offset = (i !== 0) ? marginTop : 0;
                let ypos = (selectedTable?.ypos || 0) + i * ((selectedTable?.height || 0) + offset);
                for (let j = 0; j < itemPerRow; j++) {
                    let isFirstItem = i === 0 && j === 0;
                    _table.xpos = (selectedTable?.xpos || 0) + j * ((selectedTable?.width || 0) + marginLeft);
                    _table.ypos = ypos;
                    let suffix = (k === 0 || isFirstItem || selectedTable.shape.startsWith(STATIC_ELEMENT_PREFIX)) ? '' : ` ${k}`;
                    _table.name = selectedTable?.name + suffix;
                    if (k !== 0 && !isFirstItem) {
                        k++;
                    }
                    if (!isFirstItem && this.selectedRoom.tables) {
                        this.selectedRoom.tables.push(_table);
                    }
                    _table = structuredClone(selectedTable);
                }
            }
        }
        this.log('DUPLICATE_TABLES', this.selectedRoom)
    }

    private async moveTables() {
        const list: GenericListItem[] = this.rooms.map(({name, id}) => ({
            name,
            nameValue: id,
            isSelected: this.lastTableSelected?.room_id == id
        }));

        const answer = await this.openDialogsService.openGenericListDialog({
            data: {
                title: {label: 'TABLES.MOVE_TABLE.TITLE'},
                list,
                showCheckbox: true
            }
        });

        //Cancel if no room is selected
        if(!answer) {
            return;
        }

        //Find the target room
        const targetRoom = this.rooms.find((room) => answer?.nameValue === room.id);

        //Cancel if the target room is the same as the source room or is not found
        if(!targetRoom || targetRoom.id === this.selectedRoom.id) {
            return;
        }

        //Move tables
        this.selectedTables?.forEach((selectedTable) => {
            const index = this.selectedRoom.tables?.findIndex((t) => t === selectedTable.table);

            if (index !== undefined && index !== -1) {
                this.selectedRoom.tables?.splice(index, 1);
            }

            const x = targetRoom?.tables?.find((t) => t.id === selectedTable.table?.id);

            if (selectedTable?.table) {
                selectedTable.table.xpos = 0;
                selectedTable.table.ypos = 0;
            }

            if (!x) {
                if (targetRoom && targetRoom.tables) {
                    if (selectedTable.table) {
                        targetRoom.tables.push(selectedTable.table);
                    }
                }
            } else {
                Object.assign(x, selectedTable.table);
            }
        });

        //Send updated rooms to the API
        const updatedSourceRoom = await this.entityManagerService.rooms.putOneOnline(this.selectedRoom);
        const updatedTargetRoom = await this.entityManagerService.rooms.putOneOnline(targetRoom);

        //Replace local rooms with the updated ones
        for(const room of [updatedSourceRoom, updatedTargetRoom]) {
            const roomIndex = this.rooms.findIndex(r => r.id === room.id);

            if (roomIndex !== -1) {
                this.rooms[roomIndex] = room;
            }
        }

        this.log('MOVE_TABLES', {selectedTables: this.selectedTables?.map(({table: {name} = {}}) => name), answer});
    }


    private async deleteTables() {
        const answer = await this.confirmDialogService.openDialog({
            data: {
                messageLabel: 'TABLES.DELETE_TABLES.CONFIRM',
                cancelLabel: 'TABLES_NEW.DIALOGS.SALE.BUTTON_CANCEL',
                confirmLabel: 'TABLES_NEW.DIALOGS.SALE.BUTTON_CONFIRM'
            }
        });

        if(!answer) {
            return;
        }

        this.log('DELETE_TABLES', {selectedTables: this.selectedTables?.map(({table: {name} = {}}) => name), answer});
        
        const selectedTableIds = this.selectedTables!.map(selTable => selTable!.table!.id);
        const updatedTables = this.selectedRoom.tables?.filter(table => selectedTableIds.indexOf(table.id) < 0);
        
        this.selectedRoom = {
            ...this.selectedRoom,
            tables: updatedTables
        }

        this.log('DELETE_TABLES', this.selectedRoom);
        this.entityManagerService.rooms.putOneOnline(this.selectedRoom);
        this.deselectAllTables();
    }

// END - TOOLBAR

// START - GESTURES
    swipeRight($event: any) {
        if (this.tabs?.selectedIndex && this.isSwipable) {
            this.tabs.selectedIndex! -= 1;
            this.log('SWIPE_RIGHT', this.tabs, this.tabs?.selectedIndex, {$event})
        }
    }

    //this room.length = tabsLength -1
    swipeLeft($event: any) {
        if (this.tabs && (this.tabs?.selectedIndex || 0) < this.rooms.length && this.isSwipable) {
            this.log('SWIPE_LEFT', this.tabs, {$event})
            this.tabs.selectedIndex! += 1;
        }
    }

    selectedTabChange($event: MatTabChangeEvent) {
        this.tabIndex.set($event.index);
        this.roomsStateService.selectedRoom = $event.index;
        this.deselectAllTables();

        if (!this.isEditMode()) {
            this.roomsStateService.totalsArray$.next(this.createTopbarTotals());
        }
    }

// END - GESTURES

    onRoomClick() {
        this.log('ROOM_CLICK');
        this.deselectAllTables();
    }

    protected async tableClick(table: RoomsTables, isEditMode: boolean, longPress=false) {
        this.setLastTableSelected(table);
        this.createToolbarButtons(isEditMode);
        if (!isEditMode) {
            if (await this.connectionCheckAndGo() && !table.shape.startsWith(STATIC_ELEMENT_PREFIX)) {
                await this.actionOnTableClick(table,longPress);
            }
        }
    }

    private async connectionCheckAndGo() {
        return this.isOffline
            ? await this.confirmDialogService.openDialog({
                data: {
                    messageLabel: 'TABLES.TABLE.OFFLINE_CONFIRM',
                    confirmLabel: 'TABLES_NEW.DIALOGS.OFFLINE_ACTIONS.BUTTON_CONFIRM',
                    cancelLabel: 'TABLES_NEW.DIALOGS.OFFLINE_ACTIONS.BUTTON_CANCEL'
                }
            })
            : true;
    }

    private updateTableSalesMap() {
        const tableSales = groupBy(this.sales, (sale) => sale.table_id || 0);
        delete tableSales[0];

        this.tableSalesMap = tableSales;
    }

    private updateTablesBookingMap() {
        const now = Date.now();
        const maxBookedFor = now + (this.timeSlaBooking * 1000);

        const updatedBookings: Bookings[] = [];
        const bookingMap: TablesBookingMap = {};

        for (const booking of this.bookings) {
            const bookedFor = Date.parse(booking.booked_for);

            // Skip if booking is in the past and discard it
            if (bookedFor < now) {
                continue;
            }

            updatedBookings.push(booking);

            // Skip if booking is more than timeSlaBooking minutes in the future
            if (bookedFor > maxBookedFor) {
                continue;
            }

            // This booking should be shown, so update the booking table map
            for (const table of booking.tables || []) {
                const tableId = +(table.table_id || 0);

                if (!bookingMap[tableId] || booking.booked_for < bookingMap[tableId].booked_for) {
                    bookingMap[tableId] = booking;
                }
            }
        }

        this.bookings = updatedBookings;
        this.bookingPerTableMap = bookingMap;
    }

    private async actionOnTableClick(table: RoomsTables, longPress = false) {
        const tilbyDatePipe = this.injector.get(TilbyDatePipe);
        const tilbyCurrencyPipe = this.injector.get(TilbyCurrencyPipe)

        let saleIdForMultiple: number | undefined;

        const salesOfTable = this.sales
            .filter(sale => table.id == sale.table_id)
            .map(sale => ({
                name: `${tilbyDatePipe.transform(sale.open_at, 'HH:mm')} ${sale.name}      (${tilbyCurrencyPipe.transform(sale.final_amount || 0)})`,
                id: sale.id,
                noTranslate: true
            }));

        if (table.order_type == 'multiple' || (table.order_type == 'single' && salesOfTable.length > 1)) {
            saleIdForMultiple = await this.openDialogsService.openGenericListDialog<GenericListDialogMessage, GenericListItem & { id: number | undefined }>({
                data: {
                    title: { label: 'DIALOG.TABLE_MULTI_SALE.TITLE' },
                    list: table.order_type == 'multiple' ? [...salesOfTable, { name: 'DIALOG.TABLE_MULTI_SALE.NEW_SALE', id: undefined, preIcon: 'add' }] : salesOfTable
                },
                disableClose: true
            }).then((answer) => answer?.id);
        }

        if (saleIdForMultiple === -1) {
            return;
        }

        const saleWithTable = this.sales.find((sale) => {
            return (table.order_type == 'multiple' || (table.order_type == 'single' && salesOfTable.length > 1))
                ? sale.id == saleIdForMultiple
                : sale.table_id === table.id;
        });

        /**
         * CASE 1: If there is a sale associated with the table, redirect to the cashregister to open it
         */
        if (saleWithTable) {
            this.state.go(this.cashRegisterRoute, {
                action: 'open-sale-id',
                id: saleWithTable?.uuid
            });
        } else {
            const newSaleConfigTemplate = {
                action: 'create-new-sale',
                room: this.rooms.find((room) => room.id === table.room_id),
                saleType: 'normal',
                table: table
            }

            this.updateTablesBookingMap();

            const booking = this.bookingPerTableMap[table.id!];

            if (booking) {
                /**
                 * CASE 2: If there is no sale associated with the table and there are upcoming bookings
                 * associated with the table, then show a modal “Table is reserved for Mario Rossi. What do you want to do?“
                 * show 3 options:
                 *  - [Guest arrived] that redirects to the cashregister to create a new sale passing also the booking information.
                 *  - [Other guest] that redirects to the cashregister to create a new sale without passing the booking.
                 *  - [Back] that closes the modal without doing anything
                 */
                const customer = booking.booking_customer?.first_name ? `${booking.booking_customer?.first_name} ${booking.booking_customer?.last_name}` : booking.booking_customer?.company_name;

                const optionList = [
                    { value: 1, name: 'DIALOG.TABLE_BOOKING.RESERVATION_ARRIVED', isSelected: false },
                    { value: 2, name: 'DIALOG.TABLE_BOOKING.OTHER_GUEST', isSelected: false },
                    { value: 3, name: 'DIALOG.TABLE_BOOKING.BACK', isSelected: false }
                ];

                const info = await this.openDialogsService.openGenericListDialog<GenericListDialogMessage, any>({
                    data: {
                        title: {
                            label: 'DIALOG.TABLE_BOOKING.TITLE',
                            params: { value1: customer || '' }
                        },
                        list: optionList
                    }
                });

                switch (info.value) {
                    case 1: {
                        this.state.go(this.cashRegisterRoute, {
                            ...newSaleConfigTemplate,
                            booking
                        });
                        break;
                    }
                    case 2: {
                        this.state.go(this.cashRegisterRoute, {
                            ...newSaleConfigTemplate,
                        });
                        break;
                    }
                }
            } else {
                /**
                 * CASE 3
                 * If there is no sale associated with the table and there are no upcoming bookings
                 * then redirect to the cashregister to create a new sale with the table information
                 */
                this.state.go(this.cashRegisterRoute, {
                    ...newSaleConfigTemplate,
                    longPress
                });
            }
        }
    }

    protected tableMoved(tableUpdated: RoomsTables, room: Rooms) {
        this.log('TABLE_MOVED', tableUpdated, room);
        const roomUpdated = {
            ...room,
            tables: room.tables?.map(table => table.id == tableUpdated.id ? tableUpdated : table)
        };
        this.updateRoom(roomUpdated);
    }

    protected async updateRoom(room: Rooms) {
        await this.entityManagerService.rooms.putOneOnline(room).then((room: Rooms) => this.updateRoomOnRooms(room));
    }

    private updateRoomOnRooms(room: Rooms) {
        const roomIndex = this.rooms.findIndex(r => r.id === room.id);
        if (roomIndex !== -1) {
            this.selectedRoom = room;
        }
    }

    protected async saveNewRoomSizes($event: ResizeRoom) {
        const {roomWidth:width, roomHeight:height} = $event;
        const updatedRoom = {...this.selectedRoom,width, height};
        this.updateRoomOnRooms(updatedRoom);
        await this.updateRoom(updatedRoom);
    }

    protected onTouchStart(ev: TouchEvent) {
        this.isSwipable= ev.touches.length === 3;
    }
}
