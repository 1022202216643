<mat-tab-group #tabs [mat-stretch-tabs]="true" mat-align-tabs="start"
            class="tw-h-full"
            [selectedIndex]="tabIndexValueMatTabsAtInit"
            (selectedTabChange)="selectedTabChange($event)">
    @for(room of rooms; track room.id) {
        <mat-tab [label]="room.name">
            <ng-template matTabContent  #roomElement>
                <app-booking-room class="tw-relative tw-block tw-h-full" [roomHeight]="(room.height||1)*roomMultiplier+'px'" [roomWidth]="(room.width||1)*roomMultiplier+'px'"
                    [roomFloor]="$any(room.floor)"
                    [room]="room"
                    (click)="clickOnRoom()">

                    @for(table of (room.tables||[]); track table.id){
                            <app-booking-room-element
                                #tables class="tw-block"
                                [table]="table"
                                [bookingInfo]="bookingInfo"
                                [isTablesSelected] = "table.id && tablesById[table.id]?.selected"
                                />
                    }
                </app-booking-room>
        </ng-template>
        </mat-tab>
    }
</mat-tab-group>
