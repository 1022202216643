import * as angular from 'angular';
import * as _ from 'lodash';
import { donationBanner } from 'src/app/core/constants';
import { ItalanFiscalPrinterDriver, PrintFreeOptions } from 'src/app/shared/model/it-fiscal-printer.model';
import { AxonRTDriver } from './drivers/axon-g100-rt-driver';
import { CustomRTDriver } from './drivers/custom-rt-driver';

import {
    ConfigurationManagerService,
    EntityManagerService
} from 'src/app/core';

import {
    DailyClosingSchema,
    Orders,
    Printers,
    Sales,
    SalesDocuments,
    SalesItems
} from 'tilby-models';

import { DocumentPrinterOptions } from 'src/app/shared/model/document-printer.model';
import { blobToDataURL, dataURLToBase64 } from 'src/app/shared/data-conversion-utils';
import { Subject } from 'rxjs';
import { keyBy } from 'src/app/shared/utils';

import {
    SaleDocumentsViewerDialogService
} from 'src/app/dialogs';

type PrinterDriverInfo = {
    driver: ItalanFiscalPrinterDriver
    printer: Printers
};

type fiscalDocumentMethods = Pick<ItalanFiscalPrinterDriver, ("printFiscalReceipt" | "printInvoice" | "printReceiptInvoice" | "printSummaryInvoice" | "printShippingInvoice")>;

const documentToDriverMethod: Record<string, keyof fiscalDocumentMethods> = {
    "fiscal_receipt": "printFiscalReceipt",
    "invoice": "printInvoice",
    "receipt_invoice": "printReceiptInvoice",
    "summary_invoice": "printSummaryInvoice",
    "shipping_invoice": "printShippingInvoice"
};

const printerStatusFields = ['printer_serial', 'shop', 'driver', 'cpuRel', 'mfRel', 'fpStatus', 'rtType', 'rtMainStatus', 'rtSubStatus', 'rtDailyOpen', 'rtNoWorkingPeriod', 'rtFileToSend', 'rtOldFileToSend', 'rtFileRejected', 'rtExpiryCD', 'rtExpiryCA', 'rtTrainingMode', 'rtUpgradeResult', 'fiscal_gran_total', 'fiscal_daily_closures', 'dgfe_status', 'lastPeriodicCheck'];

// Export main service class
export class FiscalPrintersService {
    private printerDriversMap: Record<string, Record<string, ItalanFiscalPrinterDriver>>;

    // Export daily closing status observable
    private static readonly fiscalClosingSubject = new Subject<{ printer: Printers, action: ('configure' | 'dgfeRead'), status: ('IN_PROGRESS' | 'COMPLETED' | 'FAILED') }>();
    public static readonly fiscalClosingStatus$ = FiscalPrintersService.fiscalClosingSubject.asObservable();

    constructor(
        private errorsLogger: any,
        private $http: angular.IHttpService,
        private $filter: any,
        private $translate: any,
        private entityManagerService: EntityManagerService,
        private configurationManagerService: ConfigurationManagerService,
        private ImagesManager: any,
        private restManager: any,
        private confirmDialog: any,
        private util: any,
        private printerErrorFiscal: any,
        private saleDocumentsViewer: SaleDocumentsViewerDialogService,
        private fiscalUtils: any,
        EpsonMFDriver: ItalanFiscalPrinterDriver,
        EpsonRTDriver: ItalanFiscalPrinterDriver,
        RchMFDriver: ItalanFiscalPrinterDriver,
        RchRTDriver: ItalanFiscalPrinterDriver,
        CustomRTDriver: CustomRTDriver,
        AxonRTDriver: ItalanFiscalPrinterDriver,
        AxonG100RTDriver: AxonRTDriver,
        DtrRTDriver: ItalanFiscalPrinterDriver,
    ) {
        //Initialize the printer drivers map
        this.printerDriversMap = {
            'fiscal': {
                'epson': EpsonMFDriver,
                'rch': RchMFDriver
            },
            'rt': {
                'axon': AxonRTDriver,
                'axon_g100': AxonG100RTDriver,
                'custom': CustomRTDriver,
                'epson': EpsonRTDriver,
                'rch': RchRTDriver,
                'dtr': DtrRTDriver
            }
        }
    }

    /**
     * Retrieves the display message, invoice footer and headers
     * 
     * @param {Printers} printer - The printer object.
     * @return {Record<string, string>} - The printer options as a record of key-value pairs.
     */
    private getPrinterOptions(printer: Printers): Record<string, string> {
        const printerOptions: Record<string, string> = {};

        if (printer.type != 'nonfiscal') {
            printerOptions.display_message = this.configurationManagerService.getPreference('fiscalprinter.display_message') || ""

            for (let i = 1; i <= 13; i++) {
                printerOptions[`header${i}`] = this.configurationManagerService.getPreference(`fiscalprinter.printer_header_${i}`) || "";
            }

            for (let i = 1; i <= 6; i++) {
                printerOptions[`invoice_footer${i}`] = this.configurationManagerService.getPreference(`fiscalprinter.printer_invoice_footer_${i}`) || "";
            }
        }

        return printerOptions;
    };

    /**
     * Sets up the printer and detects the driver for the given printer.
     *
     * @param {Printers} printer - The printer to set up and detect the driver for.
     * @return {Promise<ItalanFiscalPrinterDriver>} - A promise that resolves to the detected printer driver.
     */
    private async setupPrinterAndDetectDriver(printer: Printers): Promise<ItalanFiscalPrinterDriver> {
        const resources = await this.fiscalUtils.getPrinterConfigurationResources();

        const optionsSetup: Record<string, any> = {
            print_notes: !!(this.configurationManagerService.getPreference('fiscalprinter.print_notes')),
            print_description: !!(this.configurationManagerService.getPreference('fiscalprinter.print_description')),
            print_name: !!(this.configurationManagerService.getPreference('fiscalprinter.print_name')),
            print_barcode_id: !!(this.configurationManagerService.getPreference('fiscalprinter.print_barcode_id')),
            print_customer_detail: !!(this.configurationManagerService.getPreference('fiscalprinter.print_customer_details')),
            print_sale_qr_code: !!(this.configurationManagerService.getPreference('fiscalprinter.print_sale_qr_code')),
            print_seller: !!(this.configurationManagerService.getPreference('fiscalprinter.print_seller')),
            resources: resources,
            seller_prefix: this.configurationManagerService.getPreference('fiscalprinter.seller_prefix') || '',
            tail: this.configurationManagerService.getPreference('fiscalprinter.tail') || '', //shop preference no user       string
            ticket_change: !!(this.configurationManagerService.getPreference('fiscalprinter.ticket_change'))
        };

        //Emergency Donation tail
        if (this.configurationManagerService.getPreference('fiscalprinter.enable_donation_tail')) {
            optionsSetup.tail += donationBanner;
        }

        for (let i = 1; i <= 13; i++) {
            optionsSetup[`header${i}`] = this.configurationManagerService.getPreference(`fiscalprinter.printer_header_${i}`) || "";
        }

        let printerDriver = this.printerDriversMap[printer.type]?.[printer.driver];

        if (!printerDriver) {
            throw 'UNSUPPORTED_PRINTER';
        }

        if (printer.connection_type === 'ts') {
            const ipAddress = printer.ip_address?.split(':') || [];

            if (ipAddress.length >= 2) {
                printer.ip_address = ipAddress[0];
                printer.port = parseInt(ipAddress[1]) || 0;
            }
        }

        printerDriver.setup(printer, optionsSetup);

        if (printer.driver === 'epson' && printer.type === 'fiscal') {
            try {
                await new Promise((resolve, reject) => printerDriver.isReachable(resolve, reject));
            } catch (err: any) {
                //If the printer is in RT mode, switch to RT driver
                if (err === 'RT_MODE_ENABLED') {
                    printer.type = 'rt';
                    this.entityManagerService.printers.putOneOfflineFirst(printer);
                    printerDriver = this.printerDriversMap[printer.type]?.[printer.driver];
                    printerDriver.setup(printer, optionsSetup);
                }
            }
        }

        return printerDriver;
    };


    /**
     * Sends the printer status to the server.
     *
     * @param {Printers} printer - The printer object.
     * @return {Promise<void>} - A promise that resolves when the printer status is sent.
     */
    private async sendPrinterStatus(printer: Printers) {
        const printerStatus = await this.getPrinterStatus(printer, { getFiscalStatus: true });

        if (!printerStatus?.printer_serial) {
            return;
        }

        printerStatus.driver = [printer.driver, printer.type].join('-');
        printerStatus.configured_at = printer.configured_at;

        return this.restManager.put('printer_status', null, _.pick(printerStatus, printerStatusFields));
    };

    /**
     * Retrieves the printer driver for a given printer ID.
     *
     * @param {number} printerID - The ID of the printer.
     * @param {string} [method] - The optional method to check if it exists in the printer driver.
     * @return {Promise<{printer: Printers, driver: ItalanFiscalPrinterDriver}>} - An object containing the printer and driver service.
     * @throws {'PRINTERS_NOT_FOUND'} - If the printer is not found.
     * @throws {'METHOD_DOES_NOT_EXIST'} - If the specified method does not exist in the printer driver.
     */
    public async preparePrinterDriver(printerID: number, method?: string) {
        const printer = await this.entityManagerService.printers.fetchOneOffline(printerID);

        if (!printer) {
            throw 'PRINTERS_NOT_FOUND';
        }

        const printerDriver = await this.setupPrinterAndDetectDriver(printer);

        if (method && !(method in printerDriver)) {
            throw 'METHOD_DOES_NOT_EXIST';
        }

        return {
            printer: printer,
            driver: printerDriver
        };
    }

    /**
     * Checks if the given printer is reachable.
     *
     * @param {Printers} printer - The printer to check.
     * @return {Promise} A promise that resolves if the printer is reachable, and rejects otherwise.
     */
    public async isReachable(printer: Printers) {
        const printerDriver = await this.preparePrinterDriver(printer.id!);

        return new Promise((resolve, reject) => printerDriver.driver.isReachable(resolve, reject));
    }

    /**
     * Checks if the driver has the capability to handle the given document type.
     *
     * @param {any} printerData - The printer data to check.
     * @param {string} documentTypeId - The document type ID to check.
     * @return {boolean} Returns true if the driver has the capability, otherwise false.
     */
    public checkDriverCapability(printerData: PrinterDriverInfo, documentTypeId: string) {
        if (['e_invoice', 'summary_e_rc', 'summary_e_nrc'].includes(documentTypeId)) {
            return true;
        }

        const driverFunctionName = documentToDriverMethod[documentTypeId];
        return typeof printerData.driver[driverFunctionName] === 'function';
    }

    /**
     * Prints the fiscal document using the printer and configuration contained in printerDocumentData.
     *
     * @param {Sales} sale - the sale object
     * @param {DocumentPrinterOptions} printerDocumentData - the printer document data
     * @param {PrinterDriverInfo} printerData - the printer data
     * @param {any} optionsPrint - the print options
     * @return {Promise<[boolean, SalesDocuments[]]>} - a promise that resolves to an array containing a boolean value and an array of sales documents
     */
    public async printDocument(sale: Sales, printerDocumentData: DocumentPrinterOptions, printerData: PrinterDriverInfo, optionsPrint: any): Promise<[boolean, SalesDocuments[]]> {
        const driverFunctionName = documentToDriverMethod[printerDocumentData.document_type.id] as keyof ItalanFiscalPrinterDriver;

        if (!(driverFunctionName in printerData.driver)) {
            throw 'METHOD_DOES_NOT_EXIST';
        }

        const saleToPrint = structuredClone(sale);

        let options = typeof (optionsPrint) === 'object' ? structuredClone(optionsPrint) : {};
        options.itemsMap = printerDocumentData.itemsMap;

        //Put items with sale type before refunds/accounts/coupons
        const saleItemsByType: Record<string, SalesItems[]> = { sale: [], other: [] };

        for (const saleItem of (saleToPrint.sale_items || [])) {
            if (saleItem.type === 'sale') {
                saleItemsByType.sale.push(saleItem);
            } else {
                saleItemsByType.other.push(saleItem);
            }
        }

        saleToPrint.sale_items = [...saleItemsByType.sale, ...saleItemsByType.other];

        //Fetch the departments used in the sale
        const departments = await this.entityManagerService.departments.fetchCollectionOffline({
            id_in: [...new Set(saleToPrint.sale_items!.map((saleItem) => saleItem.department_id))]
        });

        //Check if a department is missing a printer_code
        const hasMissingPrinterCode = departments.some((department: any) => !department.printer_code);

        if (hasMissingPrinterCode) {
            throw 'DEPARTMENTS_MISSING_PRINTER_CODE';
        }

        const departmentsById = keyBy(departments, (d) => d.id);

        //Replace department_id with the department printer_code
        for (const saleItem of (saleToPrint.sale_items || [])) {
            const itemDeparmtment = departmentsById[saleItem.department_id];

            if (!itemDeparmtment) {
                throw 'MISSING_DEPARTMENT';
            }

            saleItem.department_id = itemDeparmtment.printer_code!;
        }

        try {
            const saleDocuments = await new Promise((resolve, reject) => printerData.driver[driverFunctionName]!(saleToPrint, options, resolve, reject)) as SalesDocuments[];

            return [true, saleDocuments];
        } catch (error: any) {
            if (!['PAPER_END', 'SALE_PRINT_ERROR', 'EPSON.ERROR_3'].includes(error)) {
                throw error;
            }

            let result = await this.confirmDialog.show(this.$translate.instant('PRINTERS.FISCAL.ASK_IF_PRINTED', { value: this.printerErrorFiscal.translateError(error) }));

            if (!result) {
                throw 'SALE_NOT_CLOSED'
            }

            if(typeof printerData.driver.getLastReceipt === 'function') {
                // New incomplete receipt handling
                const lastReceipt = await printerData.driver.getLastReceipt(printerData.printer);

                if(!lastReceipt) {
                    throw 'SALE_NOT_CLOSED';
                }

                //Check if this receipt exists in the cloud
                const existingSale = await this.entityManagerService.sales.fetchCollectionOnline({
                    'pagination': false,
                    'sale_documents.printer_serial': lastReceipt.printer_serial,
                    'sale_documents.sequential_number': lastReceipt.sequential_number,
                    'sale_documents.document_type': lastReceipt.document_type
                }) as Sales[];

                if(existingSale.length) {
                    throw 'DOCUMENT_NOT_EMITTED';
                }

                //Show receipt in dialog
                const confirm = await this.saleDocumentsViewer.openDialog({ data: {
                    sale: {
                        ...saleToPrint,
                        sale_documents: [lastReceipt]
                    },
                    confirmMode: true
                }});

                if(!confirm) {
                    throw 'SALE_NOT_CLOSED';
                }

                return [true, [lastReceipt]];
            } else {
                // Legacy incomplete receipt handling (for drivers that don't have lastReceipt function)
                this.errorsLogger.sendReport({
                    subject: "Vendita chiusa senza documento",
                    message: "La seguente vendita è stata chiusa senza documento poichè l'operatore, a seguito di un errore di comunicazione con la stampante, ha confermato l'effettiva stampa dello scontrino",
                    meta: {
                        uuid_vendita: sale.uuid || sale.id,
                        nome_vendita: sale.name,
                        totale: sale.final_amount,
                    }
                });

                return [true, []];
            }
        }
    }

    /**
     * Prints a non-fiscal sale using the specified printer.
     *
     * @param {Sales} sale - The sale object to print.
     * @param {Printers} printer - The printer to use for printing.
     * @return {Promise} A promise that resolves when the sale is printed successfully, or rejects with an error if there was an issue.
     */
    public async printNonFiscalSale(sale: Sales, printer: Printers) {
        if (sale.final_amount! < 0 && sale.sale_items?.find((saleItem) => (saleItem.quantity > 0))) {
            throw 'INCONGRUITY_ON_SALE';
        }

        const printerData = await this.preparePrinterDriver(printer.id!);

        const optionsPrint = {
            can_open_cash_drawer: this.configurationManagerService.isFunctionEnabledOptout('cashregister.fiscalprinter.can_open_cash_drawer')
        };

        return new Promise((resolve, reject) => printerData.driver.printNonFiscal(sale, optionsPrint, resolve, reject));
    }

    /**
     * Prints a non-fiscal document on the specified printer.
     *
     * @param {string[]} documentToPrint - The document to be printed.
     * @param {Printers} printer - The printer to use for printing.
     * @param {PrintFreeOptions} options - Additional options for printing.
     * @return {Promise<void>} - A promise that resolves when the document has been printed.
     */
    public async printFreeNonFiscal(documentToPrint: string[], printer: Printers, options: PrintFreeOptions) {
        if (typeof options != 'object') {
            options = {};
        }

        const printerDriver = await this.setupPrinterAndDetectDriver(printer);

        if (typeof printerDriver.printFreeNonFiscal != 'function') {
            throw 'PRINTERS.FISCAL.FUNCTION_NOT_AVAILABLE';
        }

        //Send the document to the driver
        await printerDriver.printFreeNonFiscal(documentToPrint, options);
    }

    /**
     * Uploads a logo to a printer.
     *
     * @param {string | null} logoToSave - The logo to upload.
     * @param {number} printerID - The ID of the printer.
     * @param {any} locations - The locations.
     * @return {Promise<void>} A promise that resolves when the logo is set.
     */
    public async setLogo(logoToSave: (string | null), printerID: number, locations: any) {
        let printerData: (PrinterDriverInfo | null) = null;

        //Send the document to the driver
        printerData = await this.preparePrinterDriver(printerID, 'setLogo');

        await printerData.driver.setLogo!(logoToSave, printerData.printer, locations);
    }

    /**
     * Prints the logo using the specified base64 data, printer IP address, and type.
     * (Currently only for Epson printers)
     * @param {string} base64Data - The base64 encoded logo data to be printed.
     * @param {string} printerIp - The IP address of the printer.
     * @param {string} type - The type of the printer.
     * @return {Promise<void>} - A promise that resolves when the logo is printed successfully.
     */
    public async printLogo(base64Data: string, printerIp: string, type: string) {
        const printer = { name: 'Temporary Printer', ip_address: printerIp, ssl: false, driver: "epson", type: type };

        const printerDriver = await this.setupPrinterAndDetectDriver(printer);

        await printerDriver.printLogo!(base64Data, printer);
    }

    /**
     * Prints a courtesy receipt using the specified printer.
     *
     * @param {Sales} origSale - the sale to print
     * @param {number} printerID - the ID of the printer to use
     * @return {Promise<void>} - a promise that resolves when the receipt is printed
     */
    public async printCourtesyReceipt(origSale: Sales, printerID: number) {
        const sale = structuredClone(origSale);
        const printerData = await this.preparePrinterDriver(printerID, 'printCourtesyReceipt');

        await new Promise((resolve, reject) => printerData!.driver.printCourtesyReceipt(sale, {}, resolve, reject));
    }

    /**
     * Prints an order using the specified printer.
     *
     * @param {Orders} order - The order to be printed.
     * @param {number} printerID - The ID of the printer to use.
     * @return {Object} - An object containing the printer name and the status of the print operation.
     */
    public async printOrder(order: Orders, printerID: number) {
        let printerData: PrinterDriverInfo | null = null;

        try {
            printerData = await this.preparePrinterDriver(printerID, 'printOrder');

            await new Promise((resolve, reject) => printerData!.driver.printOrder(order, resolve, reject));

            return {
                printer_name: printerData.printer.name,
                status: 'success'
            }
        } catch (error: any) {
            throw {
                printer_name: printerData?.printer.name || '',
                status: 'error',
                msg_error: error
            };
        }
    }

    /**
     * Opens the printer cash drawer.
     *
     * @param {DocumentPrinterOptions} printerDocumentData - The printer document data.
     * @return {Promise<void>} A promise that resolves when the cash drawer is opened.
     */
    public async openCashDrawer(printerDocumentData: DocumentPrinterOptions) {
        const printerData = await this.preparePrinterDriver(printerDocumentData.printer.id!, 'openCashDrawer');

        await new Promise((resolve, reject) => printerData.driver.openCashDrawer(resolve, reject));
    }

    /**
     * Display text on a printer display.
     *
     * @param {Printers} printer - The printer to display text on.
     * @param {string[]} textLines - An array of text lines to display.
     * @return {Promise<void>} A promise that resolves when the text is displayed.
     */
    public async displayText(printer: Printers, textLines: string[]) {
        const printerData = await this.preparePrinterDriver(printer.id!, 'displayText');

        await printerData.driver.displayText!(printer, textLines);
    }

    public async recoverDailyClosings(printerID: number, lastDailyClosing: number, numDailyClosings: number): Promise<DailyClosingSchema[]> {
        const printerData = await this.preparePrinterDriver(printerID, 'getDailyClosing');
        const printerStatus = await this.getPrinterStatus(printerData.printer);

        if(!printerStatus.printer_serial) {
            return [];
        }

        //Get last numDailyClosings daily closings for this printer
        const lastDailyClosings = await this.entityManagerService.dailyClosings.fetchCollectionOnline({
            printer_serial: printerStatus?.printer_serial,
            pagination: true,
            per_page: numDailyClosings + 5,
            orderby_desc: 'sequential_number',
            sequential_number_max: (lastDailyClosing - 1)
        }).then((resp) => Array.isArray(resp) ? resp : resp.results);

        const dailyClosingsByNumber = keyBy(lastDailyClosings, dc => dc.sequential_number);

        const result: DailyClosingSchema[] = [];

        // Check if there are missing daily closings
        for(let i = (lastDailyClosing - numDailyClosings); i < lastDailyClosing; i++) {
            if(dailyClosingsByNumber[i]) {
                continue;
            }

            //Recover missing daily closing
            const missingDailyClosing = await printerData.driver.getDailyClosing!(printerData.printer, i);

            if(missingDailyClosing?.document_content) {
                result.push(missingDailyClosing);

                this.entityManagerService.dailyClosings.postOneOfflineFirst({
                    ...missingDailyClosing,
                    printer_id: printerID,
                    printer_name: printerData.printer.name
                });
            }
        }

        return result;
    }

    /**
     * Executes the daily closing process.
     *
     * @param {Printers} printer - The printer object.
     * @param {any} data - Additional data.
     * @return {Promise<[string, any[], DailyClosingSchema]>} - A promise that resolves to an array containing the status, errors, and daily closing document.
     */
    public async dailyClosing(printer: Printers, data: any): Promise<{ status: ('COMPLETED' | 'FAILED'), errors: string[] }> {
        if (typeof data != 'object') {
            data = {};
        }

        const errors: string[] = []
        const printerID = printer.id!;
        const printerData = await this.preparePrinterDriver(printerID);

        const convertDateToDDMMYY = (date: Date) => this.$filter('sclDate')(date, 'DDMMYY');

        let isSuccessFul = false;

        try {
            //Send printer status to our monitoring servers (Pre-closing)
            try {
                await this.sendPrinterStatus(printer);
            } catch (err) {
                //Nothing to do
            }

            //Perform daily closing
            const dailyClosingDocument: Partial<DailyClosingSchema> = await new Promise((resolve, reject) => printerData.driver.dailyClosing(resolve, reject));

            const printerSerial = dailyClosingDocument?.printer_serial || '';
            const sequentialNumber = dailyClosingDocument?.sequential_number;

            if(dailyClosingDocument?.document_content) {
                this.entityManagerService.dailyClosings.postOneOfflineFirst({
                    ...dailyClosingDocument,
                    ...data,
                    printer_id: printerID,
                    printer_name: printer.name
                });

                const numDailyClosings = parseInt(this.configurationManagerService.getPreference('printers.recover_old_daily_closings') || '7');

                if(numDailyClosings && printerSerial && sequentialNumber) {
                    try {
                        await this.recoverDailyClosings(printerID, sequentialNumber, numDailyClosings);
                    } catch(err) {
                        //Nothing to do
                    }
                }
            } else if (['epson', 'rch', 'axon', 'axon_g100'].includes(printerData.printer.driver)) {
                errors.push('PRINTERS.FISCAL.DAILY_CLOSING_DOCUMENT_RETRIEVAL_FAILED');
            }

            //Send printer status to our monitoring servers (Post-closing)
            try {
                await this.sendPrinterStatus(printer);
            } catch (err) {
                //Nothing to do
            }

            let dgfeReadSuccess = false;
            FiscalPrintersService.fiscalClosingSubject.next({ printer: printer, action: 'dgfeRead', status: 'IN_PROGRESS' });

            try {
                const now = new Date();
                const todayOpen = this.util.getDayStartTime();

                Object.assign(data, {
                    from_date: todayOpen.toISOString(),
                    to_date: now.toISOString()
                });

                const resDgfe = await this.readDgfeBetween(printer, convertDateToDDMMYY(todayOpen), convertDateToDDMMYY(now));
                dgfeReadSuccess = true;

                this.entityManagerService.dgfe.postOneOfflineFirst({
                    content: resDgfe,
                    printer_name: printer.name,
                    printer_driver: printer.driver,
                    printer_serial: printerSerial,
                    printer_id: printerID,
                    start_date: todayOpen.toISOString(),
                    end_date: now.toISOString()
                });
            } catch (err) {
                //Nothing to do
            }

            FiscalPrintersService.fiscalClosingSubject.next({ printer: printer, action: 'dgfeRead', status: dgfeReadSuccess ? 'COMPLETED' : 'FAILED' });

            if (printerData.printer.configuration_pending) {
                FiscalPrintersService.fiscalClosingSubject.next({ printer: printer, action: 'configure', status: 'IN_PROGRESS' });

                let configurationSuccessful = false;

                try {
                    await this.configurePrinter(printerData.printer, this.getPrinterOptions(printerData.printer));
                    configurationSuccessful = true;
                    printerData.printer.configuration_pending = false;

                    await this.entityManagerService.printers.putOneOnline(printerData.printer);
                } catch (err) {
                    //Nothing to do
                }

                FiscalPrintersService.fiscalClosingSubject.next({ printer: printer, action: 'configure', status: configurationSuccessful ? 'COMPLETED' : 'FAILED' });
            }

            isSuccessFul = true;
        } catch (err: any) {
            errors.push(err);
        }
        
        return {
            errors,
            status: isSuccessFul ? 'COMPLETED' : 'FAILED',
        }
    }

    /**
     * Searches for paired printers (only for RCH legacy printers).
     *
     * @return {Promise<any>} A promise that resolves with the discovered Bluetooth RCH printers.
     * @deprecated
     */
    public discoverPairedBluetoothRch() {
        const RchMFDriver = this.printerDriversMap['fiscal']['rch'] as any;

        return new Promise((resolve, reject) => RchMFDriver.discoverPairedBT(resolve, reject));
    }

    /**
     * Configures the printer for Bluetooth connection (only for RCH legacy printers).
     *
     * @param {Printers} printer - The printer to be configured.
     * @return {Promise<any>} A promise that resolves when the printer is successfully configured.
     * @throws {string} Thrown if the printer driver is not 'rch'.
     * @deprecated
     */
    public configurePrinterLanBluetoothRch(printer: Printers) {
        if (printer.driver !== 'rch') {
            throw "NOT_RCH";
        }

        const RchMFDriver = this.printerDriversMap['fiscal']['rch'] as any;

        return new Promise((resolve, reject) => RchMFDriver.configureLanBT(printer, resolve, reject));
    }

    /**
     * Configures the printer
     *
     * @param {Printers} printer - The printer to configure.
     * @param {any} options - The configuration options for the printer.
     * @return {Promise<null>} A promise that resolves when the printer is configured.
     */
    public async configurePrinter(printer: Printers, options: any) {
        const printerDriver = await this.setupPrinterAndDetectDriver(printer);

        if (typeof printerDriver.autoConfigurePrinter != 'function') {
            throw 'METHOD_DOES_NOT_EXIST';
        }

        //Check departments printer_code
        const departments = await this.entityManagerService.departments.fetchCollectionOffline();

        const hasMissingPrinterCode = departments.some((department: any) => !department.printer_code);

        //Regenerate printer codes if some departments don't have one
        if (hasMissingPrinterCode) {
            //TODO: Implement
            const newDepartments = await this.restManager.getOne('departments', 'regenerate_printer_codes');

            //Reload departments
            await this.entityManagerService.departments.reloadEntityCollection({ avoidSync: true, collection: newDepartments });
        }

        await printerDriver.autoConfigurePrinter(printer, options);

        try {
            if (typeof printerDriver.setLogo == 'function') {
                let data: string | null = null;

                const imageUrl = await this.ImagesManager.getImage("logo_small.bin");
                const response = await this.$http.get(imageUrl, { responseType: 'blob' });

                if (response.status === 200 && response.data !== null) {
                    const dataURL = await blobToDataURL(response.data as Blob);
                    data = dataURLToBase64(dataURL);
                }

                try {
                    await this.setLogo(data, printer.id!, [1, 3]);
                } catch (error: any) {
                    console.error(error);

                    if (data) {
                        console.warn("Failed to upload logo. Deleting logo from printer.");
                        await this.setLogo(null, printer.id!, [1, 3])
                    }
                }
            }
        } catch (err) {
            console.warn(err);
        }

        return new Promise((resolve, reject) => {
            resolve(null);

            //Send printer status to our monitoring servers
            this.sendPrinterStatus(printer);

            //Update printer configuration date
            printer.configured_at = new Date();
            this.entityManagerService.printers.putOneOnline(printer);
        });
    }

    /**
     * Retrieves the status of a printer.
     *
     * @param {Printers} printer - The printer object.
     * @param {any} options - Additional options for retrieving printer status.
     * @return {Promise<any>} A promise that resolves with the printer status.
     */
    public async getPrinterStatus(printer: Printers, options?: any) {
        const printerData = await this.preparePrinterDriver(printer.id!, 'getPrinterStatus');

        return printerData.driver.getPrinterStatus(options);
    }

    /**
     * Executes a daily read operation on the specified printer.
     *
     * @param {DocumentPrinterOptions} printerDocumentData - The options for the printer document.
     * @return {Promise<type>} - A promise that resolves to the result of the daily read operation.
     */
    public async dailyRead(printerDocumentData: DocumentPrinterOptions) {
        const printerData = await this.preparePrinterDriver(printerDocumentData.printer.id!, 'dailyRead');

        return printerData.driver.dailyRead();
    }

    /**
     * Executes a deposit transaction.
     *
     * @param {DocumentPrinterOptions} printerDocumentData - The data for the printer document.
     * @param {number} cash - The amount of cash to deposit.
     * @return {Promise<void>} A promise that resolves when the deposit transaction is completed.
     */
    public async deposit(printerDocumentData: DocumentPrinterOptions, cash: number) {
        const printerData = await this.preparePrinterDriver(printerDocumentData.printer.id!, 'deposit');

        return printerData.driver.deposit!(cash);
    }

    /**
     * Executes a withdrawal transaction.
     *
     * @param {DocumentPrinterOptions} printerDocumentData - The printer document data.
     * @param {number} cash - The amount of cash to withdraw.
     * @return {Promise<any>} - A promise that resolves when the withdrawal transaction is completed.
     */
    public async withdrawal(printerDocumentData: DocumentPrinterOptions, cash: number) {
        const printerData = await this.preparePrinterDriver(printerDocumentData.printer.id!, 'withdrawal');

        return printerData.driver.withdrawal!(cash);
    }

    /**
     * Prints the fiscal memory.
     *
     * @param {DocumentPrinterOptions} printerDocumentData - The printer document data.
     * @return {Promise<void>} A promise that resolves when the fiscal memory is printed.
     */
    public async printFiscalMemory(printerDocumentData: DocumentPrinterOptions) {
        const printerData = await this.preparePrinterDriver(printerDocumentData.printer.id!, 'printFiscalMemory');

        return printerData.driver.printFiscalMemory!();
    }

    /**
     * Prints the fiscal memory between two dates.
     *
     * @param {DocumentPrinterOptions} printerDocumentData - The printer document data.
     * @param {boolean} corrispettivi - Indicates whether to include corrispettivi.
     * @param {string} from - The starting date.
     * @param {string} to - The ending date.
     * @returns {Promise<any>} A promise that resolves to the result of printing the fiscal memory.
     */
    public async printFiscalMemoryBetween(printerDocumentData: DocumentPrinterOptions, corrispettivi: boolean, from: string, to: string) {
        const printerData = await this.preparePrinterDriver(printerDocumentData.printer.id!, 'printFiscalMemoryBetween');

        return printerData.driver.printFiscalMemoryBetween!(corrispettivi, from, to);
    }

    /**
     * Reads the whole DGFE for a printer.
     *
     * @param {Printers} printer - The printer object.
     * @return {Promise<any>} - A promise that resolves with the DGFE data.
     */
    public async readDgfe(printer: Printers) {
        const printerData = await this.preparePrinterDriver(printer.id!, 'readDgfe');

        return printerData.driver.readDgfe!('read');
    }

    /**
     * Reads the DGFE between two dates for a specific printer.
     *
     * @param {Printers} printer - The printer object.
     * @param {string} from - The start date.
     * @param {string} to - The end date.
     * @return {Promise<any>} - The result of the read operation.
     */
    public async readDgfeBetween(printer: Printers, from: string, to: string) {
        const printerData = await this.preparePrinterDriver(printer.id!, 'readDgfeBetween');

        return printerData.driver.readDgfeBetween!('read', from, to);
    }

    /**
     * Prints the whole DGFE using the specified printer.
     *
     * @param {DocumentPrinterOptions} printerDocumentData - The options for printing the document.
     * @return {Promise<type>} - The result of the print operation.
     */
    public async printDgfe(printerDocumentData: DocumentPrinterOptions) {
        const printerData = await this.preparePrinterDriver(printerDocumentData.printer.id!, 'readDgfe');

        return printerData.driver.readDgfe!('print');
    }

    /**
     * Prints DGFE data between two dates.
     *
     * @param {DocumentPrinterOptions} printerDocumentData - The printer document data.
     * @param {string} from - The start date.
     * @param {string} to - The end date.
     * @return {Promise<void>} A promise that resolves when the DGFE is printed.
     */
    public async printDgfeBetween(printerDocumentData: DocumentPrinterOptions, from: string, to: string) {
        const printerData = await this.preparePrinterDriver(printerDocumentData.printer.id!, 'readDgfeBetween');

        return printerData.driver.readDgfeBetween!('print', from, to);
    }

    /**
     * Sends a firmware upgrade request to the specified printer.
     *
     * @param {Printers} printer - The printer object to send the upgrade request to.
     * @param {any} updateData - The data for the firmware upgrade.
     * @return {Promise<any>} A promise that resolves with the result of the upgrade request.
     */
    public async sendFwUpgradeRequest(printer: Printers, updateData: any) {
        const printerData = await this.preparePrinterDriver(printer.id!, 'sendFWUpgradeRequest');

        return printerData.driver.sendFWUpgradeRequest!(printer, updateData);
    }

    /**
     * Checks if the specified printer supports electronic receipts.
     *
     * @param {Printers} printer - The printer to check.
     * @return {Promise<boolean>} A boolean indicating whether the printer supports electronic receipts.
     */
    public async canUseEReceipt(printer: Printers) {
        const printerData = await this.preparePrinterDriver(printer.id!, 'canUseEReceipt');

        return printerData.driver.canUseEReceipt!(printer);
    }
}

FiscalPrintersService.$inject = ["errorsLogger", "$http", "$filter", "$translate", "entityManager", "checkManager", "ImagesManager", "restManager", "confirmDialog", "util", "printerErrorFiscal", "saleDocumentsViewer", "fiscalUtils", "EpsonMFDriver", "EpsonRTDriver", "RchMFDriver", "RchRTDriver", "CustomRTDriver", "AxonRTDriver", "AxonG100RTDriver", "DtrRTDriver"];