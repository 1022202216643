import { CdkDrag } from "@angular/cdk/drag-drop";
import { CommonModule } from "@angular/common";
import { Component, Input, effect, inject } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatDividerModule } from "@angular/material/divider";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatTabsModule } from "@angular/material/tabs";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { TilbyGesturesDirective } from "@tilby/tilby-ui-lib/directives/tilby-gestures";
import { TilbyCurrencyPipe } from "@tilby/tilby-ui-lib/pipes/tilby-currency";
import { TilbyDatePipe } from "@tilby/tilby-ui-lib/pipes/tilby-date";
import { mobileCheck } from "@tilby/tilby-ui-lib/utilities";
import { restManager } from "app/ajs-upgraded-providers";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { ConfigurationManagerService, EntityManagerService, StorageManagerService } from "src/app/core";
import { BookingShifts, Bookings, Customers, Rooms, RoomsTables } from "tilby-models";
import { BookingsDataInput, BookingsStateService } from "../../../services/bookings.state.service";
import { AddEditNewBookingDialogService } from "src/app/dialogs/bookings/add-edit-new-booking-dialog/add-edit-new-booking-dialog.component";
import { isArray } from "angular";

type TablesById = {
    table_name: string,
    table_id: number,
    room_name: string,
    room_id?: number,
    covers: number,
    type: string
};

@Component({
    selector: "app-booking-data",
    templateUrl: "./booking-data.component.html",
    styleUrls: ["./booking-data.component.scss"],
    standalone: true,
    imports: [
        CommonModule,
        MatTabsModule,
        MatSelectModule,
        MatButtonModule,
        MatIconModule,
        MatInputModule,
        MatDividerModule,
        FormsModule,
        ReactiveFormsModule,
        CdkDrag,
        TranslateModule,
        TilbyDatePipe,
        TilbyCurrencyPipe,
        TilbyGesturesDirective,
        NgxSkeletonLoaderModule
    ],
})
export class BookingDataComponent {

    private readonly tilbyDatePipe = inject(TilbyDatePipe);
    private readonly restManagerService = inject(restManager);
    private readonly translateService = inject(TranslateService);
    private readonly entityManager = inject(EntityManagerService);
    private readonly bookingsStateService = inject(BookingsStateService);
    private readonly entityManagerService = inject(EntityManagerService);
    private readonly configurationManagerService = inject(ConfigurationManagerService);
    private readonly addEditBookingDialogService = inject(AddEditNewBookingDialogService);

    isMobile = mobileCheck();

    tabIndex = 0;
    tabLabels: string[] = [
        this.translateService.instant("BOOKINGS.NEW_BOOKING_LIST.ALL"),
        this.translateService.instant("BOOKINGS.NEW_BOOKING_LIST.CONFIRMED"),
        this.translateService.instant("BOOKINGS.NEW_BOOKING_LIST.PROVISIONAL")
    ];

    @Input() rooms: Rooms[] = [];
    @Input() bookings: Bookings[] = [];

    bookingsDataInput!: BookingsDataInput;
    tablesById: TablesById[] = [];
    bookingShifts: BookingShifts[] = [];

    bookingsFiltered: any[] = [];
    bookingsAll: any[] = [];
    bookingsAllFiltered: any[] = [];

    bookingsConfirmed: any[] = [];
    bookingsConfirmedFiltered: any[] = [];

    bookingsProvisional: any[] = [];
    bookingsProvisionalFiltered: any[] = [];

    shifts: any[] = [];
    shift: BookingShifts | undefined = undefined;
    shiftSelected: BookingShifts | undefined = undefined;

    bookingCustomers: Customers[] = [];
    bookingCustomersFiltered: Customers[] = [];

    filter: "" = "";
    filterId!: number;
    filteredCustomer: Customers[] = [];

    statuses: string[] = ['provisional', 'confirmed', 'arrived', 'seated', 'departed', 'noshow'];
    bookingStatuses: { id: string; name: string; }[] = [];

    openFilter: boolean = false;
    firstLoad: boolean = true;
    loadingData: boolean = false;

    channels : string[] = ['alvolo', 'thefork', 'plateform', 'lean'];
    timezone: string | null = this.configurationManagerService.getPreference('general.timezone');

    dropTargetElement: any = null; // Store the drop target element

    constructor(){
        this.bookingsStateService.bookingObservable.subscribe((updatedBookings) => {
            this.bookings = updatedBookings;
            this.bookingsDataInput = this.bookingsStateService.selectBookingsData();
            this.filterBookings();
            this.setBackgroundColor();
        });
        
        effect(() => {
            this.bookingsDataInput = this.bookingsStateService.selectBookingsData();
            this.filterBookings();
            this.setBackgroundColor();
        });

        effect(() => {
            if (this.bookingsStateService.isRefreshData()) {
                this.getData();
                this.bookingsStateService.isRefreshData.set(false);
            }
        }, { allowSignalWrites: true });

        effect(() => {
            if(this.bookingsStateService.bookingData()) {
                this.bookings = this.bookingsStateService.bookingData();
                this.bookingsDataInput = this.bookingsStateService.selectBookingsData();
                this.filterBookings();
                this.setBackgroundColor();
            }
        }, { allowSignalWrites: true });
    }

    ngOnInit() {
        this.getData();

        this.rooms.forEach((room) => {
            if(room.tables) {
                room.tables.forEach((table) => {
                    if(table.id !== undefined && !table.name.startsWith('ph_')) {
                        this.tablesById[table.id] = {
                            table_name: table.name,
                            table_id: table.id,
                            room_name: room.name,
                            room_id: room.id,
                            covers: table.covers,
                            type: table.order_type
                        };
                    }
                });
            }
        });

        this.statuses.forEach((status) => {
            this.bookingStatuses.push({
                id: status,
                name: this.translateService.instant('BOOKINGS.STATUSES.' + status.toUpperCase())
            });
        });
    }

    ngAfterViewInit(){
        this.setBackgroundColor();

        // StorageManagerService.storageUpdates$.subscribe(() => this.bookingsStateService.getBookings());
    }

    async getData() {
        this.loadingData = true;
        this.shifts = await this.restManagerService.getList("/booking_shifts", { pagination: false });
        this.bookings = this.bookingsStateService.bookingData();
        this.bookingShifts = await this.entityManagerService.bookingShifts.fetchCollectionOffline();
        this.bookingsDataInput = this.bookingsStateService.selectBookingsData();
        this.loadingData = false;

        this.filterBookings();
        this.setBackgroundColor();
    }


    filterOpen() {
        this.openFilter = !this.openFilter;
    }

    filterBookings() {
        this.bookingsFiltered = this.bookings.filter((booking) => {
            const transformDate = this.tilbyDatePipe.transform(booking.booked_for, 'YYYY-MM-dd HH:mm:ss');
            const bookedForDate = TilbyDatePipe.date({outputFormat: 'date', date: transformDate || ''});

            if(bookedForDate)
                return bookedForDate.getDate() === this.bookingsDataInput.date.getDate() &&
                bookedForDate.getMonth() === this.bookingsDataInput.date.getMonth() &&
                bookedForDate.getFullYear() === this.bookingsDataInput.date.getFullYear();
        });
        this.filterBookingsAll();
        this.filterBookingsConfirmed();
        this.filterBookingsProvisional();

        this.filterCustomerForShift(this.bookingsFiltered);
        this.setBackgroundColor();
    }

    filterBookingsAll() {
        this.bookingsAll = this.bookingsAllFiltered = this.bookingsFiltered.sort((a, b) => {

            const a_a = TilbyDatePipe.shopDate({date: a.booked_for}) as any;
            const a_a_a = TilbyDatePipe.date({outputFormat: 'date', date: a_a || ''}).getHours();

            const b_b = TilbyDatePipe.shopDate({date: b.booked_for}) as any;
            const b_b_b = TilbyDatePipe.date({outputFormat: 'date', date: b_b || ''}).getHours();

            return a_a_a - b_b_b;
        });

        setTimeout(() => this.bookingsStateService.bookingFiltered.set(this.bookingsAll), 1000);
    }

    filterBookingsConfirmed() {
        this.bookingsConfirmed = this.bookingsConfirmedFiltered = this.bookingsFiltered.filter((booking) => booking.status === "confirmed" || booking.status === "arrived" || booking.status === "seated" || booking.status === "departed");
    }

    filterBookingsProvisional() {
        this.bookingsProvisional = this.bookingsProvisionalFiltered = this.bookingsFiltered.filter((booking) => booking.status === "provisional");
    }

    onShiftChange(event: any) {
        this.setBackgroundColor();

        this.shiftSelected = event;

        if (!event || !event.value) {
            this.filterBookingsAll();
            this.filterBookingsConfirmed();
            this.filterBookingsProvisional();

            if(this.filter.trim()) {
                this.bookingsAll = this.bookingsAll.filter((booking) => booking.customer_id == this.filteredCustomer[0].id);
                this.bookingsConfirmed = this.bookingsConfirmed.filter((booking) => (booking.status === "confirmed" || booking.status === "arrived" || booking.status === "seated" || booking.status === "departed") && booking.customer_id == this.filteredCustomer[0].id);
                this.bookingsProvisional = this.bookingsProvisional.filter((booking) => booking.status === "provisional" && booking.customer_id == this.filteredCustomer[0].id);
            }
        } else {
            const start_time = TilbyDatePipe.shopDate({date: event.value.start_time}) as any;
            const start_time_date = TilbyDatePipe.date({outputFormat: 'date', date: start_time || ''});

            if(start_time_date){
                start_time_date.setHours(event.value.start_time.split(":")[0]);
                start_time_date.setMinutes(event.value.start_time.split(":")[1]);
                start_time_date.setSeconds(event.value.start_time.split(":")[2]);
            }

            const end_time = TilbyDatePipe.shopDate({date: event.value.end_time}) as any;
            const end_time_date = TilbyDatePipe.date({outputFormat: 'date', date: end_time || ''});

            if(end_time_date){
                end_time_date.setHours(event.value.end_time === "00:00:00" ? "23" : event.value.end_time.split(":")[0]);
                end_time_date.setMinutes(event.value.end_time === "00:00:00" ? "59" : event.value.end_time.split(":")[1]);
                end_time_date.setSeconds(event.value.end_time.split(":")[2]);
            }

            this.filterBookingsAll();

            this.bookingsAllFiltered = this.bookingsAll = this.bookingsAll.filter((booking) => {

                const booking_time = TilbyDatePipe.shopDate({date: booking.booked_for}) as any;
                const booking_time_date = TilbyDatePipe.date({outputFormat: 'date', date: booking_time || ''}).getTime();

                if(this.filter.trim()) {
                    return booking_time_date >= start_time_date.getTime() && booking_time_date <= end_time_date.getTime() && this.filteredCustomer.find(customer => customer.id == booking.booking_customer.id);
                } else {
                    return booking_time_date >= start_time_date.getTime() && booking_time_date <= end_time_date.getTime();
                }
            });

            this.filterBookingsConfirmed();
            this.bookingsConfirmedFiltered = this.bookingsConfirmed = this.bookingsConfirmed.filter((booking) => {
                const booking_time = TilbyDatePipe.shopDate({date: booking.booked_for}) as any;
                const booking_time_date = TilbyDatePipe.date({outputFormat: 'date', date: booking_time || ''}).getTime();

                if(this.filter.trim()) {
                    return booking_time_date >= start_time_date.getTime() && booking_time_date <= end_time_date.getTime() && this.filteredCustomer.find(customer => customer.id == booking.booking_customer.id)
                } else {
                    return booking_time_date >= start_time_date.getTime() && booking_time_date <= end_time_date.getTime();
                }
            });

            this.filterBookingsProvisional();
            this.bookingsProvisionalFiltered = this.bookingsProvisional = this.bookingsProvisional.filter((booking) => {
                const booking_time = TilbyDatePipe.shopDate({date: booking.booked_for}) as any;
                const booking_time_date = TilbyDatePipe.date({outputFormat: 'date', date: booking_time || ''}).getTime();

                if(this.filter.trim()) {
                    return booking_time_date >= start_time_date.getTime() && booking_time_date <= end_time_date.getTime() && this.filteredCustomer.find(customer => customer.id == booking.booking_customer.id)
                } else {
                    return booking_time_date >= start_time_date.getTime() && booking_time_date <= end_time_date.getTime();
                }
            });

            this.setBookingsFiltered();
        }
    }

    filterCustomerForShift(booking: Bookings[]) {
        let customers: Customers[] = [];
        booking.forEach((booking) => {if(booking.booking_customer) customers.push(booking.booking_customer)});

        this.bookingCustomersFiltered = customers.filter((obj, index, self) =>
            index === self.findIndex((o) => JSON.stringify(o) === JSON.stringify(obj))
        );
    }

    onTabChange(event: any) {
        this.tabIndex = event.index;

        this.setBackgroundColor();
        this.setBookingsFiltered();
    }

    onCustomerChange(event: any) {
        if(!this.filter.trim()) {
            this.filterBookingsAll();
            this.filterBookingsConfirmed();
            this.filterBookingsProvisional();

            this.onShiftChange(this.shiftSelected);
        } else {

            this.filteredCustomer = this.bookingCustomersFiltered.filter(customer => {
                return customer.first_name?.toLowerCase().includes(this.filter.toLowerCase()) || customer.last_name?.toLowerCase().includes(this.filter.toLowerCase()) || (customer.first_name?.toLowerCase() + " " + customer.last_name?.toLowerCase()).includes(this.filter.toLowerCase());
            });

            this.bookingsAll = this.bookingsAllFiltered.filter((booking) => {
                return booking.booking_customer.first_name.toLowerCase().includes(this.filter.toLowerCase()) || booking.booking_customer.last_name.toLowerCase().includes(this.filter.toLowerCase()) || (booking.booking_customer.first_name.toLowerCase() + " " + booking.booking_customer.last_name.toLowerCase()).includes(this.filter.toLowerCase()) || booking.pms_reservation_name && booking.pms_reservation_name.toLowerCase().incudes(this.filter.toLowerCase()) || booking.notes && booking.notes.toLowerCase().includes(this.filter.toLowerCase());
            });

            this.bookingsConfirmed = this.bookingsConfirmedFiltered.filter((booking) => {
                return booking.booking_customer.first_name.toLowerCase().includes(this.filter.toLowerCase()) || booking.booking_customer.last_name.toLowerCase().includes(this.filter.toLowerCase()) || (booking.booking_customer.first_name.toLowerCase() + " " + booking.booking_customer.last_name.toLowerCase()).includes(this.filter.toLowerCase()) || booking.pms_reservation_name && booking.pms_reservation_name.toLowerCase().incudes(this.filter.toLowerCase()) || booking.notes && booking.notes.toLowerCase().includes(this.filter.toLowerCase());
            });

            this.bookingsProvisional = this.bookingsProvisionalFiltered.filter((booking) => {
                return booking.booking_customer.first_name.toLowerCase().includes(this.filter.toLowerCase()) || booking.booking_customer.last_name.toLowerCase().includes(this.filter.toLowerCase()) || (booking.booking_customer.first_name.toLowerCase() + " " + booking.booking_customer.last_name.toLowerCase()).includes(this.filter.toLowerCase()) || booking.pms_reservation_name && booking.pms_reservation_name.toLowerCase().incudes(this.filter.toLowerCase()) || booking.notes && booking.notes.toLowerCase().includes(this.filter.toLowerCase());
            });

            this.setBookingsFiltered();
        }

        this.setBackgroundColor();
    }

    editBooking(edit_booking: Bookings, event?: any) {
        const booking = {...edit_booking};

        this.addEditBookingDialogService.openDialog({data: {bookings: this.bookings, bookingShifts: this.bookingShifts, rooms: this.rooms, options: {booking}}})
        .then((result) => {
            if(result){
                if(result.action == 'delete') {
                    this.bookingsStateService.deleteBooking(edit_booking);
                } else if(result.booking){
                    this.bookingsStateService.editBooking(result.booking);
                }

                this.bookingsStateService.isRefreshData.set(true);
            }
        });
    }

    setBackgroundColor() {
        setTimeout(() => {
            const statusSelect = document.querySelectorAll('.status-select');

            if (statusSelect.length > 0) {
                statusSelect.forEach((statusEl, index) => {
                    const status = statusEl.getElementsByClassName('mat-mdc-text-field-wrapper')[0];


                    if(status.textContent === this.translateService.instant('BOOKINGS.STATUSES.CONFIRMED')) {
                        status.setAttribute('style', 'background-color: green !important');
                        const child = Array.from(status.getElementsByClassName('mat-mdc-select-min-line') as HTMLCollectionOf<HTMLElement>)[0];
                        child.setAttribute('style', 'color: white !important;');
                    } else if(status.textContent === this.translateService.instant('BOOKINGS.STATUSES.PROVISIONAL')) {

                        status.setAttribute('style', 'background-color: orange !important');
                        const child = Array.from(status.getElementsByClassName('mat-mdc-select-min-line') as HTMLCollectionOf<HTMLElement>)[0];
                        child.setAttribute('style', 'color: black !important;');

                    } else if(status.textContent === this.translateService.instant('BOOKINGS.STATUSES.ARRIVED')) {
                        status.setAttribute('style', 'background-color: red !important');
                        const child = Array.from(status.getElementsByClassName('mat-mdc-select-min-line') as HTMLCollectionOf<HTMLElement>)[0];
                        child.setAttribute('style', 'color: white !important;');
                    } else {
                        status.setAttribute('style', 'background-color: gray !important');
                        const child = Array.from(status.getElementsByClassName('mat-mdc-select-min-line') as HTMLCollectionOf<HTMLElement>)[0];
                        child.setAttribute('style', 'color: black !important;');
                    }
                })
            }
        }, this.firstLoad ? 250 :0);

        setTimeout(() => {
            this.firstLoad = false;
        }, 200);
    }

    editBookingStatus(event: any, booking: Bookings) {
        this.changeBackgroundColor(event);
        booking.status = event.value;

        this.entityManager.bookings.putOneOnline(booking)
        .then((booking_updated) => {
            if(booking_updated){
                const index = this.bookings.findIndex(booking => booking.id === booking_updated.id);
                this.bookings.splice(index, 1, booking_updated);

                this.filterBookings();
            }
        });
    }

    changeBackgroundColor(event: any) {
        const el = document.getElementById(event.source.id);

        if(el) {
            const wrapper = el.closest('.mat-mdc-text-field-wrapper');

            if(event.value === 'confirmed') {
                if(wrapper) {
                    wrapper.setAttribute('style', 'background-color: green !important');
                    const child = Array.from(wrapper.getElementsByClassName('mat-mdc-select-min-line') as HTMLCollectionOf<HTMLElement>)[0];
                    child.setAttribute('style', 'color: white !important;');
                }
            } else if(event.value === 'provisional') {
                if(wrapper) {
                    wrapper.setAttribute('style', 'background-color: orange !important');
                    const child = Array.from(wrapper.getElementsByClassName('mat-mdc-select-min-line') as HTMLCollectionOf<HTMLElement>)[0];
                    child.setAttribute('style', 'color: black !important;');
                }
            } else if(event.value === 'arrived') {
                if(wrapper) {
                    wrapper.setAttribute('style', 'background-color: red !important');
                    const child = Array.from(wrapper.getElementsByClassName('mat-mdc-select-min-line') as HTMLCollectionOf<HTMLElement>)[0];
                    child.setAttribute('style', 'color: white !important;');
                }
            } else {
                if(wrapper) {
                    wrapper.setAttribute('style', 'background-color: gray !important');
                    const child = Array.from(wrapper.getElementsByClassName('mat-mdc-select-min-line') as HTMLCollectionOf<HTMLElement>)[0];
                    child.setAttribute('style', 'color: black !important;');
                }
            }
        }
    }

    channelImage(channel : string) {
        return this.channels.includes(channel) ? channel : '-';
    }

    swipeRight(event: any) {
        this.bookingsDataInput.date.setDate(this.bookingsDataInput.date.getDate() + (this.bookingsDataInput.type !== 0 ? +1 : +7));
        this.bookingsStateService.selectBookingsData.set({
            date: this.bookingsDataInput.date,
            type: this.bookingsDataInput.type
        });
    }

    swipeLeft(event: any) {

        this.bookingsDataInput.date.setDate(this.bookingsDataInput.date.getDate() + (this.bookingsDataInput.type !== 0 ? -1 : -7));
        this.bookingsStateService.selectBookingsData.set({
            date: this.bookingsDataInput.date,
            type: this.bookingsDataInput.type
        });
    }

    trackByFn(index: number, tabLabel: string): number {
        return index;
    }

    setBookingsFiltered() {
        if(this.tabIndex == 0) {
            this.bookingsStateService.bookingFiltered.set(this.bookingsAll); 
        } else if(this.tabIndex == 1) {
            this.bookingsStateService.bookingFiltered.set(this.bookingsConfirmed);
        } else if(this.tabIndex == 2) {
            this.bookingsStateService.bookingFiltered.set(this.bookingsProvisional);
        }
    }

    // Method triggered when an element is dragged over the target
    onDragOver(event: DragEvent) {
        event.preventDefault(); // Prevent default to allow drop
    }

    // Method triggered when element is dropped
    onDrop(event: DragEvent, booking: Bookings) {
        event.preventDefault(); // Prevent default action
        const data = event.dataTransfer?.getData('application/json'); // Get the JSON string
        if(data) {
            const tableDropped = JSON.parse(data); // Parse the JSON string into an object
            this.editBookingByDragAndDrop(booking, tableDropped);
        }
    }

    async editBookingByDragAndDrop(booking: any, targetTable: RoomsTables){
        let tables: any[] = [];

        this.rooms.forEach((room) => {
            if(isArray(room.tables)) {
                room.tables.forEach((table) => {
                    if(!table.name.startsWith('ph_')) {
                        tables.push({
                            room_name: room.name,
                            room_id: room.id,
                            table_name: table.name,
                            table_id: table.id,
                            covers: table.covers,
                            type: table.order_type,
                            selected: false,
                            available: true
                        });
                    }
                });
            }
        });

        const table = tables.find((table) => table.table_id === targetTable.id);

        booking.tables.push(table);

        booking.tables.sort((table_a: any, table_b: any) => table_a.table_id - table_b.table_id);

        Object.assign(booking, {
            tables: booking.tables.map((table: any) => ({ table_id: table.table_id })),
            booked_for: booking.booked_for,
            external_id: ''
        });

        const { _bookedFor, booking_customer, _customerName, ...bookingToSave } = structuredClone(booking);

        await this.entityManager.bookings.putOneOnline(bookingToSave);

        this.bookingsStateService.editBooking(booking);
    }
}
