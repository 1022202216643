import * as angular from 'angular';
import * as IpSubnetCalculator from 'ip-subnet-calculator';
import * as _ from 'lodash';
import * as moment from 'moment-timezone';

import { stringToLines } from 'src/app/shared/string-utils';

import {
    EpsonBarcode,
    EpsonFont,
    EpsonHRI,
    NFThermalPrinter,
    NFThermalPrinterConstructor
} from 'src/app/shared/model/nf-thermal-printer.model';

import {
    ConfigurationManagerService,
    EntityManagerService
} from 'src/app/core';

import {
    DocumentBuilderService
} from '../document-builder/document-builder';

import {
    OrderItems,
    Orders,
    Printers,
    Sales,
    SalesDocuments,
    SalesItemsPriceChanges,
    SalesPriceChanges
} from 'tilby-models';

import {
    asyncParallelLimit,
    groupBy,
    keyBy,
    MathUtils
} from 'src/app/shared/utils';

import {
    VariationSale,
    VariationSaleItem
} from 'src/app/shared/model/cashregister.model';

import { TranslateService } from '@ngx-translate/core';
import { DocumentPrinterOptions } from 'src/app/shared/model/document-printer.model';

type DriverInfo = {
    driverObj: NFThermalPrinterConstructor;
    port: number;
    configuration: {
        codepage_mapping?: string
        connection_type?: string
        line_spacing?: number
    };
};

type SetupOptions = {
    goExit_skip_content?: boolean;
}

type PrintFreeOptions = {
    printLogo: boolean
    printCopyHeader: boolean
    header: string[]
    barcode: {
        type: 'CODE39' | 'EAN13' | 'QRCODE'
        value: string
    }
};

type NonFiscalSendResult = 'PRINTED' | 'EMPTY_ORDER' | 'CONNECTION_ERROR' | 'INVALID_DRIVER';

type SaleWithOptions = Sales & {
    options?: PrintNonFiscalSaleOptions,
    print_type: 'sale_nonfiscal'
};

type VariationSaleItemWithPrice = VariationSaleItem & {
    final_price?: number,
    category_id?: number,
    category_name?: string
    printers?: Record<number, boolean>
}

type VariationSaleWithPrices = Omit<VariationSale, 'variation_items'> & {
    variation_items: VariationSaleItemWithPrice[],
    final_amount?: number
}

type PrintInfo = {
    print_type: 'order' | 'sale'
    printed_at?: string
    goExit?: boolean
    reprint?: boolean
    reprint_info?: {
        reprint_source: string
        reprint_new: string
    }
}

export type TableVariation = {
    print_type: 'table_variation'
    previous: Pick<Sales, 'table_id' | 'room_id' | 'table_name' | 'room_name'>
    current: Pick<Sales, 'table_id' | 'room_id' | 'table_name' | 'room_name'>
}

type OrderItemWithPrinters = OrderItems & {
    printers?: Record<number, boolean>
}

type PrintableVariation = VariationSaleWithPrices & PrintInfo;
type PrintableOrder = Omit<Orders, 'order_items'> & {
    order_items: OrderItemWithPrinters[],
    final_amount?: number
} & PrintInfo;

type PrintableOrderOrVariation = PrintableVariation | PrintableOrder;

type OtherPrinterConfig = {
    id: number,
    inline: boolean
}

type PrintItemGroupConfig = {
    bold?: boolean,
    itemFontSize: number,
    variationFontSize: number,
    padding: number
}

type PrinterWithConfig = Omit<Printers, 'configuration'> & {
    configuration: {
        add_prices_to_order?: false
        codepage_mapping?: string
        footer_printer_categories?: number
        footer_order_number?: number
        footer_operator_name?: number
        footer_print_type?: number
        footer_date?: number
        footer_time?: number
        footer_room_name?: number
        footer_table_name?: number
        footer_covers?: number
        footer_delivery_info?: number
        footer_order_name?: number
        footer_customer_info?: number
        footer_order_notes?: number
        footer_total_pieces?: number
        footer_final_amount?: number
        header_printer_categories?: number
        header_order_number?: number
        header_operator_name?: number
        header_print_type?: number
        header_date?: number
        header_time?: number
        header_room_name?: number
        header_table_name?: number
        header_covers?: number
        header_delivery_info?: number
        header_order_name?: number
        header_customer_info?: number
        header_order_notes?: number
        header_total_pieces?: number
        header_final_amount?: number
        item_font_size?: number
        item_spacing?: number
        item_hide_border?: boolean
        item_hide_brackets?: boolean
        items_align?: string
        items_group_by?: 'exit' | 'category_id' | 'exit_and_category'
        line_spacing?: number
        split_orders_by_exit?: boolean
        variation_font_size?: number
        progressive_prefix?: string
        other_printers?: OtherPrinterConfig[]
        other_printers_font_size?: number,
        cashregisters?: number[]
        rooms?: (number | 'no_rooms' | 'take_away' | 'delivery')[]
    }
}

type PrintNonFiscalSaleOptions = {
    header?: string[]
    tail?: string
    tailQRCode?: string
    tailQRCodeSize?: number
}

export class NonFiscalDriver {
    constructor(
        private readonly $rootScope: any,
        private readonly $translate: TranslateService,
        private readonly $http: any,
        private readonly $filter: any,
        private readonly errorsLogger: any,
        private readonly DocumentBuilder: DocumentBuilderService,
        private readonly EscPosDriver: NFThermalPrinterConstructor,
        private readonly EPosDriver: NFThermalPrinterConstructor,
        private readonly fiscalUtils: any,
        private readonly orderUtils: any,
        private readonly util: any,
        private readonly entityManager: EntityManagerService,
        private readonly checkManager: ConfigurationManagerService,
        private readonly KDSManager: any,
    ) {
    }

    private nonFiscalLabels: Record<string, string> = {};

    private printers: PrinterWithConfig[] = [];

    private configuration = {
        columns: 48,
        top_space: 0,
        bottom_space: 3,
        goExit_skip_content: false
    };

    private newLine(times = 1) {
        return '\n\r'.repeat(times);
    }

    private border(length?: number, char: string = '=') {
        length = Math.floor((length || this.configuration.columns) / 2);

        return char.repeat(length) + this.newLine();
    }

    private setSize(drvObj: NFThermalPrinter, size: number = 0) {
        drvObj.addTextDouble((size & 16) ? true : false, (size & 1) ? true : false);
    }

    private validateIPaddress(ipaddress: string) {
        return (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ipaddress));
    }

    private intToIP(int: number) {
        return [((int >> 24) & 255), ((int >> 16) & 255), ((int >> 8) & 255), (int & 255)].join('.');
    }

    private logEvent(...args: any) {
        this.errorsLogger.debug('[ NonFiscalDriver ]', ...args);
    }

    private getDriverInfo(printer: Pick<PrinterWithConfig, 'driver' | 'configuration' | 'connection_type'>): DriverInfo | undefined {
        let printerDriver: NFThermalPrinterConstructor | undefined;
        let port: number | undefined;
        let config: DriverInfo['configuration'] = {};

        switch (printer.driver) {
            case 'escpos':
                printerDriver = this.EscPosDriver;
                port = 9100;
                config = {
                    codepage_mapping: printer.configuration.codepage_mapping,
                    connection_type: printer.connection_type,
                    line_spacing: printer.configuration.line_spacing
                };
                break;
            case 'epos':
                printerDriver = this.EPosDriver;
                port = 80;
                config = {
                    line_spacing: printer.configuration.line_spacing
                };
                break;
        }

        return printerDriver ? { driverObj: printerDriver, port: port!, configuration: config } : undefined;
    }

    private setAlign(drvObj: NFThermalPrinter, align: string) {
        switch (align) {
            case 'center':
                drvObj.addTextAlign(drvObj.ALIGN_CENTER);
                break;
            case 'left':
                drvObj.addTextAlign(drvObj.ALIGN_LEFT);
                break;
            case 'right':
                drvObj.addTextAlign(drvObj.ALIGN_RIGHT);
                break;
            default:
                drvObj.addTextAlign(drvObj.ALIGN_CENTER);
        }
    }

    private setReversed(drvObj: NFThermalPrinter, mode: boolean) {
        drvObj.addTextStyle(mode, undefined, undefined, undefined);
    }

    private setUnderline(drvObj: NFThermalPrinter, mode: boolean) {
        drvObj.addTextStyle(undefined, mode, undefined, undefined);
    }

    private setBold(drvObj: NFThermalPrinter, mode: boolean) {
        drvObj.addTextStyle(undefined, undefined, mode, undefined);
    }

    private calculateDifferences(oldOrder: Orders, newOrder: Orders) {
        const oldOrderItems = this.orderUtils.getCleanOrderItems(oldOrder) as OrderItems[];
        const newOrderItems = this.orderUtils.getCleanOrderItems(newOrder) as OrderItems[];

        // Get oldOrder and newOorder order_items uuids
        const oldOrderOrderItemsIds = oldOrderItems.map((orderItem) => orderItem.uuid);
        const newOrderOrderItemsIds = newOrderItems.map((orderItem) => orderItem.uuid);

        // Additions
        var additionsIds = _.difference(newOrderOrderItemsIds, oldOrderOrderItemsIds);

        // Removals
        var removalsIds = _.difference(oldOrderOrderItemsIds, newOrderOrderItemsIds);

        // Intersection
        var intersectionIds = _.intersection(oldOrderOrderItemsIds, newOrderOrderItemsIds);

        const variationOrder: VariationSale = {
            type: newOrder.type,
            deliver_at: newOrder.deliver_at,
            open_at: newOrder.open_at,
            name: newOrder.name,
            order_number: newOrder.order_number,
            room_id: newOrder.room_id,
            room_name: newOrder.room_name,
            table_name: newOrder.table_name,
            operator_name: newOrder.operator_name,
            covers: newOrder.covers,
            order_customer: newOrder.order_customer ? { ...newOrder.order_customer } : undefined,
            notes: newOrder.notes,
            variation_items: []
        };

        const oldOrderItemsMap = keyBy(oldOrderItems, (orderItem) => orderItem.uuid);
        const newOrderItemsMap = keyBy(newOrderItems, (orderItem) => orderItem.uuid);

        // Additions orderItems
        for (const additionId of additionsIds) {
            const addedOrderItem = newOrderItemsMap[additionId];

            if (addedOrderItem) {
                variationOrder.variation_items!.push({
                    ...addedOrderItem,
                    type: 'addition'
                });
            }
        }

        // Removals orderItems
        for (const removalId of removalsIds) {
            const removedOrderItem = oldOrderItemsMap[removalId];

            if (removedOrderItem) {
                variationOrder.variation_items!.push({
                    ...removedOrderItem,
                    type: 'removal'
                })
            }
        }

        /*
            Intersection analysis:
                - check for quantity variations
                - check for property variations (contents & presence)
        */
        for (const intersectionId of intersectionIds) {
            const oldOrderItem = oldOrderItemsMap[intersectionId];
            const newOrderItem = newOrderItemsMap[intersectionId];
            const diffOrderItem = structuredClone(newOrderItem);

            if (oldOrderItem && newOrderItem && !_.isEqual(oldOrderItem, newOrderItem)) {
                // Finalize and push
                variationOrder.variation_items!.push({
                    ...diffOrderItem,
                    type: 'edit',
                    quantity_difference: (newOrderItem.quantity - oldOrderItem.quantity) || 0,
                    exit_difference: newOrderItem.exit !== oldOrderItem.exit,
                    half_portion_difference: newOrderItem.half_portion !== oldOrderItem.half_portion,
                    notes_difference: newOrderItem.notes !== oldOrderItem.notes,
                    variations_differences: !_.isEqual(oldOrderItem.variations, newOrderItem.variations),
                    ingredients_differences: !_.isEqual(oldOrderItem.ingredients, newOrderItem.ingredients)
                });
            }
        }

        return variationOrder;
    };

    private groupItemsByGroupByPreference = (itemsGroupBy: PrinterWithConfig['configuration']['items_group_by']) => (item: OrderItemWithPrinters | VariationSaleItemWithPrice) => {
        switch (itemsGroupBy) {
            case 'category_id':
                return item.category_id;
            case 'exit':
            case 'exit_and_category':
            default:
                return item.exit;
        }
    }

    private groupItemsByPrinterOwnership<T extends OrderItemWithPrinters | VariationSaleItemWithPrice>(items: T[], printer: PrinterWithConfig): { own?: T[], other_inline?: T[], other?: T[], discard?: T[] } {
        return groupBy(items, (item) => {
            if (item.printers?.[printer.id!]) {
                return 'own';
            }

            const otherPrinter = (printer.configuration.other_printers || []).find(p => !!item.printers?.[p.id!]);

            if (otherPrinter) {
                return otherPrinter.inline ? 'other_inline' : 'other';
            }

            return 'discard';
        });
    };

    private isPrintersRoomOrder(printer: PrinterWithConfig, order: PrintableOrderOrVariation) {
        const allowedRooms = new Set(printer.configuration.rooms);

        // if no rooms are allowed, allow all
        if (!allowedRooms.size) {
            return true;
        }

        if (order.room_id) {
            if (allowedRooms.has(order.room_id)) {
                return true;
            }
        } else {
            if ((order.type === 'normal' || !order.type) && allowedRooms.has('no_rooms')) {
                return true;
            }
            if (order.type === 'take_away' && allowedRooms.has('take_away')) {
                return true;
            }
            if (order.type === 'delivery' && allowedRooms.has('delivery')) {
                return true;
            }
        }

        return false;
    }

    private buildShopHeader(drvObj: NFThermalPrinter, header: string[]) {
        const addLine = (line: string) => drvObj.addText(line + this.newLine());

        for (const [idx, line] of header.entries()) {
            this.setAlign(drvObj, 'center');
            drvObj.addTextDouble(false, (!idx));

            addLine(line);
        }

        addLine("");
    };

    private settingsToCheck = [
        'order_number',
        'operator_name',
        'print_type',
        'date',
        'time',
        'room_name',
        'table_name',
        'covers',
        'delivery_info',
        'order_name',
        'customer_info',
        'order_notes',
        'order_categories',
        'total_pieces',
        'final_amount'
    ];

    private getLayoutConfig(printer: PrinterWithConfig) {
        return {
            itemFontSize: printer.configuration.item_font_size ?? 17,
            itemSpacing: printer.configuration.item_spacing ?? 1,
            itemHideBorder: printer.configuration.item_hide_border ?? false,
            itemHideBrackets: printer.configuration.item_hide_brackets ?? false,
            itemsGroupBy: printer.configuration.items_group_by ?? 'exit',
            splitOrders: printer.configuration.split_orders_by_exit ?? false,
            addPrices: printer.configuration.add_prices_to_order ?? false,
            variationFontSize: printer.configuration.variation_font_size ?? 16,
            otherPrintersFontSize: printer.configuration.other_printers_font_size ?? 0,
            itemsAlign: printer.configuration.items_align ?? 'center',
            topSpace: this.newLine().repeat(printer.top_space || this.configuration.top_space),
            bottomSpace: this.newLine().repeat(printer.bottom_space || this.configuration.bottom_space),
        };
    }

    private getHeaderFooterSettings(printer: PrinterWithConfig, settingsType: 'header' | 'footer') {
        const settings: any = {
            columns: printer.columns,
            settings_type: settingsType
        };

        const printerConfig = printer.configuration;

        //Initialize settings
        switch (settingsType) {
            case 'header':
                for (const settingName of this.settingsToCheck) {
                    const finalName = `header_${settingName}` as keyof PrinterWithConfig['configuration'];

                    if (['total_pieces', 'final_amount'].includes(settingName)) {
                        settings[settingName] = printerConfig[finalName] != null ? printerConfig[finalName] : -1;
                    } else {
                        settings[settingName] = printerConfig[finalName] != null ? printerConfig[finalName] : ['header_order_name', 'header_order_notes'].includes(finalName) ? 0 : 16;
                    }
                }
                break;
            case 'footer':
                for (const settingName of this.settingsToCheck) {
                    const finalName = `footer_${settingName}` as keyof PrinterWithConfig['configuration'];

                    settings[settingName] = printerConfig[finalName] != null ? printerConfig[finalName] : -1;
                }
                break;
            default: break;
        }

        return settings;
    };

    private buildOrderHeader(drvObj: NFThermalPrinter, printer: PrinterWithConfig, order: PrintableOrderOrVariation, type: 'header' | 'footer') {
        const printerSettings = this.getHeaderFooterSettings(printer, type);
        const printDate = moment(order.printed_at);

        type HeaderRow = {
            text: string;
            size?: number;
            align?: string;
            bold?: boolean;
            reversed?: boolean;
        };

        const headerData: HeaderRow[] = [];

        if (printerSettings.order_categories !== -1) {
            headerData.push({
                text: (printer.categories || []).map(c => c.name).join(', ') + this.newLine(2),
                size: printerSettings.order_categories,
                align: 'center',
                bold: true
            });
        }

        if (printerSettings.order_number !== -1) {
            const orderNumberString = order.order_number ? `#${order.order_number}` : '';

            if (orderNumberString) {
                headerData.push({
                    text: orderNumberString,
                    size: printerSettings.order_number
                });
            }
        }

        if (printerSettings.operator_name !== -1) {
            if (order.operator_name) {
                let opName = order.operator_name;

                if (printerSettings.operator_name > 0) {
                    opName += this.newLine();
                }

                headerData.push({
                    text: opName,
                    size: printerSettings.operator_name
                });
            }
        }

        if (printerSettings.date !== -1) {
            let headerDate = printDate.format('L');

            if (printerSettings.operator_name === 0) {
                headerDate += this.newLine();
            }

            headerData.push({ text: headerDate, size: printerSettings.date });
        }

        if (printerSettings.time !== -1) {
            headerData.push({ text: printDate.format('LT'), size: printerSettings.time });
        }

        for (const row of headerData) {
            if (!row.text.endsWith(this.newLine()) && row !== headerData[headerData.length - 1]) {
                row.text += ' - ';
            }
        }

        if (headerData.length) {
            headerData.push({
                text: this.newLine(2)
            });
        }

        if (printerSettings.print_type !== -1) {
            if ('variation_items' in order) { // If order variation print
                headerData.push({
                    text: ` ${this.nonFiscalLabels.variation} ${this.newLine(2)}`,
                    size: printerSettings.print_type,
                    bold: true,
                    reversed: true
                });
            } else if ('reprint' in order && order.reprint) { // if reprint
                headerData.push({
                    text: ` ${this.nonFiscalLabels.reprint_order} ${this.newLine(2)}`,
                    size: printerSettings.print_type,
                    bold: true,
                    reversed: true
                });
            }
        }

        switch (order.type) {
            case 'normal':
                if (order.table_name && printerSettings.room_name !== -1) {
                    headerData.push({
                        text: order.room_name + this.newLine(),
                        size: printerSettings.room_name
                    });
                }

                if (order.table_name && printerSettings.table_name !== -1) {
                    headerData.push({
                        text: order.table_name + this.newLine(),
                        size: printerSettings.table_name
                    });
                }

                if (order.covers && printerSettings.covers !== -1) {
                    headerData.push({
                        text: `${order.covers} ${this.nonFiscalLabels.covers}` + this.newLine(),
                        size: printerSettings.covers
                    });
                }
                break;
            case 'take_away':
            case 'delivery':
                if (printerSettings.delivery_info !== -1) {
                    let deliveryInfo = this.nonFiscalLabels[order.type] + this.newLine();

                    if ('channel' in order) {
                        if ((order.channel && order.channel !== 'pos') || order.external_id) {
                            deliveryInfo += (order.channel ? order.channel + ' ' : '') + (order.external_id ? `#${order.external_id}` : '') + this.newLine();
                        }
                    }

                    const deliverAt = moment(order.deliver_at);

                    if (deliverAt.dayOfYear() === printDate.dayOfYear() && deliverAt.year() === printDate.year()) { //Same day
                        deliveryInfo += `${this.nonFiscalLabels.deliver_at} ${deliverAt.format('LT')}` + this.newLine(2);
                    } else { //Different day
                        deliveryInfo += `${this.nonFiscalLabels.deliver_at_date} ${deliverAt.format('L LT')}` + this.newLine(2);
                    }

                    headerData.push({
                        text: deliveryInfo,
                        size: printerSettings.delivery_info
                    });
                }
                break;
            default:
                break;
        }

        for (const row of headerData) {
            Object.assign(row, {
                bold: true,
                align: 'center'
            });
        }

        if (printerSettings.order_name !== -1) {
            headerData.push({
                text: order.name + this.newLine(2),
                size: printerSettings.order_name,
                align: 'center'
            });
        }

        const orderCustomer = order.order_customer;
        const customerData: HeaderRow[] = [];

        // Add customer (if exists)
        if (orderCustomer && printerSettings.customer_info !== -1) {
            customerData.push({
                text: (order.type === 'delivery' ? this.nonFiscalLabels.customer_delivery! : this.nonFiscalLabels.customer!)
            }, {
                text: ` ${this.util.getCustomerCaption(orderCustomer)}`,
                bold: true
            });


            if (orderCustomer.fidelity) {
                customerData.push({
                    text: `  ${this.nonFiscalLabels.fidelity} ${orderCustomer.fidelity}`
                });
            }

            if (orderCustomer.shipping_street) {
                customerData.push({
                    text: `  ${orderCustomer.shipping_street} ${orderCustomer.shipping_number || ''}`
                });

                customerData.push({
                    text: `  ${orderCustomer.shipping_zip ? `${orderCustomer.shipping_zip} ` : ''}${orderCustomer.shipping_city ? `${orderCustomer.shipping_city} ` : ''}${orderCustomer.shipping_prov ? `(${orderCustomer.shipping_prov})` : ''}`
                });

            }

            if (orderCustomer.phone) {
                customerData.push({
                    text: `  ${this.nonFiscalLabels.phone} ${orderCustomer.phone}`
                });
            }

            if (orderCustomer.mobile) {
                customerData.push({
                    text: `  ${this.nonFiscalLabels.mobile} ${orderCustomer.mobile}`
                });
            }

            if (orderCustomer.notes) {
                customerData.push({
                    text: `  ${this.nonFiscalLabels.customer_notes} ${orderCustomer.notes.toUpperCase()}`
                });
            }

            for (const row of customerData) {
                Object.assign(row, {
                    text: row.text + this.newLine(),
                    size: printerSettings.customer_info
                });
            }

            headerData.push(...customerData);
        }

        if (order.notes && printerSettings.order_notes !== -1) {
            headerData.push({
                text: this.border(printerSettings.columns, '='),
                size: 16
            }, {
                text: `${this.nonFiscalLabels.order_notes} ${order.notes}`,
                size: printerSettings.order_notes
            });
        }

        if (printerSettings.total_pieces !== -1) {
            let totalQuantity = 0;

            if ('variation_items' in order) {
                // Calculate total quantity considering only additions
                totalQuantity = order.variation_items!.filter((item) => item.quantity_difference! >= 0).reduce((total, item) => total + (item.quantity_difference || item.quantity || 0), 0);
            } else if ('order_items' in order) {
                // Calculate total quantity considering the sum of the quantities of the whole order
                totalQuantity = order.order_items!.reduce((total, item) => total + (item.quantity || 0), 0);
            }

            headerData.push({
                text: this.border(printerSettings.columns, '='),
                size: 16
            }, {
                text: `${this.nonFiscalLabels.total_pieces} ${totalQuantity}` + this.newLine(),
                size: printerSettings.total_pieces
            });
        }

        if (printerSettings.final_amount !== -1) {
            // Add separator if we didn't add it before in total_pieces
            if (printerSettings.total_pieces === -1) {
                headerData.push({
                    text: this.border(printerSettings.columns, '='),
                    size: 16
                });
            }

            headerData.push({
                text: `${this.nonFiscalLabels.final_amount} ${this.$filter('sclCurrency')(order.final_amount || 0)}` + this.newLine(),
                size: printerSettings.final_amount
            });
        }

        if (headerData.length) {
            headerData.unshift({
                text: this.border(printerSettings.columns, '='),
                size: 16
            });
        }

        headerData.push({
            text: this.border(printerSettings.columns, '='),
            size: 16
        });

        if (type === 'footer' && order.reprint_info) {
            headerData.push({
                text: this.newLine(),
                size: 16
            }, {
                text: `${this.nonFiscalLabels.reprinted_at} ${moment().format('HH:mm DD/MM')}${this.newLine()}`,
                size: 16
            }, {
                text: `${this.nonFiscalLabels.reprint_source} ${order.reprint_info.reprint_source}${this.newLine()}`,
                size: 16
            }, {
                text: `${this.nonFiscalLabels.reprint_new} ${order.reprint_info.reprint_new}${this.newLine()}`,
                size: 16
            }
            );
        }

        for (const row of headerData) {
            this.setSize(drvObj, row.size);
            this.setBold(drvObj, row.bold || false);
            this.setAlign(drvObj, row.align || 'left');
            this.setReversed(drvObj, row.reversed || false);

            drvObj.addText(row.text);
        }
    };
    private printItemVariationGroup(drvObj: NFThermalPrinter, itemGroup: VariationSaleItemWithPrice[], printer: PrinterWithConfig, config: PrintItemGroupConfig) {
        const {
            itemSpacing,
            itemHideBorder,
            itemHideBrackets,
            addPrices,
            itemsAlign,
        } = this.getLayoutConfig(printer);

        const {
            bold,
            itemFontSize,
            variationFontSize,
            padding,
        } = config;

        const paddingText = ' '.repeat(padding);

        const addItemRow = (item: VariationSaleItemWithPrice, showSign: boolean) => {
            this.setAlign(drvObj, itemsAlign);
            this.setSize(drvObj, itemFontSize);

            const quantityBrackets = itemHideBrackets ? '' : '[';
            const quantitySign = (item.quantity_difference! > 0 && showSign) ? "+" : "";
            const quantity = item.quantity_difference || item.quantity;
            const quantityBracketsEnd = itemHideBrackets ? '' : ']';
            const halfPortion = item.half_portion ? "1/2 " : "";
            const name = (item.order_name || item.name || '').toUpperCase();
            const priceTag = addPrices ? ` ${this.$filter('sclCurrency')(item.final_price)}` : '';

            drvObj.addText(paddingText + `${quantityBrackets}${quantitySign}${quantity}${quantityBracketsEnd} ${halfPortion}${name}${priceTag}` + this.newLine());

            // INGREDIENTS (ADDING/REMOVAL)
            const addedOrRemoved = groupBy(item.ingredients || [], i => i.type);
            const underLineIngredients = item.ingredients_differences ? true : false;
            const underlineVariations = item.variations_differences ? true : false;
            const underlineNotes = item.notes_difference ? true : false;

            for (const [addremType, ingredients] of Object.entries(addedOrRemoved)) {
                this.setAlign(drvObj, 'left');
                this.setSize(drvObj, variationFontSize);
                this.setUnderline(drvObj, underLineIngredients);

                const typeLabel = this.nonFiscalLabels[addremType] || "";

                for (const ingredient of ingredients) {
                    switch (addremType) {
                        case 'added':
                            drvObj.addText(paddingText + `${typeLabel} ${ingredient.quantity !== 1 ? (ingredient.quantity + "x ") : ""}${ingredient.name}` + this.newLine());
                            break;
                        case 'removed':
                            drvObj.addText(paddingText + `${typeLabel} ${ingredient.name}` + this.newLine());
                            break;
                    }
                }

                this.setUnderline(drvObj, false);
            }

            // VARIATIONS
            if (!Array.isArray(item.variations)) {
                item.variations = [];
            }

            for (const variation of item.variations) {
                this.setAlign(drvObj, 'left');
                this.setSize(drvObj, variationFontSize);

                drvObj.addText(paddingText + `${variation.name}: `);
                this.setUnderline(drvObj, underlineVariations);
                drvObj.addText(variation.value);
                this.setUnderline(drvObj, false);
                drvObj.addText(this.newLine());
            }

            // NOTES
            if (item.notes) {
                this.setAlign(drvObj, 'left');
                this.setSize(drvObj, variationFontSize);

                drvObj.addText(paddingText + `${this.nonFiscalLabels.notes}: `);
                this.setUnderline(drvObj, underlineNotes);
                drvObj.addText(item.notes);
                this.setUnderline(drvObj, false);
                drvObj.addText(this.newLine());
            }

            this.setSize(drvObj, 16);

            if (!itemHideBorder) {
                this.setAlign(drvObj, 'left');
                drvObj.addText(this.border(printer.columns, '-'));
            }
            drvObj.addText(this.newLine().repeat(itemSpacing));
        };

        // PRODOTTI E VARIANTI
        const addTypeHeader = (header: string) => {
            this.setAlign(drvObj, 'center');
            this.setSize(drvObj, itemFontSize);
            this.setReversed(drvObj, true);
            drvObj.addText(` ${header} ` + this.newLine());
            this.setReversed(drvObj, false);
        };

        this.setBold(drvObj, !!bold);

        const itemsByType = groupBy(itemGroup, group => group.type);

        if (itemsByType.removal) {
            addTypeHeader(this.nonFiscalLabels.item_removal!);

            for (const item of itemsByType.removal) {
                addItemRow(item, false);
            }
        }

        if (itemsByType.addition) {
            addTypeHeader(this.nonFiscalLabels.item_addition!);

            for (const item of itemsByType.addition) {
                addItemRow(item, false);
            }
        }

        if (itemsByType.edit) {
            const editedItems = groupBy(itemsByType.edit, (item) => item.quantity_difference ? 'quantity' : 'other');

            if (editedItems.quantity) {
                addTypeHeader(this.nonFiscalLabels.item_editquantity!);

                for (const item of editedItems.quantity) {
                    addItemRow(item, true);
                }
            }

            if (editedItems.other) {
                addTypeHeader(this.nonFiscalLabels.item_edit!);

                for (const item of editedItems.other) {
                    addItemRow(item, false);
                }
            }
        }

        this.setBold(drvObj, false);
    }

    private async buildVariation(drvObj: NFThermalPrinter, variationOrder: PrintableVariation, printer: PrinterWithConfig): Promise<NonFiscalSendResult> {
        const isOwnRoomOrder = this.isPrintersRoomOrder(printer, variationOrder);

        if (!isOwnRoomOrder) {
            return 'EMPTY_ORDER';
        }

        // Filter order_items which have category_id contained in printersIds
        const variationsToPrint = this.groupItemsByPrinterOwnership(variationOrder.variation_items, printer);

        // Check if empty
        if (!variationsToPrint.own) {
            return 'EMPTY_ORDER';
        }

        const {
            itemFontSize,
            itemsGroupBy,
            splitOrders,
            addPrices,
            otherPrintersFontSize,
            topSpace,
            bottomSpace,
            variationFontSize
        } = this.getLayoutConfig(printer);

        // Compile order prices if necessary
        if (addPrices) {
            const refOrder = structuredClone({ order_items: variationOrder.variation_items || [] });

            for (const item of refOrder.order_items) {
                item.quantity = item.quantity_difference || item.quantity;
            }

            this.orderUtils.calculateOrderPrices(refOrder);

            const orderItemsByUuid = keyBy(refOrder.order_items, oi => oi.uuid);

            for (const item of variationOrder.variation_items || []) {
                item.final_price = orderItemsByUuid[item.uuid]?.final_price || 0;
            }
        }

        const groupedVariations = groupBy(variationsToPrint.own, this.groupItemsByGroupByPreference(itemsGroupBy));
        const otherInlineVariations = groupBy(variationsToPrint.other_inline || [], this.groupItemsByGroupByPreference(itemsGroupBy));

        const groups = Array.from(new Set([...Object.keys(groupedVariations), ...Object.keys(otherInlineVariations)])).sort();
        const lastGroupIndex = groups.length - 1;

        for (const [i, groupIndex] of groups.entries()) {
            const itemGroup = groupedVariations[groupIndex] || [];
            const otherInlineItemGroup = otherInlineVariations[groupIndex] || [];
            const hasOtherInlineItems = otherInlineItemGroup.length > 0;

            //Print header if it's the first group or the splitOrders is enabled
            if (i === 0 || splitOrders) {
                drvObj.addTextDouble(true, false);
                drvObj.addTextStyle(false, false, false, drvObj.COLOR_1);
                drvObj.addText(topSpace);

                this.buildOrderHeader(drvObj, printer, variationOrder, 'header');
            }

            if (groupIndex !== '0') {
                if (i > 0 && !splitOrders) {
                    this.setAlign(drvObj, 'left');
                    drvObj.addText(this.border(printer.columns, '='));
                }

                let groupTitle;

                switch (itemsGroupBy) {
                    case 'exit':
                    case 'exit_and_category':
                        groupTitle = `${this.nonFiscalLabels.exit} ${groupIndex}`;
                        break;
                    default:
                        break;
                }

                if (groupTitle) {
                    this.setAlign(drvObj, 'center');
                    this.setSize(drvObj, itemFontSize);
                    drvObj.addText(groupTitle + this.newLine());
                    this.setSize(drvObj, 16);
                    this.setAlign(drvObj, 'left');
                    drvObj.addText(this.border(printer.columns, '='));
                }
            } else {
                drvObj.addText(this.newLine());
            }

            if (itemsGroupBy === 'exit_and_category') {
                const ownGroups = groupBy(itemGroup, orderItem => orderItem.category_id);
                const otherInlineGroups = groupBy(otherInlineItemGroup, orderItem => orderItem.category_id);

                const subGroups = Array.from(new Set([...Object.keys(ownGroups), ...Object.keys(otherInlineGroups)])).sort();

                for (const subGroupIndex of subGroups) {
                    const subItemGroup = ownGroups[subGroupIndex] || [];
                    const otherInlineGroup = otherInlineGroups[subGroupIndex] || [];

                    //Take category name from first item
                    const category = subItemGroup[0]?.category_name;

                    if (category) {
                        drvObj.addText(category + this.newLine());
                    }

                    this.printItemVariationGroup(drvObj, subItemGroup, printer, { itemFontSize, variationFontSize, padding: 2, bold: hasOtherInlineItems });

                    if(hasOtherInlineItems) {
                        drvObj.addText(this.border(printer.columns, '-') + this.newLine());
                        this.printItemVariationGroup(drvObj, otherInlineGroup, printer, { itemFontSize: otherPrintersFontSize, variationFontSize: otherPrintersFontSize, padding: 2 });
                    }
                }
            } else {
                this.printItemVariationGroup(drvObj, itemGroup, printer, { itemFontSize, variationFontSize, padding: 0, bold: hasOtherInlineItems });

                if(hasOtherInlineItems) {
                    drvObj.addText(this.border(printer.columns, '-') + this.newLine());
                    this.printItemVariationGroup(drvObj, otherInlineItemGroup, printer, { itemFontSize: otherPrintersFontSize, variationFontSize: otherPrintersFontSize, padding: 0 });
                }
            }

            // Add bottom space
            drvObj.addText(bottomSpace);

            // Print other elements (printed only on the last group or when split_orders_by_exit is enabled)
            if ((i === lastGroupIndex || splitOrders) && variationsToPrint.other) {
                const addOtherItemRow = (item: VariationSaleItem, showSign: boolean) => {
                    drvObj.addText(`[${((item.quantity_difference! > 0 && showSign) ? "+" : "")}${item.quantity_difference}]${(item.half_portion ? "1/2 " : "")} ${(item.order_name || item.name || '').toUpperCase()}` + this.newLine());
                };

                this.setAlign(drvObj, 'right');
                this.setSize(drvObj, otherPrintersFontSize);

                const otherVariations = groupBy(variationsToPrint.other, this.groupItemsByGroupByPreference(itemsGroupBy));

                for (const [groupIndex, otherItemGroup] of Object.entries(otherVariations)) {
                    if (groupIndex != '0') {
                        switch (itemsGroupBy) {
                            case 'exit':
                                drvObj.addText(` - ${this.nonFiscalLabels.exit} ${groupIndex} - ` + this.newLine());
                                break;
                            default:
                                break;
                        }
                    }

                    // PRODOTTI E VARIANTI
                    const otherItemsByType = groupBy(otherItemGroup, item => item.type);

                    if (otherItemsByType.removal) {
                        drvObj.addText(` ${this.nonFiscalLabels.item_removal} ` + this.newLine());

                        for (const item of otherItemsByType.removal) {
                            addOtherItemRow(item, false);
                        }
                    }

                    if (otherItemsByType.addition) {
                        drvObj.addText(` ${this.nonFiscalLabels.item_addition} ` + this.newLine());

                        for (const item of otherItemsByType.addition) {
                            addOtherItemRow(item, false);
                        }
                    }

                    if (otherItemsByType.edit) {
                        const otherEdits = groupBy(otherItemsByType.edit, (item) => item.quantity_difference ? 'quantity' : 'other');

                        if (otherEdits.quantity) {
                            drvObj.addText(` ${this.nonFiscalLabels.item_editquantity} ` + this.newLine());

                            for (const item of otherEdits.quantity) {
                                addOtherItemRow(item, true);
                            }
                        }

                        if (otherEdits.other) {
                            drvObj.addText(` ${this.nonFiscalLabels.item_edit} ` + this.newLine());

                            for (const item of otherEdits.other) {
                                addOtherItemRow(item, true);
                            }
                        }
                    }
                }
            }

            this.setSize(drvObj, 16);

            // Print footer and cut if it's the last group or when split_orders_by_exit is enabled
            if (i === lastGroupIndex || splitOrders) {
                this.buildOrderHeader(drvObj, printer, variationOrder, 'footer');
                // Add bottom space
                drvObj.addText(bottomSpace);

                // Cut paper
                drvObj.addCut(drvObj.CUT_FEED);
            }
        }

        if (printer.enable_buzzer) {
            drvObj.addPulse();
        }

        return await new Promise((resolve) => drvObj.send(resolve));
    }

    private printItemGroup(drvObj: NFThermalPrinter, itemGroup: OrderItemWithPrinters[], printer: PrinterWithConfig, config: PrintItemGroupConfig) {
        const {
            itemSpacing,
            itemHideBorder,
            itemHideBrackets,
            itemsAlign,
            addPrices,
        } = this.getLayoutConfig(printer);

        const {
            itemFontSize,
            variationFontSize,
            padding,
            bold
        } = config;

        const paddingText = ' '.repeat(padding);

        this.setBold(drvObj, !!bold);

        // PRODOTTI E VARIANTI
        for (const item of itemGroup) {
            let quantity = itemHideBrackets ? `${item.quantity} ` : `[${item.quantity}] `;

            if (item.half_portion) {
                quantity = quantity + "1/2 ";
            }

            const priceTag = addPrices ? ` ${this.$filter('sclCurrency')(item.final_price)}` : '';

            this.setAlign(drvObj, itemsAlign);
            this.setSize(drvObj, itemFontSize);
            drvObj.addText(paddingText + quantity + (item.order_name || item.name || '').toUpperCase() + priceTag + this.newLine());

            for (let l = 0; l < itemSpacing; l++) {
                drvObj.addText(this.newLine());
            }

            // AGGIUNTE / RIMOZIONI
            const addedOrRemoved = groupBy(item.ingredients || [], i => i.type);

            for (const [addremType, ingredients] of Object.entries(addedOrRemoved)) {
                this.setAlign(drvObj, 'left');
                this.setSize(drvObj, variationFontSize);

                const typeLabel = this.nonFiscalLabels[addremType] || "";

                for (const ingredient of ingredients) {
                    switch (addremType) {
                        case 'added':
                            drvObj.addText(paddingText + `${typeLabel}${ingredient.quantity !== 1 ? (ingredient.quantity + "x") : ""} ${ingredient.name}` + this.newLine());
                            break;
                        case 'removed':
                            drvObj.addText(paddingText + `${typeLabel} ${ingredient.name}` + this.newLine());
                            break;
                    }
                }

                this.setUnderline(drvObj, false);
            }

            // VARIATIONS
            if (!Array.isArray(item.variations)) {
                item.variations = [];
            }

            for (const variation of item.variations) {
                this.setAlign(drvObj, 'left');
                this.setSize(drvObj, variationFontSize);
                drvObj.addText(paddingText + `${variation.name}: ${variation.value}` + this.newLine());
            }

            // NOTES
            if (item.notes) {
                this.setAlign(drvObj, 'left');
                this.setSize(drvObj, variationFontSize);
                drvObj.addText(paddingText + `${this.nonFiscalLabels.notes}: ${item.notes}` + this.newLine());
            }

            this.setSize(drvObj, 16);

            if (!itemHideBorder && item !== itemGroup[itemGroup.length - 1]) {
                this.setSize(drvObj, 16);
                this.setAlign(drvObj, 'left');
                drvObj.addText(this.border(printer.columns, '-'));
            }
        }

        this.setBold(drvObj, false);
    }

    private async buildTableVariation(drvObj: NFThermalPrinter, variation: TableVariation, order: PrintableOrder, printer: PrinterWithConfig): Promise<NonFiscalSendResult> {
        // Filter order_items which have category_id contained in printer.categories
        const itemsToPrint = this.groupItemsByPrinterOwnership(order.order_items || [], printer);

        // Check if empty
        if (!itemsToPrint.own) {
            return 'EMPTY_ORDER';
        }

        // Check if previous and current tables are the same, if so return EMPTY_ORDER
        if (variation.previous.table_id === variation.current.table_id) {
            return 'EMPTY_ORDER';
        }

        this.setAlign(drvObj, 'center');
        this.setSize(drvObj, 16);
        drvObj.addText(this.nonFiscalLabels.table_edit + this.newLine().repeat(2));

        this.setAlign(drvObj, 'left');
        this.setSize(drvObj, 0);

        drvObj.addText((variation.previous.table_id
            ? `${this.nonFiscalLabels.previous_table} ${variation.previous.room_name} - ${variation.previous.table_name}` + this.newLine()
            : `${this.nonFiscalLabels.previous_table} ${this.nonFiscalLabels.no_table}` + this.newLine()
        ));

        drvObj.addText((variation.current.table_id
            ? `${this.nonFiscalLabels.current_table} ${variation.current.room_name} - ${variation.current.table_name}` + this.newLine()
            : `${this.nonFiscalLabels.current_table} ${this.nonFiscalLabels.no_table}` + this.newLine()
        ));

        drvObj.addCut(drvObj.CUT_FEED);

        return await new Promise((resolve) => drvObj.send(resolve));
    }

    private async buildOrder(drvObj: NFThermalPrinter, order: PrintableOrder, printer: PrinterWithConfig): Promise<NonFiscalSendResult> {
        const isOwnRoomOrder = this.isPrintersRoomOrder(printer, order);
        const skipContent = (!!order.goExit && !!this.configuration.goExit_skip_content);

        if (!isOwnRoomOrder) {
            return 'EMPTY_ORDER';
        }

        const {
            itemsGroupBy,
            topSpace,
            bottomSpace,
            splitOrders,
            itemFontSize,
            addPrices,
            variationFontSize,
            otherPrintersFontSize,
        } = this.getLayoutConfig(printer);

        // Compile order prices if necessary
        if (addPrices) {
            this.orderUtils.calculateOrderPrices(order);
        }

        // Filter order_items which have category_id contained in printer.categories
        const itemsToPrint = this.groupItemsByPrinterOwnership(order.order_items || [], printer);

        // Check if empty
        if (!itemsToPrint.own) {
            return 'EMPTY_ORDER';
        }

        const groupedItems = groupBy(itemsToPrint.own, this.groupItemsByGroupByPreference(itemsGroupBy));
        const otherInlineGroupedItems = groupBy(itemsToPrint.other_inline || [], this.groupItemsByGroupByPreference(itemsGroupBy));

        const groups = Array.from(new Set([...Object.keys(groupedItems), ...Object.keys(otherInlineGroupedItems)])).sort();
        const lastGroupIndex = groups.length - 1;

        for (const [i, groupIndex] of groups.entries()) {
            const itemGroup = groupedItems[groupIndex] || [];
            const otherInlineGroup = otherInlineGroupedItems[groupIndex] || [];
            const hasOtherInlineItems = otherInlineGroup.length > 0;

            //Print header if it's the first group or the splitOrders is enabled
            if (i === 0 || splitOrders) {
                drvObj.addTextStyle(false, false, false, drvObj.COLOR_1);
                drvObj.addText(topSpace);

                this.buildOrderHeader(drvObj, printer, order, 'header');
            }

            if (order.goExit) { // Go exit
                this.setAlign(drvObj, 'center');
                this.setSize(drvObj, itemFontSize);

                switch (itemsGroupBy) {
                    case 'exit':
                        drvObj.addText(`${this.nonFiscalLabels.goExit} ${groupIndex}`);
                        break;
                    default:
                        if (i === 0) {
                            drvObj.addText(`${this.nonFiscalLabels.goExit}`);
                            drvObj.addText(this.newLine());
                        }
                        break;
                }

                drvObj.addText(this.newLine());
                this.setSize(drvObj, 16);
                this.setAlign(drvObj, 'left');
                drvObj.addText(this.border(printer.columns, '=') + this.newLine());
            } else { // Normal order send
                if (groupIndex !== '0') {
                    if (i > 0 && !splitOrders) { //Add upper borders
                        this.setAlign(drvObj, 'left');
                        drvObj.addText(this.border(printer.columns, '='));
                    }

                    let groupTitle;

                    switch (itemsGroupBy) {
                        case 'exit':
                        case 'exit_and_category':
                            groupTitle = `${this.nonFiscalLabels.exit} ${groupIndex}`;
                            break;
                        default:
                            break;
                    }

                    if (groupTitle) {
                        this.setAlign(drvObj, 'center');
                        this.setSize(drvObj, itemFontSize);
                        drvObj.addText(groupTitle + this.newLine());
                        this.setSize(drvObj, 16);
                        this.setAlign(drvObj, 'left');
                        drvObj.addText(this.border(printer.columns, '='));
                    }
                } else {
                    drvObj.addText(this.newLine());
                }
            }

            if (!skipContent) {
                if (itemsGroupBy === 'exit_and_category') {
                    const ownSubGroups = groupBy(itemGroup, orderItem => orderItem.category_id);
                    const inlineSubGroups = groupBy(otherInlineGroup, orderItem => orderItem.category_id);

                    const subGroups = Array.from(new Set([...Object.keys(ownSubGroups), ...Object.keys(inlineSubGroups)])).sort();

                    for (const subGroupIndex of subGroups) {
                        const subItemGroup = ownSubGroups[subGroupIndex] || [];
                        const otherInlineGroup = inlineSubGroups[subGroupIndex] || [];

                        //Take category name from first item
                        const category = subItemGroup[0]?.category_name;

                        if (category) {
                            drvObj.addText(category + this.newLine());
                        }

                        this.printItemGroup(drvObj, subItemGroup, printer, { itemFontSize, variationFontSize, padding: 2, bold: hasOtherInlineItems });

                        if(otherInlineGroup.length) {
                            drvObj.addText(this.border(printer.columns, '-') + this.newLine());
                            this.printItemGroup(drvObj, otherInlineGroup, printer, { itemFontSize: otherPrintersFontSize, variationFontSize: otherPrintersFontSize, padding: 2 });
                        }
                    }
                } else {
                    this.printItemGroup(drvObj, itemGroup, printer, { itemFontSize, variationFontSize, padding: 0, bold: hasOtherInlineItems });

                    if(hasOtherInlineItems) {
                        drvObj.addText(this.border(printer.columns, '-') + this.newLine());
                        this.printItemGroup(drvObj, otherInlineGroup, printer, { itemFontSize: otherPrintersFontSize, variationFontSize: otherPrintersFontSize, padding: 0 });
                    }
                }

                // Add bottom space
                drvObj.addText(bottomSpace);

                // Print other elements if there are (printed only on the last group or when split_orders_by_exit is enabled)
                if ((i === lastGroupIndex || splitOrders) && itemsToPrint.other) {
                    const otherItems = groupBy(itemsToPrint.other, this.groupItemsByGroupByPreference(itemsGroupBy));

                    for (const [groupIndex, otherItemGroup] of Object.entries(otherItems)) {
                        this.setAlign(drvObj, 'right');
                        this.setSize(drvObj, otherPrintersFontSize);

                        // USCITA
                        if (groupIndex != '0') {
                            switch (itemsGroupBy) {
                                case 'exit':
                                    drvObj.addText(` - ${this.nonFiscalLabels.exit} ${groupIndex} - ` + this.newLine());
                                    break;
                                default:
                                    break;
                            }
                        }

                        // PRODOTTI E VARIANTI
                        for (const item of otherItemGroup) {
                            this.setSize(drvObj, otherPrintersFontSize);
                            drvObj.addText(`${item.quantity} x ${item.half_portion ? '1/2 ' : ''}${(item.order_name || item.name || '').toUpperCase()}` + this.newLine());
                        }
                    }
                }
            }

            // Print footer and cut if it's the last group or when split_orders_by_exit is enabled
            if (i === lastGroupIndex || splitOrders) {
                this.buildOrderHeader(drvObj, printer, order, 'footer');
                drvObj.addCut(drvObj.CUT_FEED);
            }
        }

        if (printer.enable_buzzer) {
            drvObj.addPulse();
        }

        return await new Promise((resolve) => drvObj.send(resolve));
    }

    private async buildNonFiscalSale(drvObj: NFThermalPrinter, sale: SaleWithOptions, printer: PrinterWithConfig): Promise<NonFiscalSendResult> {
        const addLine = (line: string) => drvObj.addText(line + this.newLine());
        const numCols = printer.columns || 48;

        const amount = sale.amount || 0;
        const finalAmount = sale.final_amount || 0;

        const calculatePriceChanges = (pChanges: SalesPriceChanges[] | SalesItemsPriceChanges[], partial: number) => {
            for (const pChange of pChanges.sort((p1, p2) => p1.index - p2.index)) {
                const pcAmount = this.fiscalUtils.getPriceChangeAmount(pChange, partial);

                if (pcAmount == null) {
                    continue;
                }

                partial = MathUtils.round(partial + pcAmount);

                const amountStr = `${['surcharge_fix', 'surcharge_perc'].includes(pChange.type) ? "+" : ""}${pcAmount.toFixed(2).replace(".", ",")}`;
                const rowDescription = pChange.description;

                addLine(rowDescription.slice(0, numCols - amountStr.length - 2).padEnd(numCols - amountStr.length, " ") + amountStr);
            }
        };

        drvObj.addLogo();
        drvObj.addTextDouble(false, false);
        drvObj.addTextStyle(false, false, false, drvObj.COLOR_1);

        if (sale.options?.header) {
            this.buildShopHeader(drvObj, sale.options.header);
        }

        drvObj.addTextDouble(false, false);

        if (!this.checkManager.getPreference("cashregister.save_paper_on_prebill")) {
            this.setAlign(drvObj, 'center');

            this.$translate.instant('PRINTERS.NON_FISCAL_LABELS.NON_FISCAL_SALE_HEADER').split('\n').forEach(addLine);
        }

        this.setAlign(drvObj, 'left');

        for (const row of this.fiscalUtils.getFiscalReceiptHeaderLines(sale)) {
            addLine(row);
        }

        // Sale items
        for (const saleItem of this.fiscalUtils.extractSaleItems(sale)) {
            const rowDescription = `${saleItem.quantity}x ${saleItem.name || saleItem.department_name}`;
            const rowPrice = (saleItem.price * saleItem.quantity).toFixed(2).replace(".", ",");

            addLine(rowDescription.slice(0, numCols - rowPrice.length - 2).padEnd(numCols - rowPrice.length, " ") + rowPrice);

            if (saleItem.notes) {
                let notesLines = stringToLines(saleItem.notes, numCols);

                for (const nl of notesLines) {
                    if (nl.trim()) {
                        addLine(' '.repeat(5) + nl.trim());
                    }
                }
            }

            // Discount/Surcharges
            if (saleItem.price_changes) {
                calculatePriceChanges(saleItem.price_changes, MathUtils.round(saleItem.price * saleItem.quantity));
            }
        }

        // Sub-total and his discount/surcharges
        const subTotDescr = this.$translate.instant('PRINTERS.NON_FISCAL_LABELS.NON_FISCAL_SALE_SUBTOTAL');
        const subTotAmount = amount.toFixed(2).replace(".", ",");

        this.setBold(drvObj, true);
        addLine(subTotDescr.padEnd(numCols - subTotAmount.length, " ") + subTotAmount);
        this.setBold(drvObj, false);

        // Apply discount/surcharges on subtotal
        if (finalAmount >= 0) {
            const partialPrice = MathUtils.round(amount);
            calculatePriceChanges(sale.price_changes || [], partialPrice);
        }

        const totDescription = this.$translate.instant('PRINTERS.NON_FISCAL_LABELS.NON_FISCAL_SALE_FINAL_AMOUNT', { currency: this.$rootScope.currentCurrency.code });
        const totAmount = finalAmount.toFixed(2);

        drvObj.addTextDouble(false, true);
        addLine(totDescription.slice(0, numCols - totAmount.length - 2).padEnd(numCols - totAmount.length, " ") + totAmount);
        drvObj.addTextDouble(false, false);

        this.setAlign(drvObj, 'left');

        if (sale.options?.tail) {
            const tailLines = stringToLines(sale.options.tail);

            addLine("");

            for (const line of tailLines) {
                addLine(line);
            }

            addLine("");
        }

        this.setAlign(drvObj, 'center');

        if (!this.checkManager.getPreference('cashregister.save_paper_on_prebill')) {
            this.$translate.instant('PRINTERS.NON_FISCAL_LABELS.NON_FISCAL_SALE_FOOTER').split('\n').forEach(addLine);
        } else {
            addLine("");
        }

        if (this.checkManager.getPreference('fiscalprinter.print_sale_qr_code')) {
            drvObj.addSymbol(`s_uid=${sale.uuid}`, drvObj.SYMBOL_QRCODE_MODEL_2, drvObj.LEVEL_DEFAULT, 8);
        }

        const strookaKey = this.checkManager.getPreference('orders.strooka_shared_key');

        if (sale.table_id && strookaKey) {
            let strookaApiUrl = this.checkManager.getPreference('orders.strooka_api_url') || "https://api.strooka.com/plugin/scloby/tables/open/";

            if (!strookaApiUrl.endsWith("/")) {
                strookaApiUrl += "/";
            }

            try {
                const result = await this.$http({
                    url: `${strookaApiUrl}${sale.table_id}/`,
                    method: "POST",
                    headers: { 'Shared-Key': strookaKey },
                    data: { covers: sale.covers || 1, order_id: sale.id }
                });

                if (result.data?.url) {
                    var strookaUrl = _.unescape(result.data.url);
                    var strookaDomain = strookaUrl.match(/(^.*.)[\/][?]/)?.[1];

                    drvObj.addTextSize(2, 2);
                    let headerText = this.checkManager.getPreference('orders.strooka_header_text');

                    if (!headerText) {
                        if (drvObj instanceof this.EPosDriver) {
                            headerText = "Inquadra con il telefono il QRcode";
                        }
                    }

                    if (headerText) {
                        drvObj.addText(this.newLine(2) + headerText + this.newLine());
                    }

                    drvObj.addSymbol(strookaUrl, drvObj.SYMBOL_QRCODE_MODEL_2, drvObj.LEVEL_DEFAULT, 8);

                    const accessText = drvObj instanceof this.EPosDriver ? "In alternativa accedi su" : "Accedi su";
                    drvObj.addText(`${accessText}${this.newLine()}${strookaDomain}${this.newLine()}ed inserisci il codice${this.newLine()}`);
                    drvObj.addTextSize(3, 3);
                    drvObj.addText(`${result.data.pin}`);
                    drvObj.addTextSize(1, 1);
                }
            } catch (err) { }
        }

        drvObj.addCut(drvObj.CUT_FEED);

        return await new Promise((resolve) => drvObj.send(resolve));
    }

    private async printOrder(payload: PrintableOrderOrVariation | SaleWithOptions | TableVariation, originalPayload: Orders | VariationSale | Sales, printer: PrinterWithConfig): Promise<NonFiscalSendResult> {
        this.logEvent(`Printing order on printer: [${printer.name}]`);

        switch (printer.type) {
            case 'nonfiscal':
            case 'receipt':
                const driverInfo = this.getDriverInfo(printer);

                if (!driverInfo?.driverObj) {
                    return 'INVALID_DRIVER';
                }

                const printerDriver = new driverInfo.driverObj(printer.ip_address!, driverInfo.port, driverInfo.configuration);

                switch (payload.print_type) {
                    case 'order':
                    case 'sale':
                        return 'variation_items' in payload
                            ? this.buildVariation(printerDriver, payload, printer)
                            : this.buildOrder(printerDriver, payload, printer);
                    case 'sale_nonfiscal':
                        return this.buildNonFiscalSale(printerDriver, payload, printer);
                    case 'table_variation':
                        return this.buildTableVariation(printerDriver, payload, <PrintableOrder>originalPayload, printer);
                    default:
                        break;
                }
                break;
            case 'kds':
                if (['sale'].includes(payload.print_type)) {
                    return 'EMPTY_ORDER';
                }

                return this.KDSManager.sendOrders(printer, [originalPayload]).then(
                    () => 'PRINTED',
                    () => 'CONNECTION_ERROR'
                );
            default:
                break;
        }

        return 'CONNECTION_ERROR';
    }

    private async compileOrderInfo(order: PrintableOrderOrVariation, originalPayload: Orders | Sales, printers: PrinterWithConfig[]) {
        const isVariation = 'variation_items' in order;
        const orderItems = (isVariation ? order.variation_items : order.order_items) || [];
        const itemIds = Array.from(new Set(orderItems.map(item => item.item_id))).filter(id => id !== undefined);

        //Set final amount
        order.final_amount = 'final_amount' in originalPayload ? originalPayload.final_amount : originalPayload.amount;

        //Set up channel info if present
        if ('channel' in order && order.channel && order.channel !== 'pos') {
            try {
                const channel = await this.entityManager.channels.fetchOneOffline(order.channel);

                if (channel) {
                    order.channel = channel.name;
                }
            } catch (err) {
                this.errorsLogger.error("[NonFiscalDriver] Error while getting channel info for order");
            }
        }

        //Compile category name in items
        const categoriesById = await this.entityManager.categories.fetchCollectionOffline().then(categories => keyBy(categories, c => c.id));

        for (const orderItem of orderItems) {
            if (orderItem.category_id && categoriesById[orderItem.category_id]) {
                Object.assign(orderItem, {
                    category_name: categoriesById[orderItem.category_id]?.name
                });
            }
        }

        //Prepare category-to-printers map
        const categoryToPrinters: Record<number, Record<number, boolean>> = {};

        for (const printer of printers) {
            for (const category of printer.categories || []) {
                const catId = category.id!;

                if (!categoryToPrinters[catId]) {
                    categoryToPrinters[catId] = {};
                }

                categoryToPrinters[catId][printer.id!] = true;
            }
        }

        //Prepare item-to-printers map
        const itemToPrinters: Record<number, Record<number, boolean>> = {};

        for (const itemId of itemIds) {
            const item = await this.entityManager.items.fetchOneOffline(itemId!);

            if (item) {
                for (const printer of item.printers || []) {
                    const itemId = item.id!;

                    if (!itemToPrinters[itemId]) {
                        itemToPrinters[itemId] = {};
                    }

                    itemToPrinters[itemId][printer.id!] = true;
                }
            }
        }

        //Apply maps to order items
        for (const orderItem of orderItems) {
            if (!orderItem.exit) {
                orderItem.exit = 0;
            }

            const itemPrinters: Record<number, boolean> = {};

            if (orderItem.category_id) {
                Object.assign(itemPrinters, categoryToPrinters[orderItem.category_id]);
            }

            if (orderItem.item_id) {
                Object.assign(itemPrinters, itemToPrinters[orderItem.item_id]);
            }

            Object.assign(orderItem, { printers: itemPrinters });
        }
    }

    private async sendOrderToPrinters(order: PrintableOrderOrVariation | TableVariation, originalPayload: Orders | Sales) {
        const printers = this.printers;
        const printAttempts = parseInt(this.checkManager.getPreference('orders.print_attempts') || '1') || 1;

        const results: { printer: PrinterWithConfig, result: NonFiscalSendResult }[] = [];

        if (order.print_type === 'table_variation') {
            await this.compileOrderInfo(<PrintableOrder>originalPayload, originalPayload, printers);
        } else {
            await this.compileOrderInfo(order, originalPayload, printers);
        }

        //Send orders to 5 different printers in parallel, if there are printers with the same IP do them in series
        const printersByIP = groupBy(printers, p => p.ip_address);

        await asyncParallelLimit(Object.values(printersByIP), async (printerSet) => {
            for (const printer of printerSet) {
                let attempts = 0;
                let printResult;

                do {
                    attempts++;
                    printResult = await this.printOrder(order, originalPayload, printer);
                } while (!['PRINTED', 'EMPTY_ORDER'].includes(printResult) && attempts < printAttempts);

                results.push({
                    printer: printer,
                    result: printResult
                });

                if (attempts > 1) {
                    this.errorsLogger.sendReport({
                        type: 'printOrders',
                        content: `Printer [${printer.id}] [${printer.ip_address}] returned ${printResult} after ${(attempts + 1)} attempts`
                    });
                }
            }
        }, { parallelLimit: 5 });

        return results;
    }

    public getPrinterWithConfig(printer: Printers): PrinterWithConfig {
        try {
            const configuration = JSON.parse(printer.configuration || '{}');

            if (!Array.isArray(configuration.other_printers)) {
                configuration.other_printers = [];
            }

            // Backward compatibility of other_printers
            if (configuration.other_printers.length > 0 && typeof configuration.other_printers[0] === 'number') {
                configuration.other_printers = configuration.other_printers.map((p: number) => {
                    return {
                        id: p,
                        inline: false,
                    };
                });
            }

            if (!Array.isArray(configuration.cashregisters)) {
                configuration.cashregisters = [];
            }

            if (!Array.isArray(configuration.rooms)) {
                configuration.rooms = [];
            }

            return {
                ...printer,
                configuration,
            };
        } catch (err) {
            return {
                ...printer,
                configuration: {
                    other_printers: [],
                    cashregisters: [],
                    rooms: [],
                },
            };
        }
    }

    public setup(printers: Printers[], options?: SetupOptions) {
        this.nonFiscalLabels = {
            order: this.$translate.instant('PRINTERS.NON_FISCAL_LABELS.ORDER'),
            order_notes: this.$translate.instant('PRINTERS.NON_FISCAL_LABELS.ORDER_NOTES'),
            variation: this.$translate.instant('PRINTERS.NON_FISCAL_LABELS.VARIATION'),
            reprint_order: this.$translate.instant('PRINTERS.NON_FISCAL_LABELS.REPRINT_ORDER'),
            item_addition: this.$translate.instant('PRINTERS.NON_FISCAL_LABELS.ITEM_ADDITION'),
            item_removal: this.$translate.instant('PRINTERS.NON_FISCAL_LABELS.ITEM_REMOVAL'),
            item_edit: this.$translate.instant('PRINTERS.NON_FISCAL_LABELS.ITEM_EDIT'),
            item_editquantity: this.$translate.instant('PRINTERS.NON_FISCAL_LABELS.ITEM_EDITQUANTITY'),
            customer: this.$translate.instant('PRINTERS.NON_FISCAL_LABELS.CUSTOMER'),
            customer_delivery: this.$translate.instant('PRINTERS.NON_FISCAL_LABELS.CUSTOMER_DELIVERY'),
            fidelity: this.$translate.instant('PRINTERS.NON_FISCAL_LABELS.FIDELITY'),
            phone: this.$translate.instant('PRINTERS.NON_FISCAL_LABELS.PHONE'),
            mobile: this.$translate.instant('PRINTERS.NON_FISCAL_LABELS.MOBILE'),
            customer_notes: this.$translate.instant('PRINTERS.NON_FISCAL_LABELS.CUSTOMER_NOTES'),
            notes: this.$translate.instant('PRINTERS.NON_FISCAL_LABELS.NOTES'),
            total_pieces: this.$translate.instant('PRINTERS.NON_FISCAL_LABELS.TOTAL_PIECES'),
            final_amount: this.$translate.instant('PRINTERS.NON_FISCAL_LABELS.FINAL_AMOUNT'),
            covers: this.$translate.instant('PRINTERS.NON_FISCAL_LABELS.COVERS'),
            table: this.$translate.instant('PRINTERS.NON_FISCAL_LABELS.TABLE'),
            waiter: this.$translate.instant('PRINTERS.NON_FISCAL_LABELS.WAITER'),
            exit: this.$translate.instant('PRINTERS.NON_FISCAL_LABELS.EXIT'),
            added: this.$translate.instant('PRINTERS.NON_FISCAL_LABELS.ADDED'),
            removed: this.$translate.instant('PRINTERS.NON_FISCAL_LABELS.REMOVED'),
            of: this.$translate.instant('PRINTERS.NON_FISCAL_LABELS.OF'),
            take_away: this.$translate.instant('PRINTERS.NON_FISCAL_LABELS.TAKE_AWAY'),
            delivery: this.$translate.instant('PRINTERS.NON_FISCAL_LABELS.DELIVERY'),
            deliver_at: this.$translate.instant('PRINTERS.NON_FISCAL_LABELS.DELIVER_AT'),
            deliver_at_date: this.$translate.instant('PRINTERS.NON_FISCAL_LABELS.DELIVER_AT_DATE'),
            goExit: this.$translate.instant('PRINTERS.NON_FISCAL_LABELS.GO_EXIT'),
            reprinted_at: this.$translate.instant('PRINTERS.NON_FISCAL_LABELS.REPRINTED_AT'),
            reprint_source: this.$translate.instant('PRINTERS.NON_FISCAL_LABELS.REPRINT_SOURCE'),
            reprint_new: this.$translate.instant('PRINTERS.NON_FISCAL_LABELS.REPRINT_NEW'),
            table_edit: this.$translate.instant('PRINTERS.NON_FISCAL_LABELS.TABLE_EDIT'),
            previous_table: this.$translate.instant('PRINTERS.NON_FISCAL_LABELS.PREVIOUS_TABLE'),
            current_table: this.$translate.instant('PRINTERS.NON_FISCAL_LABELS.CURRENT_TABLE'),
            no_table: this.$translate.instant('PRINTERS.NON_FISCAL_LABELS.NO_TABLE'),
        }

        this.printers = structuredClone(printers).map(printer => this.getPrinterWithConfig(printer));

        this.configuration.goExit_skip_content = !!(options?.goExit_skip_content);

        return structuredClone(this.printers);
    }

    public async discoverPrinters(driver: string) {
        const printersInt: number[] = [];
        const driverInfo = this.getDriverInfo({ driver: driver, configuration: {} });

        if (!driverInfo) {
            return [];
        }

        const adapters = await new Promise((resolve) => window.chrome.socket.getNetworkList(resolve)) as { name: string, address: string, prefixLength: number }[]

        for (const adapter of adapters) {
            if (!this.validateIPaddress(adapter.address)) {
                continue;
            }

            this.logEvent(adapter);
            const networkInfo = IpSubnetCalculator.calculateSubnetMask(adapter.address, adapter.prefixLength);

            if (!networkInfo) {
                continue;
            }

            const ipLow = networkInfo.ipLow;
            const ipHigh = networkInfo.ipHigh;

            this.logEvent(`Start scanning adapter ${adapter.name} (${this.intToIP(ipLow)} - ${this.intToIP(ipHigh)})...`);

            var ipToScan = _.range(ipLow + 1, ipHigh - 1);

            await asyncParallelLimit(ipToScan, async (ip) => {
                const result = await new Promise((resolve) => driverInfo.driverObj.connectAttempt(this.intToIP(ip), driverInfo.port, resolve)) as { connected: boolean, ip: string };

                if (result.connected) {
                    printersInt.push(ip);
                }
            }, {
                parallelLimit: 32
            });
        }

        const printers = printersInt.sort().map(this.intToIP);

        this.logEvent(`Printers found: ${JSON.stringify(printers)}`);

        return printers;
    }

    public smartPrintOrder(order: Orders, reprint: boolean) {
        const localOrder = structuredClone(order) as PrintableOrder;
        localOrder.reprint = reprint;
        localOrder.print_type = 'order';

        return this.sendOrderToPrinters(localOrder, order);
    }

    private saleToOrder(sale: Sales): Orders {
        const {
            sale_items,
            sale_customer,
            order_type,
            sale_number,
            seller_id,
            seller_name,
            status,
            ...saleDetails
        } = sale;

        return {
            ...saleDetails,
            order_items: <OrderItems[]>(sale_items || []).map((saleItem) => ({
                ...structuredClone(saleItem),
                operator_id: saleItem.seller_id,
                operator_name: saleItem.seller_name,
                net_price: 0
            })),
            order_customer: sale_customer ? { ...sale_customer } : undefined,
            operator_id: seller_id,
            operator_name: seller_name,
            order_number: sale_number,
            type: order_type!,
            status: status as Orders.StatusEnum,
        };
    }

    public smartPrintSale(sale: Sales, reprint: boolean) {
        const { order_items, ...order } = this.saleToOrder(sale);

        const orderFromSale: PrintableOrder = {
            ...order,
            order_items: order_items || [],
            reprint: reprint,
            print_type: 'sale'
        };

        return this.sendOrderToPrinters(orderFromSale, sale);
    }

    public async smartPrintVariationSale(saleVariation: VariationSale, sale: Sales) {
        const localVariation = structuredClone(saleVariation) as PrintableVariation;
        localVariation.print_type = 'order';

        return this.sendOrderToPrinters(localVariation, sale);
    }

    public printNonFiscalSale(sale: Sales, printer: Printers, options: PrintNonFiscalSaleOptions) {
        const localSale = structuredClone(sale) as SaleWithOptions
        localSale.options = structuredClone(options || {})
        localSale.print_type = 'sale_nonfiscal';

        return this.printOrder(localSale, sale, this.getPrinterWithConfig(printer));
    }

    public printSaleTableVariation(tableVariation: TableVariation, sale: Sales) {
        return this.sendOrderToPrinters(structuredClone(tableVariation), this.saleToOrder(sale));
    }

    public smartPrintDifferences(order: Orders) {
        const variationOrder = this.calculateDifferences(order.previous_order, order) as PrintableVariation;
        variationOrder.print_type = "order";

        return this.sendOrderToPrinters(variationOrder, order);
    }

    public smartGoExit(order: Orders, exit: number) {
        const localOrder = structuredClone(order);

        const goExitOrder = Object.assign(localOrder, {
            order_items: localOrder.order_items?.filter((orderItem) => orderItem.exit === exit) || [],
            print_type: 'order',
            goExit: true
        }) as PrintableOrder;

        return this.sendOrderToPrinters(goExitOrder, order);
    }

    public async printDocument(saleToPrint: Sales, printerDocumentData: DocumentPrinterOptions, optionsPrint: any): Promise<[boolean, SalesDocuments[]]> {
        const printer = this.printers[0];

        if (!printer) {
            throw 'INVALID_PRINTER';
        }

        const driverInfo = this.getDriverInfo(printer);

        if (!driverInfo) {
            throw 'INVALID_DRIVER';
        }

        Object.assign(driverInfo.configuration, {
            eReceiptMode: !!(printerDocumentData?.options?.eReceipt)
        });

        const printerDriver = new driverInfo.driverObj(printer.ip_address!, driverInfo.port, driverInfo.configuration);

        const options: any = structuredClone(optionsPrint || {});
        options.columns = printer.columns;

        const documentData = await this.DocumentBuilder.buildDocument(printerDriver, saleToPrint, printerDocumentData, options);

        const result = await new Promise((resolve) => printerDriver.send(resolve));

        if (result !== 'PRINTED' && (!printer.fiscal_provider || optionsPrint.isReprint)) {
            throw result;
        }

        return [(result === 'PRINTED'), [{
            ...documentData,
            date: new Date(),
            printer_serial: undefined
        }]];
    }

    public async printFreeNonFiscal(documentToPrint: string | string[], options?: PrintFreeOptions) {
        const printer = this.printers[0];

        if (!printer) {
            throw 'INVALID_PRINTER';
        }

        const driverInfo = this.getDriverInfo(printer);

        if (!driverInfo) {
            throw 'INVALID_DRIVER';
        }

        const printerDriver = new driverInfo.driverObj(printer.ip_address!, driverInfo.port, driverInfo.configuration);

        if (typeof documentToPrint == 'string') {
            documentToPrint = documentToPrint.split('\n');
        }

        if (!Array.isArray(documentToPrint)) {
            throw 'INVALID_DOCUMENT';
        }

        if (options?.printLogo) {
            printerDriver.addLogo();
        }

        if (options?.printCopyHeader) {
            this.setAlign(printerDriver, 'center');
            printerDriver.addTextDouble(false, false);
            printerDriver.addText("RECEIPT COPY\n\n");
        }

        if (options?.header) {
            this.buildShopHeader(printerDriver, options?.header);
            printerDriver.addTextDouble(false, false);
            this.setAlign(printerDriver, 'left');
        }

        for (const line of documentToPrint) {
            printerDriver.addText(line + '\n');
        }

        if (typeof options?.barcode == 'object') {
            this.setAlign(printerDriver, 'center');

            switch (options.barcode.type) {
                case 'CODE39':
                case 'EAN13':
                default:
                    printerDriver.addText('\n');
                    printerDriver.addBarcode(options.barcode.value, EpsonBarcode[options.barcode.type] || EpsonBarcode.CODE39, EpsonHRI.BELOW, EpsonFont.FONT_B, 2, 66);
                    printerDriver.addText('\n');
                    break;
                case 'QRCODE':
                    printerDriver.addText('\n');
                    printerDriver.addSymbol(options.barcode.value, printerDriver.SYMBOL_QRCODE_MODEL_2, printerDriver.LEVEL_DEFAULT, 8);
                    printerDriver.addText('\n');
                    break;
            }
        }

        printerDriver.addCut();

        const result = await new Promise((resolve) => printerDriver.send(resolve));

        if (result !== 'PRINTED') {
            throw 'CONNECTION_ERROR';
        }
    }

    public async isReachable() {
        const printer = this.printers[0];

        if (!printer) {
            throw 'INVALID_PRINTER';
        }

        const driverInfo = this.getDriverInfo(printer);

        if (!driverInfo) {
            throw 'INVALID_DRIVER';
        }

        const result = await new Promise((resolve) => driverInfo.driverObj.connectAttempt(printer.ip_address!, driverInfo.port, resolve)) as { connected: boolean, ip: string };

        if (!result.connected) {
            throw 'CONNECTION_ERROR';
        }
    }

    public async openCashDrawer() {
        const printer = this.printers[0];

        if (!printer) {
            throw 'INVALID_PRINTER';
        }

        const driverInfo = this.getDriverInfo(printer);

        if (!driverInfo) {
            throw 'INVALID_DRIVER';
        }

        const printerDriver = new driverInfo.driverObj(printer.ip_address!, driverInfo.port, driverInfo.configuration);
        printerDriver.addPulse();

        const result = await new Promise((resolve) => printerDriver.send(resolve));

        if (result !== 'PRINTED') {
            throw 'CONNECTION_ERROR';
        }
    }

    public async displayText(printer: Printers, textLines: string[]) {
        const printerWithConfig = this.getPrinterWithConfig(printer)
        const driverInfo = this.getDriverInfo(printerWithConfig);

        if (!driverInfo) {
            throw 'INVALID_PRINTER';
        }

        if (typeof driverInfo.driverObj.displayText !== 'function') {
            throw 'METHOD_DOES_NOT_EXIST';
        }

        await driverInfo.driverObj.displayText(printerWithConfig, textLines);
    }
}

NonFiscalDriver.$inject = ["$rootScope", "$translate", "$http", "$filter", "errorsLogger", "DocumentBuilder", "EscPosDriver", "EPosDriver", "fiscalUtils", "orderUtils", "util", "entityManager", "checkManager", "KDSManager"];

angular.module('printers').factory('NonFiscalDriver', NonFiscalDriver);