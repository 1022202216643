import { Inject, inject, Injectable } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { DevLogger } from "src/app/shared/dev-logger";
import { BehaviorSubject, lastValueFrom } from "rxjs";
import { MagicPortalDialogComponent } from "../../magic-portal-dialog";
import { NexiDialogComponent } from "./nexi-dialog.component";
import { NexiDialogModel } from "./nexi-dialog.model";
import {NonNullableConfigData} from "@tilby/tilby-ui-lib/components/tilby-dialog";
import {
    AlertDialogService,
    NonNullablePortalConfigData,
    OpenDialogsService,
    PortalConfigData
} from "src/app/dialogs";
import { CustomForm, CustomFormControl, CustomFormControlProps, CustomFormGroup } from "@tilby/tilby-ui-lib/components/tilby-magic-form";
import { Validators } from "@angular/forms";
import { InputItemDataMagicForm } from "src/app/shared/model/model";
import { restManager } from "app/ajs-upgraded-providers";


type NexiDialogFormValue = {secret: string, alias: string, terminal_id: string, group: string | null };
export type NexiDialogForm = CustomFormGroup<CustomForm<NexiDialogFormValue>>;
export type NexiDialogParams = NexiDialogModel;
export type NexiDialogReturn = NexiDialogFormValue;
export type NexiDialogData = InputItemDataMagicForm<NexiDialogFormValue>;

@Injectable({
    providedIn: "root"
})
export class NexiDialogService {
    private readonly alertDialogService = inject(AlertDialogService);
    private readonly openDialogsService = inject(OpenDialogsService);
    private dialogRef?: MatDialogRef<MagicPortalDialogComponent<NexiDialogComponent>>;
    protected _form?: NexiDialogForm;

    constructor(
        @Inject(restManager) private restManagerService: any,
    ){}

    private log(...args: any[]) {
        DevLogger.log("ITEM_LABELS_DIALOG_STATE_SERVICE", ...args);
    }

    // OPEN DIALOG
    public async openDialog(config: NonNullableConfigData<NexiDialogParams>) {
        const { title } = config.data;
        // await this.resolveEntities();
        // this.resetInitialValues();
        // this.init();
        this.createForm();

        const configPortal: NonNullablePortalConfigData<NexiDialogData,NexiDialogComponent> = {
            ...config,
            data: {
                component: NexiDialogComponent,
                titleLabel: title,
                form: this._form!,
                confirmDisabled: () => !this._form?.valid,
                dontCloseDialogOnConfirm$: new BehaviorSubject<boolean>(true),
            },
        };

        this.dialogRef = this.openDialogsService.openPortalDialogRef<NexiDialogComponent, PortalConfigData<InputItemDataMagicForm<NexiDialogFormValue>, NexiDialogComponent>>({
            ...this.openDialogsService.switchMobileDesktopDimensions({
                width: "800px",
            }),
            ...configPortal,
        });

        return lastValueFrom(this.dialogRef.afterClosed()).then((res: PortalConfigData<InputItemDataMagicForm<NexiDialogFormValue>, NexiDialogComponent>) => res ? this._form?.valid && this._form.value : null);
    }

    // FUNCTIONS IN OPEN DIALOG
    private createForm() {
        this._form = new CustomFormGroup<CustomForm<NexiDialogFormValue>>({
            secret: new CustomFormControl('', { validators: [Validators.required] }, {...new CustomFormControlProps(), inputType:'text', label:'Secret', id:'input-secret', class:'tw-w-1/3 '}),
            alias: new CustomFormControl('', { validators: [Validators.required] }, {...new CustomFormControlProps(), inputType:'text', label:'Alias', id:'input-alias', class:'tw-w-1/3 '}),
            terminal_id: new CustomFormControl('', { validators: [Validators.required] }, {...new CustomFormControlProps(), inputType:'text', label:'Terminal Id', id:'input-terminal_id', class:'tw-w-1/3 '}),
            group: new CustomFormControl('', {}, {...new CustomFormControlProps(), inputType:'text', label:'Group', id:'input-group', class:'tw-w-1/3 '}),
        })
    }

    public confirm(formValue: Partial<NexiDialogFormValue>) {
        this.restManagerService.post('alvolo/api/nexi/config', {payload: formValue})
        .then((result:any) => {
            if(result) {
                this.alertDialogService.openDialog({ data: { messageLabel: `SETTINGS.ALVOLO.PAYMENTS.PAYMENTS_CONFIGURATION.SAVED` } })
                this.dialogRef?.close(formValue);
            }
        })
        .catch((error:any) => {
            error.data.error.error.message.includes('Unable to update the nexi configuration of the shop')
            && this.alertDialogService.openDialog({ data: { messageLabel: `SETTINGS.ALVOLO.PAYMENTS.PAYMENTS_CONFIGURATION.ERROR` }});
        })
    }

    get secret() {
        return this._form!.controls.secret;
    }
    get alias() {
        return this._form!.controls.alias;
    }
    get terminal_id() {
        return this._form!.controls.terminal_id;
    }
    get group() {
        return this._form!.controls.group;
    }

    // FUNTIONS IN CREATE FORM
}
