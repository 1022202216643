import { CommonModule } from "@angular/common";
import { Component, Injectable, OnInit, inject } from "@angular/core";
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { BaseDialogService, NonNullableConfigData, TilbyDialogActionButtonsComponent, TilbyDialogContentComponent, TilbyDialogToolbarComponent } from "@tilby/tilby-ui-lib/components/tilby-dialog";
import { TilbyCurrencyPipe } from "@tilby/tilby-ui-lib/pipes/tilby-currency";
import { TilbyDatePipe } from "@tilby/tilby-ui-lib/pipes/tilby-date";
import { lastValueFrom } from "rxjs";
import { ConfigurationManagerService } from "src/app/core";
import { CampgestApiError, CampgestPmsApiService } from "src/app/shared/services/digital-payments/campgest/campgest-pms-api.service";
import { AlertDialogService } from "src/app/dialogs";

export type CampgestCardResponseType = {
    account_id: number,
    card_owner: string,
    card_balance: number,
    credit: number,
    daily_limit: number,
    daily_balance: number,
    checkin_day: Date,
    expiration_day: Date,
    server_time: Date
}

@Component({
    selector: 'app-campgest-psm-payments-dialog',
    standalone: true,
    imports: [CommonModule, TilbyDialogContentComponent, TilbyDialogActionButtonsComponent,
      TilbyDialogToolbarComponent, MatDialogModule, MatIconModule, TilbyDatePipe,
      MatFormFieldModule, FormsModule, TranslateModule, ReactiveFormsModule, MatInputModule, MatButtonModule, TilbyCurrencyPipe],
    templateUrl: './campgest-psm-payments-dialog.component.html',
    styleUrls: ['./campgest-psm-payments-dialog.component.scss']
})
export class CampgestPsmPaymentsDialogComponent implements OnInit {
    private readonly alertDialogService = inject(AlertDialogService);
    private readonly campgestPmsApiService = inject(CampgestPmsApiService);
    private readonly tilbyCurrencyPipe = inject(TilbyCurrencyPipe);
    private readonly transalteService = inject(TranslateService);
    private readonly matDialogRef = inject(MatDialogRef);

    protected readonly data: DataDialogData = inject(MAT_DIALOG_DATA);

    loginForm!: FormGroup;
    searchForm!: FormGroup;

    cardOk = true;
    credentialsOk = true;
    card: CampgestCardResponseType | undefined = undefined;
    cardCode: string = '';

    private readonly configurationManagerService = inject(ConfigurationManagerService);

    status: 'initialize' | 'search-card' | 'confirm-card' | null = null;
    title: string = '';

    constructor(private fb: FormBuilder) {
        this.status = this.configurationManagerService.getPreference('digital_payments.campgest.base_url') ? 'search-card' : 'initialize';
    }

    ngOnInit(): void {
        this.loginForm = this.fb.group({
            baseUrl: ['', Validators.required],
            username: ['', Validators.required],
            password: ['', Validators.required],
            shopCode: ['', Validators.required]
        });

        this.searchForm = this.fb.group({
            cardNumber: ['', Validators.required]
        });
    }

    async onLogin() {
        if (this.loginForm.valid) {

            const response = await this.campgestPmsApiService.checkLoginCredentials({"baseUrl":this.loginForm.value.baseUrl, "aUserName":this.loginForm.value.username, "aPassword":this.loginForm.value.password});

            if (response.Result) {
                this.configurationManagerService.setShopPreference('digital_payments.campgest.base_url', this.loginForm.value.baseUrl);
                this.configurationManagerService.setShopPreference('digital_payments.campgest.username', this.loginForm.value.username);
                this.configurationManagerService.setShopPreference('digital_payments.campgest.password', this.loginForm.value.password);
                this.configurationManagerService.setShopPreference('digital_payments.campgest.shop_code', this.loginForm.value.shopCode);
                this.campgestPmsApiService.refreshConnectionPreferences();
                this.status = 'search-card';
            } else {
                this.credentialsOk = false;
            }
        }
    }

    async onSearchCard() {
        if (this.searchForm.valid) {

            try {
                const response = await this.campgestPmsApiService.checkCard({"aCardCode":this.searchForm.get('cardNumber')?.value});

                this.cardOk=true;
                this.cardCode=this.searchForm.get('cardNumber')?.value;
                this.status = 'confirm-card';
                this.card = response;
            } catch(error : any) {
                if (error instanceof CampgestApiError) {
                    this.cardOk=false;
                  } else {
                    this.alertDialogService.openDialog({
                      data: { messageLabel: error.message }
                    });
                }
            }
        }
    }

    onCardNumberChange() {
        this.cardOk = true;
    }

    getTitle() {
        switch (this.status) {
            case 'initialize':
            case 'search-card':
                return this.transalteService.instant('CASHREGISTER.CAMPGEST_PMS_PAYMENTS.TITLE');
            case 'confirm-card':
                return this.transalteService.instant('CASHREGISTER.CAMPGEST_PMS_PAYMENTS.TITLE_WITH_PAYMENTS') +  ' ' + this.tilbyCurrencyPipe.transform(this.data.amount || 0);
            default:
                return '';
        }
    }

    confirm(): void {
        this.matDialogRef.close(this.cardCode);
    }

}

export type DataDialogData = {amount?:number};

@Injectable({
    providedIn: 'root'
})
export class CampgestPsmPaymentsDialogService extends BaseDialogService {
    private readonly dialogRef = inject(MatDialog);

    public openDialog(data?: DataDialogData){
        if(!data) {
            data={amount:0};
        }
        const config:NonNullableConfigData<any>={...this.switchMobileDesktopDimensions(), disableClose:true, data };
        return lastValueFrom(this.dialogRef.open(CampgestPsmPaymentsDialogComponent,config).afterClosed()).then((res : string | number) => {
            return res ? { cardCode : res } : undefined;
        });
    }
}
