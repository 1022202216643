<ng-container>
    <div class="tw-flex tw-justify-between tw-w-full">
        <div class="tw-flex">
            <div class="tw-flex tw-pl-8">
                <button class="action-button" mat-button (click)="previousDayAction()"><mat-icon>chevron_left</mat-icon></button>
                @if (typeBooking !== 0) {
                    <div style="top:34px; position:relative">
                        <mat-form-field style="width: 0px; height: 0px;">
                            <input matInput [matDatepicker]="picker" (dateChange)="addEvent('change', $event)" [value]="dateBooking">
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <button class="action-button" mat-button (click)="picker.open()"><mat-icon class="tw-p-0 tw-m-0">event</mat-icon></button>
                }
                <div class="tw-flex tw-items-center">{{period}}</div>
                <button class="action-button" mat-button (click)="laterDayAction()"><mat-icon>chevron_right</mat-icon></button>
            </div>
            @if(!isActualDate) {
                <div>
                    <button class="label-button" mat-button (click)="actualDayAction()">{{"BOOKINGS.TOPBAR_CALENDAR.TODAY" | translate}}</button>
                </div>
            }
        </div>
        @if(!isMobile()) {
            <div>
                <button class="label-button" mat-button [matMenuTriggerFor]="belowMenu"><mat-icon>event</mat-icon> {{labelButtonMenu}}</button>
                <mat-menu #belowMenu="matMenu">
                    <button mat-menu-item (click)="menuAction(1)">{{"BOOKINGS.TOPBAR_CALENDAR.LIST" | translate}}</button>
                    <button mat-menu-item (click)="menuAction(0)">{{"BOOKINGS.TOPBAR_CALENDAR.TIMEGRID_WEEK" | translate}}</button>
                    <button mat-menu-item (click)="menuAction(2)">{{"BOOKINGS.TOPBAR_CALENDAR.TIMELINE" | translate}}</button>
                </mat-menu>
            </div>
        }
    </div>
</ng-container>

