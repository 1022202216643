import {
    Component,
    EventEmitter,
    inject,
    Input,
    Output
} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GeneralInputButton} from "@tilby/tilby-ui-lib/models";
import {
    CustomForm,
    CustomFormGroup
} from '@tilby/tilby-ui-lib/components/tilby-magic-form';
import {TilbyGeneralInputButtonComponent} from "@tilby/tilby-ui-lib/components/tilby-buttons";
import {TilbyKeypadDisplayComponent} from "../tilby-keypad-display";
import {MatIcon} from "@angular/material/icon";
import { ScreenOrientationService } from 'src/app/core';

export type TotalFormValue = {
    cashValue:string
}
@Component({
    selector: 'app-tilby-sale-keypad',
    standalone: true,
    imports: [
        CommonModule,
        MatIcon,
        TilbyGeneralInputButtonComponent,
        TilbyKeypadDisplayComponent,
    ],
    templateUrl: './tilby-sale-keypad.component.html',
    styleUrls: ['./tilby-sale-keypad.component.scss']
})
export class TilbySaleKeypadComponent {
    private readonly screenOrientationService = inject(ScreenOrientationService);

    isMobilePortrait = this.screenOrientationService.isMobilePortrait;

    @Input({required: true}) form!: CustomFormGroup<CustomForm<TotalFormValue>>;
    @Input({required: true}) buttons!: GeneralInputButton[];
    @Output() activateSideKeyboard= new EventEmitter<boolean>();

}
