import {
    Component,
    EventEmitter,
    Injector,
    inject,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';

import {
    activeSiteDialog,
    cashlogyDriver,
    ExternalOrdersManager,
    ExternalSalesManager,
    kioskUtils,
    util,
} from 'app/ajs-upgraded-providers';

import {
    ConfigurationFunctions,
    ConfigurationManagerService,
    ConnectionService,
    EntityManagerService,
    EnvironmentInfoService,
    ThemeModeService
} from 'src/app/core';

import {TranslateModule, TranslateService} from '@ngx-translate/core';
import * as _ from 'lodash';
import { User } from 'src/app/models/app';
import {
    AlertDialogService,
    SupportDialogService
} from 'src/app/dialogs';
import {MatButton, MatButtonModule} from '@angular/material/button';
import {MatIconModule} from "@angular/material/icon";
import {CommonModule} from "@angular/common";
import {MatDividerModule} from "@angular/material/divider";
import { PaymentMethods } from 'tilby-models';
import { groupBy } from 'src/app/shared/utils';
import { ChooseCashmaticPaymentMethodDialogService } from 'src/app/dialogs/choose-cashmatic-payment-method-dialog/choose-cashmatic-payment-method-dialog.component';

@Component({
    selector: 'app-nav-list',
    standalone: true,
    templateUrl: './nav-list.component.html',
    imports: [
        MatIconModule,
        MatButtonModule,
        CommonModule,
        MatDividerModule,
        TranslateModule
    ],
    styleUrls: ['./nav-list.component.scss']
})
export class NavListComponent implements OnInit {
    private readonly activeSiteDialogService = inject(activeSiteDialog);
    private readonly alertDialogService = inject(AlertDialogService);
    private readonly cashlogyDriverService = inject(cashlogyDriver);
    private readonly configurationManager = inject(ConfigurationManagerService);
    private readonly connectionService = inject(ConnectionService);
    private readonly entityManager = inject(EntityManagerService);
    private readonly environmentInfoService = inject(EnvironmentInfoService);
    private readonly kioskUtils = inject(kioskUtils);
    private readonly injector = inject(Injector);
    private readonly utilService = inject(util);
    public readonly ordersMan = inject(ExternalOrdersManager);
    public readonly salesMan = inject(ExternalSalesManager);
    public readonly themeModeService = inject(ThemeModeService);
    public readonly translate = inject(TranslateService);
    public readonly chooseCashmaticPaymentMethodDialogService = inject(ChooseCashmaticPaymentMethodDialogService);

    private _currentRoute = '';

    @Input() tilbyVersion: any;
    @Input() country?: string;
    @Input() submenus: any;
    @Input() user?: User;
    @Input() set currentRoute(value: string | { name: string }) {
        const current = (typeof value == 'string') ? value : value.name;
        this._currentRoute = current.split('.').slice(1).join('.') || '';
    };

    @Output() closeLeftMenu = new EventEmitter<void>();
    @Output() goTo = new EventEmitter<string>();

    @ViewChild('settings') settingsButton!: MatButton;
    @ViewChild('automaticPrintSettings') automaticPrintSettingsButton!: MatButton;
    @ViewChild('alvoloSettings') alvoloSettingsButton!: MatButton;

    get currentRoute() {
        return this._currentRoute;
    }

    //Cashdrawers
    cashlogyPaymentMethod?: PaymentMethods;
    cimaPaymentMethod?: PaymentMethods;
    vnePaymentMethod?: PaymentMethods;
    gloryPaymentMethod?: PaymentMethods;
    paymentMethodsCashmatic: PaymentMethods[] = [];

    modulesPermissions = this.configurationManager.getModulesStatus();
    eInvoiceSendMode = this.configurationManager.getSetting('e_invoice.send_mode');

    protected isCurrentRoute(route:string){
        return (<string>this.currentRoute).indexOf(route)>-1;
    }

    async ngOnInit() {
        const paymentMethods = await this.entityManager.paymentMethods.fetchCollectionOffline()
            .then(p => groupBy(p, p => p.payment_method_type_id));
        
        this.paymentMethodsCashmatic = paymentMethods[19] || []; 
        this.cashlogyPaymentMethod = (this.environmentInfoService.canUseTcpSockets() && paymentMethods[21]?.[0]) || undefined;
        this.cimaPaymentMethod = paymentMethods[32]?.[0];
        this.vnePaymentMethod = paymentMethods[38]?.[0];
        this.gloryPaymentMethod = paymentMethods[40]?.[0];
    }

    async goToKiosk() {
        await this.kioskUtils.promptKioskConfig();
        this.goTo.emit('kiosk')
    }

    goToEcommerceSettings() {
        const active = this.configurationManager.getShopPreference('ecommerce.activated');

        if (!active) {
            this.closeLeftMenu.emit();
            // mostro la modale
            this.activeSiteDialogService.show().then((successMessage: string) => {
                if (successMessage) {
                    this.alertDialogService.openDialog({data: {messageLabel:successMessage}})
                        .finally(() => this.goTo.emit('settings.e_commerce'));
                }
            });
        } else {
            this.goTo.emit('settings.e_commerce');
        }
    }

    isAnalyticsEnabled() {
        return this.environmentInfoService.canOpenExternalLinks() && this.configurationManager.getAnalyticsSetting('enable_analytics');
    }

    isConnectionOffline() {
        return this.connectionService.isOffline();
    }

    openAnalytics() {
        this.utilService.openExternalLink('https://analytics.tilby.com');
    }


    async openCashlogyBackoffice() {
        if(!this.cashlogyPaymentMethod) {
            return;
        }

        this.cashlogyDriverService.initialize(this.cashlogyPaymentMethod.schema_name);
        this.cashlogyDriverService.openBackoffice();
    };

    async openCimaBackoffice() {
        if(!this.cimaPaymentMethod) {
            return;
        }

        this.utilService.openExternalLink(`https://${this.cimaPaymentMethod.schema_name}/inlane`);
    };

    async openVneBackoffice() {
        if(!this.vnePaymentMethod) {
            return;
        }

        this.utilService.openExternalLink(`http://${this.vnePaymentMethod.schema_name}/tool/`);
    };

    async openCashmaticBackoffice() {
        if (!this.paymentMethodsCashmatic.length) {
            return;
        }

        let cashmaticPayment: PaymentMethods | undefined;

        if (this.paymentMethodsCashmatic.length === 1) {
            cashmaticPayment = this.paymentMethodsCashmatic[0];
        } else {
            cashmaticPayment = await this.chooseCashmaticPaymentMethodDialogService.openDialog({ data: { paymentMethods: this.paymentMethodsCashmatic } });
        }

        if (!cashmaticPayment) {
            return;
        }

        this.utilService.openExternalLink(`http://${cashmaticPayment.schema_name}/`);
    };

    openPassiveInvoicePortal() {
        if (this.eInvoiceSendMode === 'digitalhub') {
            this.goTo.emit('history.e_invoices_passive');
        } else {
            this.utilService.openExternalLink('https://fatture.scloby.com');
        }
    }

    openPromotedModule(moduleName: string) {
        if (!this.modulesPermissions.shopModules[moduleName]) {
            if (['giftcard'].includes(moduleName) && this.modulesPermissions.shopModules['fidelity']) {
                return this.alertDialogService.openDialog({data: {messageLabel:'SIDENAV.PROMOTED_MODULE_DISABLED_SHOP_2'}});
            }

            return this.alertDialogService.openDialog({data: {messageLabel:'SIDENAV.PROMOTED_MODULE_DISABLED_SHOP'}});
        }  else if (!this.modulesPermissions.enabledModules[moduleName]) {
            return this.alertDialogService.openDialog({data: {messageLabel:'SIDENAV.PROMOTED_MODULE_DISABLED_USER'}});
        }

        switch(moduleName) {
            case 'fidelity':
                //If new customers module is enabled, redirect to fidelity setup if authorized
                if(this.showNewFeature('customers')) {
                    if(this.configurationManager.isFunctionUserEnabledOptin("fidelity.setup")) {
                        this.goTo.emit('fidelity.setup');
                    } else {
                        return this.alertDialogService.openDialog({data: {messageLabel:'SIDENAV.MIGRATION_FIDELITY_SETUP_DISABLED'}});
                    }
                } else {
                    this.goTo.emit('fidelity.search');
                }
            break;
            case 'giftcard':
                const targetState = this.showNewFeature('giftcard') ? 'new.giftcard' : 'giftcard';
                this.goTo.emit(targetState);
            break;
            case 'promotions':
                this.goTo.emit('promotions.showcase');
            break;
        }
    }

    openPurchaseInfo() {
        const params = {
            utm_source: 'scloby-app',
            utm_medium: 'mainmenu',
            utm_campaign: 'Informazioni sull acquisto di Tilby',
            ambiente: this.user?.shop?.name,
            email: this.user?.email || undefined,
            phone: this.user?.phone || undefined,
            nome: this.user?.first_name || undefined,
            cognome: this.user?.last_name || undefined
        };

        const paramsArr: string[] = [];

        _.forEach(params, (value, key) => {
            if (!_.isUndefined(value)) {
                paramsArr.push(key + '=' + _.toString(value));
            }
        });

        this.utilService.openExternalLink('https://tilby.com/prezzi/?' + paramsArr.join('&'));
    }

    async openSupport() {
        this.closeLeftMenu.emit();
        await this.openDialog();
    }

    async openDialog() {
        await this.injector.get(SupportDialogService).openDialog();
    }

    showLink(moduleName: string) {
        return this.modulesPermissions.enabledModules[moduleName];
    }

    showLinkUserEnable(functionName: keyof ConfigurationFunctions) {
        return this.configurationManager.isFunctionUserEnabledOptin(functionName);
    }

    showPurchaseInfo() {
        return this.environmentInfoService.canOpenExternalLinks() && _.startsWith(this.user?.shop?.name, 'demo_') && this.user?.id !== 889;
    }

    showSupport() {
        return this.environmentInfoService.canOpenExternalLinks() && this.user?.id !== 889;
    }

    toggleSubmenu(el: MatButton, value: string) {
        this.submenus[`${value}`] = !this.submenus[`${value}`];

        if (this.submenus[`${value}`]) {
            setTimeout(() => el._elementRef.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' }));
        }
    }

    invokeToggleSubmenuSettings() {
        this.submenus[`settings`] = false;
        this.toggleSubmenu(this.settingsButton, 'settings');
    }

    invokeToggleSubmenuAutomaticPrinter() {
        this.submenus[`automaticPrintSettings`] = false;
        this.toggleSubmenu(this.automaticPrintSettingsButton, 'automaticPrintSettings');
    }

    invokeToggleSubmenuAlvolo() {
        this.submenus[`alvoloSettings`] = false;
        this.toggleSubmenu(this.alvoloSettingsButton, 'alvoloSettings');
    }

    showNewFeature(feature: string): boolean {
        return this.configurationManager.isModuleAngular(feature);
    }

    async execGloryApp() {
        if (!this.environmentInfoService.isElectronApp() || !this.environmentInfoService.isWindows()) {
            this.alertDialogService.openDialog({ data: { messageLabel: 'SETTINGS.GLORY_SETTINGS.NO_ELECTRON_APP' } });
            return;
        }

        try {
            const path = window.require('path');
            const pathAppToExecute = path.join("C:\\sitrade\\CiMax\\Ci.exe");

            await window.require('electron').shell.openPath(pathAppToExecute);
        } catch (error) {
            this.alertDialogService.openDialog({ data: { messageLabel: 'SETTINGS.GLORY_SETTINGS.NO_APP_FOUND' } });
        }
    }
}
