import { CommonModule } from "@angular/common";
import { Component, ElementRef, Inject, OnInit, Renderer2, ViewChild, inject } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { TilbyMagicFormComponent } from "@tilby/tilby-ui-lib/components/tilby-magic-form";
import { CustomFormGroup } from "@tilby/tilby-ui-lib/components/tilby-magic-form/utils/form-group/tilby-custom-form-group";
import { MathUtils } from "@tilby/tilby-ui-lib/utilities";
import { restManager } from "app/ajs-upgraded-providers";
import { ConfigurationManagerService, EnvironmentInfoService } from "src/app/core";
import { MagicFormData } from "src/app/dialogs/magic-form-dialog/magic-form-dialog.component";
import { SharedModule } from "src/app/shared/shared.module";

import {
    ScrollingModule,
    CdkVirtualScrollViewport
} from '@angular/cdk/scrolling';
import { MatTableModule } from "@angular/material/table";
import { GridTableDataSource } from "../../../shared/components/grid-table-datasource/grid-table-datasource.component";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { CustomForm } from "@tilby/tilby-ui-lib/components/tilby-magic-form";
import { firstValueFrom } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { ExternalStock, GenericStock, InternalStock } from "../../dialog.model";
import { CashRegisterStockFormFieldsValue } from "src/app/features/cashregister/services/cashregister.state.service";

export type CashregisterStockDialogData =
    MagicFormData<string, CustomFormGroup<CustomForm<CashRegisterStockFormFieldsValue>>>
    & {searchOnInit?: boolean};

@Component({
    selector: 'app-cashregister-stock',
    standalone: true,
    imports: [CommonModule, MatIconModule, TranslateModule, MatButtonModule, MatDialogModule, TilbyMagicFormComponent, SharedModule, ScrollingModule,
        CdkVirtualScrollViewport, MatTableModule, MatProgressSpinnerModule],
    templateUrl: './cashregister-stock-dialog.component.html',
    styleUrls: ['./cashregister-stock-dialog.component.scss'],
    host:{class:'tw-flex tw-flex-auto tw-justify-end'}
})
export class CashregisterStockDialogComponent implements OnInit {

    placeholderHeight = 0;
    displayedColumns: string[] = [];
    dataSource!: GridTableDataSource; // dataSource!: [];
    itemSize = 48;
    @ViewChild(CdkVirtualScrollViewport) viewport!: CdkVirtualScrollViewport;

    isButtonDisabled = true;
    isCheckExternalStockEnabled = false;
    checkExternalStockUrl : string = '';
    canUseCameraBarcode = this.environmentInfoService.isCameraBarcodeSupported();

    get hasShopId(){
        return !!this.shopId.value;
    };
    get hasSKU() {
        return !!this.sku.value;
    }
    get hasBarcode(){
        return !!this.barcode.value;
    }
    get hasCode(){
        return !!this.code.value;
    }
    get hasName(){
        return !!this.name.value;
    }

    stockDictionaryFilterResult: GenericStock[] = [];
    enabledField= 'barcode';

    hasRunSearch = false;
    searchInProgress = false;
    queryValue = '';
    hasSearchError = false;
    searchError = '';

    stockSettings = {apiUrl: undefined};
    stockMode = "I";
    hiddenColumns: string[] = [];

    constructor(
        public dialogRef: MatDialogRef<CashregisterStockDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: CashregisterStockDialogData,
        protected translateService: TranslateService,
        private configurationManagerService: ConfigurationManagerService,
        private environmentInfoService: EnvironmentInfoService,
        private renderer: Renderer2,
        private el: ElementRef,
        @Inject(restManager) private restManagerService: any,
        private httpClient: HttpClient
    ) {

    }
    ngOnInit(): void {

        this.isCheckExternalStockEnabled = !this.configurationManagerService.getPreference('cashregister.check_external_stock');
        this.checkExternalStockUrl = (this.configurationManagerService.getShopPreference('cashregister.check_external_stock_url') as string) || '';

        this.data.form.valueChanges.subscribe((value) => { this.checkButtonDisabled(); });

    }

    placeholderWhen(index: number, _: any) {
        return index == 0;
    }

    ngAfterViewInit(): void {

        if (this.canUseCameraBarcode) {
            const buttonBarcodeScanner = this.el.nativeElement.querySelector('#barcode-scanner');
            const matFormFieldInputBarcode = this.el.nativeElement.querySelector('#input-barcode');
            if (buttonBarcodeScanner && matFormFieldInputBarcode) {
                this.renderer.appendChild(matFormFieldInputBarcode.parentNode, buttonBarcodeScanner);
            }
        }


        this.addKeyDownListener('#input-shop_id input');
        this.addKeyDownListener('#input-sku input');
        this.addKeyDownListener('#input-barcode input');
        this.addKeyDownListener('#input-code input');
        this.addKeyDownListener('#input-name input');

        this.url?.valueChanges.subscribe((value) => {
            if (value === 'I') {
                this.shopId.customProps.class = "tw-hidden";
                this.code.customProps.class = "tw-inline-flex";
                this.stockMode = "I";
            } else if (value === 'E') {
                this.shopId.customProps.class = "tw-inline-flex";
                this.code.customProps.class = "tw-hidden";
                this.stockMode = "E";
            }
            this.hasRunSearch = false;
        });

        this.dataSource = new GridTableDataSource([], this.viewport, this.itemSize);
        this.dataSource.offsetChange.subscribe(offset => {
            this.placeholderHeight = offset
        })
        if(this.data.searchOnInit) this.search();
        else this.dataSource.allData = [];


    }


    private handleKeyDown(event: KeyboardEvent) {
        if (event.key === 'Enter') {
          event.preventDefault();
          this.search();
        }
      }

    private addKeyDownListener(inputId: string) {
        const inputElement = this.el.nativeElement.querySelector(inputId);
        if (inputElement) {
          inputElement.addEventListener('keydown', this.handleKeyDown.bind(this));
        }
      }

    cancel(){
        this.dialogRef.close();
    };

    async search() {

        try {
            this.searchInProgress = true;
            this.hasSearchError = false;
            this.searchError = '';

            let standardColumns : string[] = [];

            if (this.stockMode === 'I') {
                standardColumns = ['shop_name', 'code', 'barcode', 'name', 'details', 'stock_quantity'];
            } else if (this.stockMode === 'E') {
                standardColumns = ['shop_id', 'shop_name', 'sku', 'barcode', 'name', 'combination_code', 'variation_1', 'variation_2', 'variation_3', 'stock_location', 'quantity'];
            }

            if(!this.formEmpty()) {

                const query = { stock_location_id: 1, shop_id: null, sku: null, code: null, name: null, barcode: null };

                if(this.hasShopId) {
                    query.shop_id = this.queryValue = this.shopId.value;
                }

                if (this.hasSKU) {
                    query.sku = this.queryValue = this.sku.value;
                }

                if (this.hasCode) {
                    query.code = this.queryValue = this.code.value;
                }

                if (this.hasName) {
                    query.name = this.queryValue = this.name.value;
                }

                if (this.hasBarcode) {
                    query.barcode = this.queryValue = this.barcode.value;
                }

                this.stockDictionaryFilterResult = [];
                let results : GenericStock[] = [];

                if (this.stockMode === "I") {
                    results = await this.restManagerService.getList('chain/stock', query);
                } else if (this.stockMode === "E"){
                    results = await firstValueFrom(this.httpClient.get<ExternalStock&any>(this.checkExternalStockUrl, {
                        params: this.getExternalUrlParams()
                    }).pipe());
                }

                if (results.length) {
                    this.hiddenColumns = Object.keys(results[0]).filter(property => {
                        return results.every((item: Partial<GenericStock>) => {
                            let value = item[property as keyof Partial<GenericStock>];
                            return value === null || value === "" || value === undefined;
                        });
                    });

                    const filteredData = results.map((item : any) => {
                        const filteredItem: Record<string, any> = {};
                        for (const key in item) {
                            if (!this.hiddenColumns.includes(key)) {
                                filteredItem[key] = item[key];
                            }
                        }
                        return filteredItem;
                    });

                    this.displayedColumns = standardColumns.filter((column : string)=> !this.hiddenColumns.includes(column));

                    this.stockDictionaryFilterResult = filteredData.sort((a : Record<string, any>, b : Record<string, any>) => a.shop_name.localeCompare(b.shop_name));
                }
            }

            this.dataSource.allData = this.stockDictionaryFilterResult;

        }catch (error : any) {
            this.hasSearchError = true;
            this.searchError = error.data.message;
        } finally {
            this.hasRunSearch = true;
            this.searchInProgress = false;
        }

    }

    getExternalUrlParams(): Record<string, string> {
        const params: Record<string, string> = {};

        if (this.hasShopId) {
            params.shop_id = this.shopId.value;
        }
        if (this.hasSKU) {
            params.sku = this.sku.value;
        }
        if (this.hasCode) {
            params.code = this.code.value;
        }
        if (this.hasName) {
            params.name = this.name.value;
        }
        if (this.hasBarcode) {
            params.barcode = this.barcode.value;
        }

        return params;
    }

    barcodeScanned(barcode: string) {
        const inputElement = this.el.nativeElement.querySelector('#input-barcode input');
        inputElement.value = barcode;
        this.search();
    }

    formEmpty() {
        return !this.hasShopId && !this.hasSKU && !this.hasBarcode && !this.hasCode && !this.hasName;
    };

    checkButtonDisabled() {
        this.isButtonDisabled = !(this.hasSKU || this.hasBarcode || this.hasCode || this.hasName);
    }

    getQuantity(stock : Partial<InternalStock>) {
        if (stock.stock_quantity !== null && stock.stock_quantity !== undefined){
            return MathUtils.round(stock.stock_quantity, 4) + (stock.unit ? ' ' + stock.unit : '');
        } else {
            if (stock.available === 'available') {
                return this.translateService.instant('CASHREGISTER.CHECK_STOCK.AVAILABLE');
            } else if (stock.available === 'unavailable') {
                return this.translateService.instant('CASHREGISTER.CHECK_STOCK.NOT_AVAILABLE');
            } else if (stock.available === 'alert') {
                return this.translateService.instant('CASHREGISTER.CHECK_STOCK.LOW_STOCK');
            }
        }
    };

    getDetails(stock : any){
        var details = (stock.options_values && stock.combination ? stock.options_values + ' ' + stock.combination : stock.options_values || '' + stock.combination + '');

        if((details === null || details === undefined) && (['null', 'undefined'].includes(details))) {
            return details;
        } else {
            return '-';
        }
    };

    get url() {return this.data?.form.controls.url; }
    get shopId() {return this.data?.form.controls.shop_id; }
    get sku() {return this.data?.form.controls.sku; }
    get barcode() {return this.data?.form.controls.barcode; }
    get code() {return this.data?.form.controls.code; }
    get name() {return this.data?.form.controls.name; }

}
