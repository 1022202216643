@if({
    keyboardIsOpenBySelection: (keyboardIsOpenBySelection$|async) || false,
    keyboardIsOpen: (keyboardIsOpen$|async) || false
}; as data){

    @if(!isMobilePortrait()){ <!--DESKTOP VIEW-->
        <div class="max-height tw-h-full tw-flex tw-p-3 tw-gap-2">
            <div class="tw-w-6/12 md:tw-w-7/12 lg:tw-w-8/12 tw-rounded-tl-[10px]" appUpgradeUiViewOne></div>
            <div class="tw-w-6/12 md:tw-w-5/12 lg:tw-w-4/12" appUpgradeUiViewTwo></div>
        </div>
    }
    @else { <!--MOBILE VIEW-->
        @if(!paymentInProgress){
            <app-cashregister-mobile-view [itemsByCategory]="items"/>
        }
        @else {<app-cashregister-payments-mobile-view/>}
    }


    <!--    TILBY-KEYPAD    -->
    @if(showKeyboard && mainKeyboard()){  <!--ALL VIEWS IN CASHREGISTER ( NOT PAYMENTS) -->
        <div class="tw-w-6/12 md:tw-w-7/12 lg:tw-w-8/12 tw-relative tw-z-10" [class.tw-w-screen]="isMobilePortrait()">
            <app-tilby-draggable-bottom-sheet [CLOSED_DRAG_POSITION_Y]="keyboardHeight" [isMobilePotrait]="isMobilePortrait()" [isAccessibility]="isAccessibility" [isOpenBySelection]="data.keyboardIsOpenBySelection" [isOpen]="data.keyboardIsOpen" (isOpenChangeBySelection)="toggleKeyboard($event,true)" (isOpenChange)="toggleKeyboard($event)">
                <app-cashregister-keyboard (addNewItemToSaleEmitter)="addItemToSaleHandler($event)" (activateSideKeyboard)="toggleSideKeyboard($event)"/>
                @if(quickCouponButtons.length){<app-tilby-quick-coupon-buttons [buttons]="quickCouponButtons"/>}
            </app-tilby-draggable-bottom-sheet>
        </div>
    }

    <app-barcode [hideButton]="true" (onSubmitBarcode)="onBarcodeScanned($event)"></app-barcode>
}

