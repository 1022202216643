import {
    Component,
    computed,
    effect,
    inject,
    Injectable,
    signal
} from "@angular/core";
import {
    MAT_DIALOG_DATA,
    MatDialog,
    MatDialogClose,
    MatDialogModule,
    MatDialogRef,
} from "@angular/material/dialog";
import { distinctUntilChanged, lastValueFrom } from "rxjs";
import {
    BaseDialogService,
    NonNullableConfigData,
    TilbyDialogActionButtonsComponent,
    TilbyDialogContentComponent,
    TilbyDialogProgressBarComponent,
    TilbyDialogTabsComponent,
    TilbyDialogToolbarComponent,
} from "@tilby/tilby-ui-lib/components/tilby-dialog";
import { BaseInputButton } from "@tilby/tilby-ui-lib/models";
import {
    TranslateModule,
    TranslateService
} from "@ngx-translate/core";
import { TilbyCurrencyPipe } from "@tilby/tilby-ui-lib/pipes/tilby-currency";
import {
    KeyValue,
    KeyValuePipe
} from "@angular/common";
import {
    ReactiveFormsModule,
    Validators
} from "@angular/forms";
import {
    CustomForm,
    CustomFormControl,
    CustomFormControlProps,
    CustomFormGroup,
    TilbyInputComponent
} from "@tilby/tilby-ui-lib/components/tilby-magic-form";
import { CashregisterKeypadComponent } from "src/app/features/cashregister/components/cashregister-keyboard/cashregister-keypad";
import { TilbyKeypadDisplayComponent } from "src/app/features/cashregister/components/cashregister-keyboard/tilby-keypad-display";
import { subscribeInComponent } from "@tilby/tilby-ui-lib/utilities";
import { MatIcon } from "@angular/material/icon";
import { MatIconButton } from "@angular/material/button";
import { ConfigurationManagerService } from "src/app/core";
import { Items, SalesItems } from "tilby-models";
import { AlertDialogService } from "src/app/dialogs";

export const TIPS_DEFAULT_VALUES = [5, 10, 20];
export type TipValue = number;
export type Tip = KeyValue<string, TipValue> & { isCustom?: boolean };

type TipTotalFormValue = {
    cashValue:string;
}

export type TipDialogData = { finalAmount: number,tipsValues?: number[] };

export type TipDialogConfirm = {
    sku:"auto-tip";
}
& Required<Pick<Items,"department_id"|"price1"|"not_discountable"|"sku"|"name">>
& Pick<SalesItems,"notes">

/**
 * Gestisce l'utilizzo della tip dialog di Tilby.
 */

@Component({
    selector: "app-tip-dialog",
    standalone: true,
    imports: [
        TilbyDialogTabsComponent,
        TilbyDialogProgressBarComponent,
        TilbyDialogContentComponent,
        TilbyDialogActionButtonsComponent,
        TilbyDialogToolbarComponent,
        MatDialogModule,
        TranslateModule,
        TilbyCurrencyPipe,
        KeyValuePipe,
        ReactiveFormsModule,
        TilbyInputComponent,
        CashregisterKeypadComponent,
        TilbyKeypadDisplayComponent,
        MatDialogClose,
        MatIcon,
        MatIconButton,
    ],
    templateUrl: "./tip-dialog.component.html",
    styleUrls: ["./tip-dialog.component.scss"],
    host:{
        class: 'tw-overflow-hidden'
    }
})
export class TipDialogComponent {
    private readonly alertDialogService = inject(AlertDialogService);
    private readonly configurationManagerService = inject(ConfigurationManagerService);
    private readonly matDialogRef = inject(MatDialogRef);
    private readonly tilbyCurrencyPipe = inject(TilbyCurrencyPipe);
    private readonly translateService = inject(TranslateService);
    protected readonly data: TipDialogData = inject(MAT_DIALOG_DATA);


    protected totalValueForm = new CustomFormGroup<CustomForm<TipTotalFormValue>>({
        cashValue: new CustomFormControl('0',
            {
                validators:Validators.pattern(/^\d+(,\d{2})?$/)
        }, {
            ...new CustomFormControlProps(),
            label: 'Total',
            matElementClass: "tw-align-sub tw-text-right",
            readonly: true
        })
    });

    protected tipsChoices = new Map<string,Tip>([
            {key: 'DIALOG.TIP.NO_TIP', value: 0},
            ...(this.data.tipsValues || TIPS_DEFAULT_VALUES).map(tip => ({
                key: `${tip}%`,
                value: this.data.finalAmount * tip / 100
            })),
            {key: 'DIALOG.TIP.CUSTOM_TIP', isCustom: true, value: 0}
        ].map(tip => [tip.key, {...tip, selected: false}])
    );
    protected tipSelected = signal([...this.tipsChoices.values()][Math.floor(this.tipsChoices.size / 2)], {equal: (a,b) => a.key==b.key && a.value==b.value});
    private tipSelectedBeforeCustomSelection=this.tipSelected();
    protected showKeypad = signal(false);
    protected totalAmount = computed(() => this.data.finalAmount + (this.tipSelected()?.value || 0));
    protected totalPlusTip = computed(() => `${this.tilbyCurrencyPipe.transform(this.data.finalAmount)} + ${this.tilbyCurrencyPipe.transform(this.tipSelected().value || 0)}`);
    protected buttons: BaseInputButton[] = [{
        name: "DIALOG.TIP.CONTINUE",
        class: "tilby-body-background-primary-color tw-px-10 tw-py-6 tw-text-2xl",
        click: () => this.closeDialogOnConfirm(),
    }];
    protected keyValueNoSort(){return 0;}

    constructor() {
        subscribeInComponent(
          this.cashValueController.valueChanges.pipe(distinctUntilChanged()),
          value => this.tipSelected.update(tip=>({...tip, value:this.getKeypadValue()}))
        );
        effect(() => this.onFieldChange(`${this.tipSelected().value}`.replace('.', ',')));
    }

    get cashValueController(){
        return this.totalValueForm.controls.cashValue;
    }
    get cashValue(){
        return this.cashValueController.value;
    }
    protected onFieldChange(totalChanged: string) {
        this.cashValueController.setValue(totalChanged,{emitEvent:true});
    }

    private getKeypadValue() {
        return parseFloat(this.cashValue.replace(',', '.'));
    }
    protected selectTip(tip: Tip) {
        if(tip.isCustom){
            this.tipSelectedBeforeCustomSelection = this.tipSelected()
            this.showKeypad.set(true);
        }
        this.tipSelected.set(tip);
    }

    protected closeDialogOnCancel() {
        if(this.showKeypad()){
            this.tipSelected.set(this.tipSelectedBeforeCustomSelection);
            this.showKeypad.set(false);
        }
        else this.matDialogRef.close();
    }
    protected async closeDialogOnConfirm() {
        if (!this.tipSelected().value) {
            return this.closeDialogOnCancel();
        }

        if (this.showKeypad()) {
            this.showKeypad.set(false);
            return;
        }

        const department_id = parseInt(this.configurationManagerService.getPreference('cashregister.tip.department') || '');

        if (!department_id) {
            return await this.alertDialogService.openDialog({
                data: { messageLabel: "DIALOG.TIP.NO_DEPARTMENT" }
            });
        }

        const notes = this.configurationManagerService.getPreference('cashregister.tip.reference_text') 
            || this.translateService.instant('DIALOG.TIP.NO_REFERENCE_TEXT');

        this.matDialogRef.close(<TipDialogConfirm>{
            name: this.translateService.instant("DIALOG.TIP.TIP"),
            department_id,
            notes,
            sku: "auto-tip",
            not_discountable: true,
            price1: this.tipSelected().value
        });
    }
}

@Injectable({
    providedIn: "root",
})
export class TipDialogService extends BaseDialogService {
    private readonly dialogRef = inject(MatDialog);

    public openDialog(config: NonNullableConfigData<TipDialogData>) {
        const data: TipDialogData = config.data;
        const configEdited: NonNullableConfigData<TipDialogData> = {
            ...config,
            ...this.switchMobileDesktopDimensions({width: "90vw", maxWidth: "1200px"}),
            disableClose: true,
            panelClass:"sm:tw-max-w-fit",
            data
        };
        return lastValueFrom(
            this.dialogRef.open<TipDialogComponent, TipDialogData, TipDialogConfirm>(TipDialogComponent, configEdited).afterClosed()
        );
    }
}
