import * as angular from 'angular';
import * as async from 'async';
import * as $ from 'jquery';
import * as _ from 'lodash';
import * as moment from 'moment-timezone';
import * as semver from 'semver';
import { stringToLines } from 'src/app/shared/string-utils';

angular.module('printers').factory('RchRTDriver', ["$q", "checkManager", "fiscalUtils", "rchUtils", "util", "errorsLogger", function($q, checkManager, fiscalUtils, rchUtils, util, errorsLogger) {
    /**
     *  Tilby RCH Driver for Print!F and mini Print!F
     *  
     *  Usage:
     *  RchRTDriver.setup() and after call public methods:
     *  
     *  - autoConfigurePrinter
     *  - printReceiptInvoice
     *  - printFiscalReceipt
     *  - printInvoice
     *  - printCourtesyReceipt
     *  - printNonFiscal
     *  - printOrder
     *  - openCashDrawer
     *  - dailyClosing
     *  - dailyRead
     *  - deposit
     *  - withdrawal
     *  - readDgfe (read/print)
     *  - readDgfeBetween (read/print)
     *  - printFiscalMemory
     *  - printFiscalMemoryBetween
     *  
     *  - printFreeNonFiscal
     *  - printSummaryInvoice
     *  
     *  Specific for this printer
     *  - configureLanBT
     *  - discoverPairedBT
     */
    
    var scope = {};

    var exemptionTable = {
        'N4': 0,
        'N1': 8,
        'N2': 9,
        'N3': 10,
        'N5': 11,
        'N6': 12
    };

    const getVatFieldValue = (vat, field) => parseInt(vat?.getElementsByTagName(field)[0]?.innerHTML || 0);

	var supportsLotteryCode = function(printerInfo) {
        return semver.gte(printerInfo.cpuRel, '5.0.11');
	};

	var isXML7Firmware = function(printerInfo) {
        return (printerInfo.printer_serial?.startsWith('72IV') && semver.gte(printerInfo.cpuRel, '1.0.0')) || semver.gte(printerInfo.cpuRel, '8.0.0');
	};

    /** 
     * @description saleToInvoiceCommands
     * NB: dynamic footer is not available
     *
     * @param sale (sale.sale_customer is required)
     *
     * @param options from printInvoice
     */
    function saleToInvoiceCommands(sale, options) {
        errorsLogger.debug(sale);
        errorsLogger.debug(options);
        var commands = ['=K', '=C1'];

        // Sale Name
        var printName = true;
        if (scope.options.print_name === false) {
            printName = false;
        }
        if (printName && sale.name) {
            commands.push("=\"/(# " + sale.name + " ##)");
        }

        // Customer (required!)        
        if ((sale.sale_customer.first_name && sale.sale_customer.last_name) || (sale.sale_customer.company_name)) {
            commands.push("=A/$1/(" + util.getCustomerCaption(sale.sale_customer) + ")");
            commands.push("=A/$2/(" + sale.sale_customer.billing_street + " " + sale.sale_customer.billing_number + ")");
            commands.push("=A/$3/(" + sale.sale_customer.billing_zip + " " + sale.sale_customer.billing_city + " " + sale.sale_customer.billing_prov + ")");

            if (sale.sale_customer.tax_code) {
                commands.push("=A/$4/(C.F. " + sale.sale_customer.tax_code + ")");
            } else {
                commands.push("=A/$4/()");
            }
            if (sale.sale_customer.vat_code) {
                commands.push("=A/$5/(P.IVA " + sale.sale_customer.vat_code + ")");
            } else {
                commands.push("=A/$5/()");
            }
        }

        commands.push("=F/*4");

        var printDetails = true;
        if (options.print_details === false) {
            printDetails = false;
        }

        // Sale items
        if (printDetails) {
            for(let saleItem of fiscalUtils.extractSaleItems(sale)) {
                Array.prototype.push.apply(commands, rchUtils.saleItemToCommands(saleItem, {
                    printNotes: scope.options.print_notes
                }));
            }
        } else {
            var departmentTotals = fiscalUtils.extractDepartments(sale);
            for (var i in departmentTotals) {
                var cmd = "=R" + departmentTotals[i].id + "/$" + Math.round(departmentTotals[i].amount * 100);
                commands.push(cmd);
            }
        }

        // Print subtotal            
        commands.push("=S");

        // Apply discount/surcharges on subtotal
        if (printDetails) {
            const salePcCommands = rchUtils.applyPriceChanges(sale.price_changes, fiscalUtils.roundDecimals(sale.amount));

            if(!_.isEmpty(salePcCommands)) {
                Array.prototype.push.apply(commands, salePcCommands);
                commands.push("=S");
            }
        }

        // Payments
        _(rchUtils.extractPayments(sale, scope.options.resources)).forEach(function(payment) {
            commands.push([
                '=T' + _.toString(payment.payment_type),
                '$' + _.toString(Math.round(payment.amount * 100)),
                '(' + _.truncate(payment.method_name, { length: 20, omission: '' }) + ')'
            ]);
        });

        commands.push("=c");

        /* Payment tail */
        if (options.tail) {
            var tailCommands = [];

            // Add Payment Tail 
            var tail = options.tail;
            var tails = tail.split("\n");
            if (tails.length) {
                tailCommands.push("=o");
            }
            for (var t in tails) {
                tailCommands.push("=\"/( " + rchUtils.cleanUpSpecialChars(tails[t]) + ")");
            }
            if (tails.length) {
                tailCommands.push("=o");
            }
            _.forEach(tailCommands, function(t) {
                commands.push(t);
            });
            // Another copy for the sign
            if (tail.indexOf('FIRMA') > -1) {
                _.forEach(tailCommands, function(t) {
                    commands.push(t);
                });
            }
        }

        return commands;
    }

    /**     
     *  saleToReceiptCommands
     */
    function saleToReceiptCommands(sale, options, printerInfo) {
        errorsLogger.debug(sale);
        var commands = ['=K', '=C1'];
        var isRefunding;
        var xml7Mode = isXML7Firmware(printerInfo);
        const isRefundVoid = fiscalUtils.isRefundVoidSale(sale);

        if (isRefundVoid) {
			var docDataItem = _.find(sale.sale_items, function (si) {
				return si.reference_sequential_number && si.reference_date;
			});

			var docData = {};

			if (docDataItem) {
				docData = {
					reference_sequential_number: docDataItem.reference_sequential_number,
					reference_date: docDataItem.reference_date
				};
			} else {
				return 'MISSING_REFERENCE_DOCUMENT';
			}

			if (_.every(sale.sale_items, docData)) { //If all of the items are from the same document
				//Check if we are doing a void or a refund
				var hasVoid = false;
                var hasRefund = false;

				_.forEach(sale.sale_items, function (si) {
					if (si.refund_cause_id === 6) {
						hasVoid = true;
					} else {
						hasRefund = true;
					}
				});

				if (hasRefund && hasVoid) {
					return 'MIXED_REFUND_CAUSES';
				} else {
					var docSeqNum = fiscalUtils.parseRTDocumentSequentialNumber(docData.reference_sequential_number);
                    var documentDate = moment(docData.reference_date).format('DDMMYY');

					if (hasVoid) {
                        commands.push(['=k', '&' + documentDate, '[' + docSeqNum.daily_closing_num, ']' + docSeqNum.document_sequential_number ]);
                        return commands;
                    } else {
                        commands.push(['=r', '&' + documentDate, '[' + docSeqNum.daily_closing_num, ']' + docSeqNum.document_sequential_number ]);
                        isRefunding = true;
					}
				}
			} else {
				return 'ITEMS_FROM_DIFFERENT_DOCUMENTS';
			}
        } else {
            if(_.some(sale.sale_items, { type: 'refund' })) {
                return 'REFUNDS_NOT_ALLOWED_IN_SALES';
            }

            // Sale Name
            var printName = (scope.options.print_name === false) ? false : true;

            if(printName) {
                for(let row of fiscalUtils.getFiscalReceiptHeaderLines(sale)) {
                    commands.push(`="/(# ${row} ##)`);
                }
            }

            //Add lottery code if available and supported by the printer firmware (requires version 5.0.11 or higher)
            if(sale.lottery_code) {
				if(supportsLotteryCode(printerInfo)) {
                    commands.push(['="', '?L', '$1', '(' + sale.lottery_code + ')']);
				} else {
					return 'LOTTERY_FW_UPGRADE_REQUIRED';
				}
			}
        }

        var printDetails = (options.print_details === false && !isRefundVoid) ? false : true;

        // Sale items
        if (printDetails || isRefunding) {
            for(let saleItem of fiscalUtils.extractSaleItems(sale)) {
                Array.prototype.push.apply(commands, rchUtils.saleItemToCommands(saleItem, {
                    isRefundingRT: isRefunding,
                    printNotes: scope.options.print_notes,
                    xml7Mode: xml7Mode
                }));
            }
        } else { // Hide details
            var departmentTotals = fiscalUtils.extractDepartments(sale);
            for (var i in departmentTotals) {
                var cmd = "=R" + departmentTotals[i].id + "/$" + Math.round(departmentTotals[i].amount * 100);
                commands.push(cmd);
            }
        }

        
        if(!isRefunding) {
            // Print subtotal            
            commands.push("=S");
    
            // Apply discount/surcharges on subtotal
            if (!isRefundVoid && printDetails) {
                const salePcCommands = rchUtils.applyPriceChanges(sale.price_changes, fiscalUtils.roundDecimals(sale.amount), { xml7Mode: xml7Mode });

                if(!_.isEmpty(salePcCommands)) {
                    Array.prototype.push.apply(commands, salePcCommands);
                    commands.push("=S");
                }
            }

            // Payments
            if (!isRefundVoid) {
                _(rchUtils.extractPayments(sale, scope.options.resources, { xml7Mode: xml7Mode })).forEach(function(payment) {
                    commands.push([
                        '=T' + _.toString(payment.payment_type),
                        '$' + _.toString(Math.round(payment.amount * 100)),
                        '(' + _.truncate(payment.method_name, { length: 20, omission: '' }) + ')'
                    ]);
                });
            } else {
                // If 'preconto'
                commands.push("=T");
            }

            // Barcode
            var printBarcodeId = true;
    
            if (scope.options.print_barcode_id === false) {
                printBarcodeId = false;
            }
            if (printBarcodeId && _.isInteger(sale.id)) {
                commands.push("=\"/$4/(" + sale.id.toString() + ")");
            }

            // Ticket change
            var ticketChange = (scope.options.ticket_change === false) ? false : true;
    
            if (ticketChange && sale.change > 0 && sale.change_type === 'ticket') {
                var ticketChangeAmount = util.round(sale.change);
    
                commands.push("=\"/(################ RESTO TICKET #################)");
                commands.push("=\"/(                                              #)");
                commands.push("=\"/( QUESTO COUPON VALE:               " + ticketChangeAmount + " EURO #)");
                commands.push("=\"/(                                              #)");
                commands.push("=\"/( Presenta alla cassa prima del pagamento questo)");
                commands.push("=\"/( coupon per avere riconosciuto il credito.    #)");
                commands.push("=\"/( Valido 30 giorni dalla data di emissione.    #)");
                commands.push("=\"/( Non convertibile in denaro.                  #)");
                commands.push("=\"/(                                              #)");
                commands.push("=\"/(###############################################)");
                commands.push("=\"/$4/(" + (1212000000000 + Math.round(ticketChangeAmount * 100)) + ")");
            }
    
            // Add Tail (tail for this sale + general tail)
            var tail = options.tail || '';
    
            if (scope.options.tail) {
                tail += "\n" + scope.options.tail;
            }
    
            if (tail) {
                //var tails = tail.match(/.{1,24}/g);
                var tails = tail.split("\n");
                //var tails = tail.match(/.{1,24}/g);
                _.forEach(tails, function(tRow) {
                    commands.push("=\"/( " + rchUtils.cleanUpSpecialChars(tRow) + ")");
                });
            }
    
            var printCustomerDetail = (scope.options.print_customer_detail === false) ? false : true;
    
            // CF
            var customerTaxCode = _.get(sale, "sale_customer.tax_code") || sale.customer_tax_code;

            if (customerTaxCode && fiscalUtils.checkTaxCode(customerTaxCode) && _.isEmpty(sale.lottery_code)) {
                commands.push("=\"/?C/(" + _.toUpper(customerTaxCode) + ")");
            }

            // Customer
            if (printCustomerDetail && sale.sale_customer) {
                var saleCustomer = sale.sale_customer;

                var addCustomerLine = function(line) {
                    commands.push(rchUtils.addTextLine(line));
                };

                var customerInfo = fiscalUtils.getCustomerInfo(saleCustomer);

                _.forEach(customerInfo, addCustomerLine);
            }
    
            // Print Seller
            var printSeller = (scope.options.print_seller === false) ? false : true;
    
            if (printSeller && sale.seller_name) {
                var sellerPrefix = scope.options.seller_prefix || "Ti ha servito";
                commands.push("=\"/( " + sellerPrefix + " " + sale.seller_name.trim().split(" ")[0] + ")");
            }
    
            commands.push("=c");
    
            // Payment tail */
            if (options.tail && options.tail.indexOf('FIRMA') > -1) {
    
                // Add Payment Tail 
                var pTail = options.tail.split("\n");
                if (pTail.length) {
                    commands.push("=o");
    
                    _.forEach(pTail, function(tRow) {
                        commands.push("=\"/( " + rchUtils.cleanUpSpecialChars(tRow) + ")");
                    });
    
                    commands.push("=o");
                }
            }
        } else {
            commands.push('=S', '=T1', '=c');
        }

        return commands;
    }

    	/**
	 * @description saleToNonFiscalCommands
	 */
	function saleToNonFiscalCommands(sale, options) {
        var commands = ['=K', '=C1', '=o'];

		var addLine = function (line) {
            commands.push(rchUtils.addTextLine(line));
		};

        const savePaper = !!checkManager.getPreference('cashregister.save_paper_on_prebill');
        const isRefundVoid = fiscalUtils.isRefundVoidSale(sale);

		// Sale Name (additional header)
		var printName = (savePaper || scope.options.print_name === false) ? false : true;
		var printerColumns = scope.printer.columns || 46;


        if(printName) {
            for(let row of fiscalUtils.getFiscalReceiptHeaderLines(sale)) {
                addLine(row);
            }
        }

		var printDetails = (options.print_details === false) ? false : true;

		// Sale items
		if (printDetails) {
			_.forEach(fiscalUtils.extractSaleItems(sale), function(si) {
				var rowDescription = si.quantity + "x " + (si.name || si.department_name);
				var rowPrice = fiscalUtils.decimalToString(si.price * si.quantity);
				var row = _.padEnd(_.truncate(rowDescription, { length: (printerColumns - rowPrice.length - 2) }), printerColumns - rowPrice.length, " ") + rowPrice;

				addLine(row);

				// Notes
				var printNotes = (scope.options.print_notes === false) ? false : true;

				if (printNotes && si.notes) {
					var notesLines = stringToLines(si.notes, printerColumns);

					_.forEach(notesLines, function (nl) {
						if (nl.trim()) {
							addLine(nl.trim());
						}
					});
				}

				// Discount/Surcharges
				if (si.price_changes) {
					var partialPrice = fiscalUtils.roundDecimals(si.price * si.quantity);

					_(si.price_changes).sortBy('index').forEach(function(pc) {
						var pcAmount = fiscalUtils.getPriceChangeAmount(pc, partialPrice);
						partialPrice = fiscalUtils.roundDecimals(partialPrice + pcAmount);

						if(!_.isNil(pcAmount)) {
							var rowDescription = pc.description;
							var rowAmount = (_.startsWith(pc.type, 'surcharge') ? '+' : '') + pcAmount.toFixed(2).replace(".", ",");
							var row = _.padEnd(_.truncate(rowDescription, { length: (46 - rowPrice.length - 2) }), 46 - rowAmount.length, " ") + rowAmount;

							addLine(row);
						}
					});
				}
			});
		}

		// Sub-total and his discount/surcharges
		var subTotDescr = "SUBTOTALE";
		var subTotAmount = fiscalUtils.decimalToString(sale.amount);
        
        addLine(_.padEnd(subTotDescr, printerColumns - subTotAmount.length, " ") + subTotAmount);

		// Apply discount/surcharges on subtotal
		if (!isRefundVoid && printDetails) {
			var partialPrice = fiscalUtils.roundDecimals(sale.amount);

			_(sale.price_changes).sortBy('index').forEach(function(pc) {
				var pcAmount = fiscalUtils.getPriceChangeAmount(pc, partialPrice);
				partialPrice = fiscalUtils.roundDecimals(partialPrice + pcAmount);

				if(!_.isNil(pcAmount)) {
					var rowDescription = pc.description;
					var rowAmount = (_.startsWith(pc.type, 'surcharge') ? '+' : '') + pcAmount.toFixed(2).replace(".", ",");
					var row = _.padEnd(rowDescription, 46 - rowAmount.length, " ") + rowAmount;

					addLine(row);
				}
			});
		}

		var totDescription = 'TOTALE EURO';
		var totAmount = fiscalUtils.decimalToString(sale.final_amount);
		var totRow = _.padEnd(totDescription, printerColumns - totAmount.length, " ") + totAmount;

		addLine(totRow);

		/*
			No data about:
			- payments
			- customerDetail
		*/

		// Add Tail (tail for this sale + general tail)
		var tailRows = [];

		if (_.isString(options.tail)) {
			tailRows.push('');

			_.forEach(stringToLines(options.tail, printerColumns), function(line) {
				tailRows.push(line);
			});
		}

		if (_.isString(scope.options.tail)) {
			tailRows.push('');

			_.forEach(stringToLines(scope.options.tail, printerColumns), function(line) {
				tailRows.push(line);
			});

			tailRows.push('');
		}

		_.forEach(tailRows, function (row) {
			addLine(row);
		});

        if(!savePaper) {
            addLine("");
            addLine(_.pad("RITIRARE LO SCONTRINO", printerColumns, ' '));
            addLine(_.pad("FISCALE ALLA CASSA", printerColumns, ' '));
            addLine("");
        }

        commands.push('=o');

		// Open cash drawer
		var openCashdrawer = (options.can_open_cash_drawer === false) ? false : true;

		if (openCashdrawer) {
			commands.push('=C86');
        }
        
        return commands;
	}

    async function retryFunction(fn) {
        try {
            return await fn();
        } catch (e) {
            await new Promise((resolve) => setTimeout(resolve, 2000));
            return await fn();
        }
    }

    function parseEJXML(response) {
        try {
            const parsedXml = new DOMParser().parseFromString(response, 'text/xml');
            const EJLines = parsedXml.getElementsByTagName('EJ');
            const textSegments = [];
            
            for(const EJLine of EJLines) {
                textSegments.push(EJLine.textContent || '');
            }

            return textSegments.join('\n');
        } catch (e) {
            // do nothing
        }
    }

    /**     
     *  lastReceipt
     */
    async function lastReceipt(printer) {
        if (!printer) {
            printer = scope.printer;
        }

        const checkXML = rchUtils.cmdToXml(['=C453']);

        await retryFunction(() => setModX(printer));
        const response = await retryFunction(() => rchUtils.sendCommands(printer, checkXML));
        const receipt = parseEJXML(response) || '';

        await retryFunction(() => setModReg(printer));

        if (receipt.trim()) {
            return receipt;
        }
    }

    /**
     * @description getConfiguration     
     */
    function getConfiguration(printer, successFunction, errorFunction) {
        // SET X MODE
        setModX(printer).then(function(status) {
            var checkXml = "<?xml version=\"1.0\" encoding=\"UTF-8\"?><Service><cmd>&lt;&lt;/?C</cmd></Service>";
            // SEND LAST RECEIPT COMMAND
            rchUtils.sendCommands(printer, checkXml).then(function(configuration) {
                // SET REG MODE
                setModReg(printer).then(function(status) {
                    // SEND CONFIGURATION BACK
                    successFunction(configuration);
                }, errorFunction);
            }, errorFunction);
        }, errorFunction);
    }

    /**     
     *  parseReceiptNumber
     */
    function parseReceiptNumber(receipt) {
        var cdNumber = receipt.match(/DOCUMENTO N. (\d{4})-(\d{4})/);

        if(!_.isNull(cdNumber)) {
            return _.toInteger((_.toInteger(cdNumber[1]) * 10000) + _.toInteger(cdNumber[2]));
        }

        var gdNumber = receipt.match(/DOC.GESTIONALE N. (\d{4})-(\d{4})/);

        if(!_.isNull(gdNumber)) {
            return _.toInteger((_.toInteger(gdNumber[1]) * 10000) + _.toInteger(gdNumber[2]));
        }

        return undefined;
    }

    /**
     *  parseInvoiceNumber
     */
    function parseInvoiceData(receipt) {
        // FATTURA N. 503  /  U1 72013281
        // FATTURA N. 504/A  U1 72013281
        var result = {};
        var res = receipt.match(/FATTURA N\. (\d+)\s*\/\w*\s+(.+)\n/);

        if (!_.isNull(res)) {
            _.assign(result, {
                sequential_number: _.toInteger(res[1]) || undefined,
                printer_serial: _.trim(res[2]) || undefined
            });
        }

        return result;
    }

    /**     
     *  parseReceiptPrinterSerial
     */
    function parseReceiptPrinterSerial(receipt) {
        var res = receipt.match(/\*\*\*\s{1}(?!\s{1}).+/g) || receipt.match(/\d{2}[A-Z]{2}\d{7}/);

        if (!_.isNull(res)) {
            var serialString = res.toString().replace("***", "").trim();
            return serialString.substring(3,5) + " " + serialString.substring(0,2) + serialString.substring(5, 12);
        } else {
            return undefined;
        }
    }

    /**
     * parseUnclaimed
     */
    function parseUnclaimed(closing) {
        var unclaimed = 0;
        var resCn = closing.match(/CORR\.NON RISCOSSO\s+(\d+\,\d+)\n/);

        if(resCn) {
            unclaimed = _.toNumber(resCn[1].replace(',', '.'));
        }
 
        return unclaimed;
    }

    /**     
     *  parseReceiptDate
     */
    function parseReceiptDate(receipt) {
        var documentDate = receipt.match(/(\d{2}-\d{2}-\d{4})\s{1,2}(\d{2}:\d{2})/);

        if(!_.isNull(documentDate)) {
            return moment([documentDate[1], documentDate[2]].join(' '), 'DD-MM-YYYY HH:mm').toISOString();
        } else {
            return undefined;
        }
    }

    /**     
     *  parseInvoiceDate
     */
    function parseInvoiceDate(receipt) {
        var invoiceDate = receipt.match(/\d{2}\/\d{2}\/\d{2}\s+\d{2}\:\d{2}/);

        if (!_.isNull(invoiceDate)) {
            return moment(invoiceDate[0], "DD/MM/YY  HH:mm").toISOString();
        } else {
            return undefined;
        }
    }

    /**     
     *  Set X Mode (C2)
     */
    function setModX(printer) {
        return rchUtils.sendCommands(printer, "<?xml version=\"1.0\" encoding=\"UTF-8\"?><Service><cmd>=C2</cmd></Service>");
    }

    /**     
     *  Set Z Mode (C)
     */
    function setModZ(printer) {
        return rchUtils.sendCommands(printer, "<?xml version=\"1.0\" encoding=\"UTF-8\"?><Service><cmd>=C3</cmd></Service>");
    }

    /**     
     *  Set REG mode (C1)
     */
    function setModReg(printer) {
        return rchUtils.sendCommands(printer, "<?xml version=\"1.0\" encoding=\"UTF-8\"?><Service><cmd>=C1</cmd></Service>");
    }

    /**     
     *  configurationToCommands
     *  @param like autoConfigurePrinter parameters
     */
    function configurationToCommands(printerInfo, options) {
        return fiscalUtils.getPrinterConfigurationResources().then(function(resources) {
            var commands = [];
            var xml7Mode = isXML7Firmware(printerInfo);

            // Vat setup
            commands.push("=C4");

            _.forEach(resources.vats, function(v) {
                if(_.inRange(v.id, 1, 8) && _.isEmpty(v.code)) {
                    commands.push(['>>', '?V', '$' + v.id , '*' + (v.value * 100)]);
                } else {
                    errorsLogger.debug("Backend has IVA id:" + v.id + ", skipped");
                }
            });

            var depConfigError;

            // Departments setup
            // Command: Default price | vat.id | name | maximum price=0 | minimum price=0 | auto_close=0 | group_code =1 |
            _.forEach(resources.departments, function(d) {
                var vatId;

                if(!_.isEmpty(d.vat.code)) {
                    vatId = exemptionTable[d.vat.code];
                } else if(_.inRange(d.vat.id, 1, 8)) {
                    vatId = d.vat.id;
                } else {
                    depConfigError = 'OUT_OF_RANGE_VAT';
                    return false;
                }

                if(!_.isNil(vatId)) {
                    var depCommand = ['>R' + d.printer_code, '?A', '$0', '*' + vatId, '(' + d.name + ')', '&0', '[0', ']0', '_1'];

                    if(xml7Mode) {
                        switch(d.sales_type) {
                            case 'goods':
                                depCommand.push('@0');
                            break;
                            case 'services':
                            default:
                                depCommand.push('@1');
                            break;
                        }
                    }

                    commands.push(depCommand);
                } else {
                    depConfigError = 'INVALID_EXEMPTION_CODE';
                    return false;
                }
            });

            if(depConfigError) {
                return depConfigError;
            }

            // Payment methods
            _.forEach(rchUtils.getPaymentsConfig({ xml7Mode: xml7Mode }), function(paymentMethod) {
                commands.push([
                    '>T' + paymentMethod.id,
                    '?A',
                    '$' + _.toInteger(paymentMethod.enableChange),
                    '&' + _.toInteger(paymentMethod.enableSum),
                    '[' + _.toInteger(paymentMethod.unclaimed),
                    ']0', //Open cashdrawer (disabled on the printer, handled by the driver)
                    '^0', //Amount required (disabled)
                    '_' + _.toInteger(paymentMethod.enableTicket),
                    '(' + _.truncate(paymentMethod.name, { length: 20, omission: '' }) + ')'
                ]);
            });

            //Setup Headers
            _.times(13, function(i) {
                var lineNumber = (i + 1);
                commands.push(['>>', '?H', '$' + lineNumber, '(' + _.toString(options['header' + lineNumber]) + ')']);
            });

            //Setup invoice footer
            _.times(6, function(i) {
                var lineNumber = (i + 1);
                commands.push(['>C918', '*1', '$' + lineNumber, '(' + _.toString(options['invoice_footer' + lineNumber]) + ')']);
            });

            // Printer identifier
            if (scope.printer.printer_number) {
                commands.push(['>C132', '$1', '*' + scope.printer.printer_number]);
            }

            // Display message
            if (options.display_message) {
                commands.push(['>>', '?h', '(' + options.display_message + ')']);
            }

            /* Daily stats */
            // /$operatori /*reparti /&finanziari /[fasce orarie /]IVA /_dettagliogruppi /@totale gruppi
            commands.push(['>C117', '$1', '*1', '&1', '[1', ']1', '_0', '@0']);

            /* Invoice setup: comments in italian because of printer's official manual and menus

               - stampa su 1 o 2 righe (0=stampa su una riga, 1=stampa su due righe), 
               - stampa Fattura interna(0=No,1=Si),
               - abilita funzione subtotale (0=No, 1=Si)
               - disabilita controllo dati CLIENTE (0=NO 1=SI) per fattura semplificata
               - Identificatore max. 5 (invoice_prefix)
            */

            commands.push(['>C917', '*1', '&1', ']1', '_1', '(' + (scope.printer.invoice_prefix ? scope.printer.invoice_prefix : '') + ')']);

            /* Stampa bufferizzata */
            commands.push(['>C932', '$1']);
            /* Opzione fidelity */
            commands.push(['>C933', '$1']);
            /* Abilita preconto da tastiera */
            commands.push(['>C934', '$1']);
            /* DGFE Ultimo scontrino */
            commands.push(['>C934', '$1']);
            /* Abilita Nota di credito */
            commands.push(['>C159', '$1']);

            // Back to reg
            commands.push('=C1');

            return commands;
        });
    }

    /**     
     *  Read Daily VAT
     */
    async function readDailyVAT(printer) {
        await setModX(printer);

        const vatXml = `<?xml version="1.0" encoding="UTF-8"?><Service><cmd>=C508/$0</cmd></Service>`;
        const response = await rchUtils.sendCommands(printer, vatXml);
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(response, "text/xml");

        const vats = xmlDoc.getElementsByTagName("VAT");

        let vatStat = {};
        let total = 0;

        //Parse first 4 vats
        for (let i = 1; i <= 4; i++) {
            const vat = vats[i];

            vatStat[`vat_value_${i}`] = util.round(getVatFieldValue(vat, "value") / 100);
            vatStat[`vat_tot_${i}`] = util.round(getVatFieldValue(vat, "totalAmount") / 100);
            vatStat[`vat_taxable_${i}`] = util.round(getVatFieldValue(vat, "netAmount") / 100);
            vatStat[`vat_amount_${i}`] = util.round(getVatFieldValue(vat, "vatAmount") / 100);
            total += vatStat[`vat_tot_${i}`];
        }

        vatStat.total = util.round(total);

        await setModReg(printer);

        return vatStat;
    }


    /**     
     *  Public methods     
     */
    var rchRTDriver = {
        /**
         * @desc setup RCH Print!F printer
         *
         * @param printer, the tilby fiscal printer (must be an RCH Print!F obviously)
         * @param options.print_notes: boolean (default true), if true prints the non-fiscal lines above each sale_item (for ex. variations, or combinations)
         * @param options.print_name: boolean (default true), if true prints the sale.name on top
         * @param options.print_barcode_id: boolean (default true), if true, prints a barcode on bottom that contains the sale.id
         * @param options.ticket_change: boolean (default true), if true enables ticket change
         * @param options.print_customer_detail: boolean (default true), if true print TAX_CODE, FIRST_NAME, LAST_NAME, COMPANY_NAME, VAT_CODE, FIDELITY
         * @param options.print_seller: boolean (default true), if true print seller
         * @param options.seller_prefix: string, customize seller phrase
         * @param options.tail: string, general tail for the receipts
         *

         * @param printer.id: integer, the printer id
         * @param printer.name: string, the printer name
         * @param printer.driver: string, must be 'rch in this case'
         * @param printer.connection_type: string, can be 'ws' (for webservice mode) or 'bt' (for bluetooth mode)
         * @param printer.ip_address: string, printer IP (if using webservice mode)
         * @param printer.mac_address_bt: string, printer MAC ADDRESS BLUETOOTH (if using bluetooth mode)         
         */
        setup: function(printer, options) {
            if (!printer) {
                throw "Printer is undefined";
            } else if (printer.driver !== 'rch') {
                throw "Wrong driver";
            }  else if (!printer.connection_type) {
                throw "Missing connection_type";
            }  else if (!printer.ip_address) {
                throw "Missing ip_address";
            }

            _.assign(scope, {
                printer: printer,
                options: options
            });
        },

        getPrinterStatus: function() {
            var d = $q.defer();
            var result = {};

			rchUtils.isAvailable(scope.printer).then(function(resp) {
                var fpStatus = _.fill(Array(5), '0');

                if(_.isString(resp)) {
                    fpStatus[0] = ($(resp).find("paperEnd").text() === '1' || $(resp).find("coverOpen").text() === '1') ? '3' : '0';
                }

				async.series([function(cb) { //Printer Serial
                    var serialCmd = ['<<', '?m'];

                    rchUtils.sendCommands(scope.printer, rchUtils.cmdToXml([serialCmd])).then(function(response) {
                        result.printer_serial = $(response).find("value").text();
                        cb();
                    }, cb);
                }, function(cb) { //Firmware
                    var fwCmd = ['<<', '?f'];

                    rchUtils.sendCommands(scope.printer, rchUtils.cmdToXml([fwCmd])).then(function(response) {
                        var fwVer = $(response).find("value").text();
                        
                        if(_.isString(fwVer)) {
                            fwVer = fwVer.match(/(\d.\d.\d)/);

                            if(!_.isNil(fwVer)) {
                                result.cpuRel = fwVer[1];
                            }
                        }

                        cb();
                    }, cb);
                }, function(cb) { //Dgfe
                    var dgfeCmd = ['<<', '?i'];

                    rchUtils.sendCommands(scope.printer, rchUtils.cmdToXml([dgfeCmd])).then(function(response) {
                        var dgfe = $(response).find("value").text();
                        
                        if(_.isString(dgfe)) {
                            if(dgfe[4] === '1') {
                                fpStatus[1] = '1';
                            }

                            if(dgfe[5] === '1') {
                                fpStatus[1] = '5';
                            }
                        }

                        cb();
                    }, cb);
                }, function(cb) { //Periodic check
                    var pcCmd = ['<<', '?i', '*2'];

                    rchUtils.sendCommands(scope.printer, rchUtils.cmdToXml([pcCmd])).then(function(response) {
                        var perChk = $(response).find("value").text();

                        result.periodicCheck = '00000000';
                        
                        if(_.isString(perChk)) {
                            if(perChk[2] === '1') {
                                result.periodicCheck = '19900101';
                            }

                            if(perChk[3] === '1') {
                                result.periodicCheck = '19800101';
                            }
                        }

                        cb();
                    }, cb);
                }, function(cb) {
                    var rtStatusCmd = ["<<", "?i", "*3"];

                    if(semver.gte(result.cpuRel, '3.2.0')) {
                        rchUtils.sendCommands(scope.printer, rchUtils.cmdToXml([rtStatusCmd])).then(function(response) {
                            var rtStatus = $(response).find("value").text();

                            if(_.isString(rtStatus)) {
                                if(rtStatus[0] === '0') {
                                    _.assign(result, { rtMainStatus: '01', rtSubStatus: '05' });
                                } else if(rtStatus[1] === '0') {
                                    _.assign(result, { rtMainStatus: '01', rtSubStatus: '06' });
                                } else if(rtStatus[2] === '0') {
                                    _.assign(result, { rtMainStatus: '01', rtSubStatus: '07' });
                                } else {
                                    _.assign(result, { rtMainStatus: '02', rtSubStatus: '08' });
                                }
                            }
                            cb();
                        }, cb);
                    } else {
                        cb();
                    }
                }, function(cb) {
                    var rtInactiveCmd = ["<<", "?i", "*5"];

                    if(semver.gte(result.cpuRel, '3.4.0')) {
                        rchUtils.sendCommands(scope.printer, rchUtils.cmdToXml([rtInactiveCmd])).then(function(response) {
                            var rtInactiveStatus = $(response).find("value").text();

                            if(_.isString(rtInactiveStatus)) {
                                rtInactiveStatus = rtInactiveStatus.match(/(\d{1})\s*(\d{1,2})\/\d{2}/);
                                if(!_.isNull(rtInactiveStatus)) {
                                    _.assign(result, {
                                        rtNoWorkingPeriod: rtInactiveStatus[1],
                                        rtFileToSend: _.padStart(rtInactiveStatus[2], 4, '0')
                                    });
                                }
                            }

                            cb();
                        }, cb);
                    } else {
                        cb();
                    }
                }], function(err, results) {
                    if(err) {
                        d.reject(err);
                    } else {
                        result.fpStatus = fpStatus.join('');
                        d.resolve(result);
                    }
                });
			}, d.reject);

            return d.promise;
		},

        /**
         *  @desc automagically configure RCH Print!F
         *
         *  ( /printers )
         *  @param printer, the printer you want to configure
         *  @param printer.invoice_prefix (from printer)
         *  @param printer.printer_number (from printer)
         *
         *  ( /shop_preferences )
         *  
         *  @param options.header1 (max 48 chars - receipt + invoice)
         *  @param options.header2 (max 48 chars - receipt + invoice))
         *  @param options.header3 (max 48 chars - receipt + invoice))
         *  @param options.header4 (max 48 chars - receipt + invoice))
         *  @param options.header5 (max 48 chars - receipt + invoice))
         *  @param options.header6 (max 48 chars - receipt + invoice))
         *
         *  @param options.header7 (max 48 chars - invoice only)
         *  @param options.header8 (max 48 chars - invoice only)
         *  @param options.header9 (max 48 chars - invoice only)
         *  @param options.header10 (max 48 chars - invoice only)
         *  @param options.header11 (max 48 chars - invoice only)
         *  @param options.header12 (max 48 chars - invoice only)
         *  @param options.header13 (max 48 chars - invoice only)
         *  
         *  @param options.invoice_footer1 (max 48 chars)
         *  @param options.invoice_footer2 (max 48 chars)
         *  @param options.invoice_footer3 (max 48 chars)
         *  @param options.invoice_footer4 (max 48 chars)
         *  @param options.invoice_footer5 (max 48 chars)
         *  @param options.invoice_footer6 (max 48 chars)
         *              
         *  @param options.display_message
         * 
         */
        autoConfigurePrinter: function(printer, options) {
            var d = $q.defer();

            rchRTDriver.getPrinterStatus().then(function(printerInfo) {
                configurationToCommands(printerInfo, options).then(function(commands) {
                    if(!_.isArray(commands)) {
                        d.reject(commands);
                    } else {
                        // Convert commands to xml
                        var setupXml = rchUtils.cmdToXml(commands);

                        // Configuring via WEB SERVICE
                        errorsLogger.debug("Autoconfiguring " + scope.printer.name + " " + scope.printer.ip_address + " via ws...");

                        rchUtils.sendCommands(scope.printer, setupXml).then(function() {
                            getConfiguration(scope.printer, d.resolve, d.reject);
                        }, d.reject);
                    }
                }, d.reject);
            }, d.reject);

            return d.promise;
        },

        /**
         * @desc print a commercial document (also known as 'documento commerciale', for italians)
         *
         * @param sale - the tilby sale you want to print
         * @param options.can_open_cash_drawer - true if user has permission, false otherwise
         * @param options.tail: string, print this string at the end of receipt
         * @param options.print_details: if false, print only department totals and hide single items and discount details
         *
         * @return successFunction with printed sale or errorFunction with errors
         */
        printFiscalReceipt: async function (sale, options, successFunction, errorFunction) {
            const isRefundVoid = fiscalUtils.isRefundVoidSale(sale);

            try {
                // Printing via WEB SERVICE
                const printerInfo = await rchRTDriver.getPrinterStatus();

                await rchUtils.checkPaymentsTable(scope.printer, printerInfo, { xml7Mode: isXML7Firmware(printerInfo) });

                // Build protocol commands
                const commands = saleToReceiptCommands(sale, options, printerInfo);

                if (!Array.isArray(commands)) {
                    throw commands;
                }

                // Open cash drawer
                const openCashdrawer = (options.can_open_cash_drawer === false) ? false : true;

                if (openCashdrawer) {
                    commands.push("=C86");
                }

                let documentType = 'commercial_doc';

                if (isRefundVoid) {
                //We only need to check a single sale item, since the cause check has been done in the saleToReceiptCommands function
                    const saleItemToCheck = sale.sale_items[0];

                    switch (saleItemToCheck.refund_cause_id) {
                        case 6:
                            documentType = "void_doc";
                            break;
                        default:
                            documentType = "refund_doc";
                            break;
                    }
                }

                // Convert commands to xml
                const saleXML = rchUtils.cmdToXml(commands);

                errorsLogger.debug(`Print fiscal receipt on ${scope.printer.name} [${scope.printer.ip_address}] via ws...`);
                errorsLogger.debug(saleXML);

                try {
                    // Print Fiscal Receipt
                    await rchUtils.sendCommands(scope.printer, saleXML);
                } catch (error) {
                    await rchUtils.fixIssues(scope.printer, error);
                }

                try {
                    const receipt = await lastReceipt(scope.printer);

                    if (!receipt) {
                        errorsLogger.debug("Not fiscalized printer, receipt or credit note cannot be retrieved");
                        throw 'NOT_FISCALIZED';
                    }

                    successFunction([{
                        sequential_number: parseReceiptNumber(receipt),
                        date: parseReceiptDate(receipt),
                        document_type: documentType,
                        printer_serial: parseReceiptPrinterSerial(receipt),
                        document_content: receipt
                    }]);
                } catch (err) {
                    successFunction([]);
                } finally {
                    rchUtils.displaySaleTotal(scope.printer, sale);
                }
            } catch (error) {
                if (isRefundVoid && error === 'RCH.ERROR_21') {
                    errorFunction('RT_ALREADY_VOID');
                } else {
                    errorFunction(error);
                }
            }
        },

        /**
         * @desc print an Invoice (also known as 'Fattura', for italians)
         *
         * @param sale - the tilby sale you want to print
         * @param options.can_open_cash_drawer - true if user has permission, false otherwise
         * @param options.tail: string, print this string at the end of receipt
         * @param options.print_details - true if user has permission, false otherwise        
         *
         * @return successFunction with printed sale or errorFunction with errors
         */
        printInvoice: function(sale, options, successFunction, errorFunction) {

            // Check for customer infos
            if (!sale.sale_customer) {
                errorFunction("CUSTOMER_MISSING");
            } else if ((!sale.sale_customer.company_name && !sale.sale_customer.first_name) || (!sale.sale_customer.company_name && !sale.sale_customer.last_name)) {
                errorFunction("CUSTOMER_MISSING_NAME");
            } else if (!sale.sale_customer.billing_street || !sale.sale_customer.billing_number || !sale.sale_customer.billing_zip || !sale.sale_customer.billing_city || !sale.sale_customer.billing_prov) {
                errorFunction("MISSING_BILLING_ADDRESS");
            } else if (!sale.sale_customer.tax_code && !sale.sale_customer.vat_code) {
                errorFunction("MISSING_TAX_VAT_CODE");
            } else {
                // Build protocol commands
                var commands = saleToInvoiceCommands(sale, options);

                const openCashdrawer = (options.can_open_cash_drawer === false) ? false : true;

                // Open cash drawer
                if (openCashdrawer) {
                    commands.push("=C86");
                }

                // Convert commands to xml
                var saleXML = rchUtils.cmdToXml(commands);
                
                var returnDocumentData = function(receipt) {
                    if (!receipt) {
                        errorsLogger.debug("Not fiscalized printer, invoice cannot be retrieved");
                        return successFunction([]);
                    }

                    var invoiceData = parseInvoiceData(receipt);

                    successFunction([{
                        sequential_number: invoiceData.sequential_number,
                        date: parseInvoiceDate(receipt),
                        document_type: 'invoice',
                        printer_serial: invoiceData.printer_serial,
                        document_content: receipt
                    }]);
                };

                rchRTDriver.getPrinterStatus().then(function(printerInfo) {
                    rchUtils.checkPaymentsTable(scope.printer, printerInfo, { xml7Mode: isXML7Firmware(printerInfo) }).then(function() {
                        errorsLogger.debug("Print invoice on a " + scope.printer.name + " " + scope.printer.ip_address + " via ws...");
                        errorsLogger.debug(saleXML);

                        rchUtils.sendCommands(scope.printer, saleXML).then(function() {
                            lastReceipt(scope.printer).then(function (receipt) {
                                rchUtils.displaySaleTotal(scope.printer, sale).finally(function() {
                                    returnDocumentData(receipt);
                                });
                            }, function(error) {
                                errorsLogger.debug("ERROR!!!: Fail to retrieve receipt");
                                successFunction([]);
                            });
                        }, function(error) {
                            rchUtils.fixIssues(scope.printer, error).then(function(message) {
                                lastReceipt(scope.printer).then(function (receipt) {
                                    returnDocumentData(receipt);
                                }, function(error) {
                                    errorsLogger.debug("ERROR!!!: Fail to retrieve receipt");
                                    successFunction([]);
                                });
                            }, function(err) {
                                errorFunction(error);
                            });
                        });
                    }, errorFunction);
                }, errorFunction);
            }
        },


        /**
         * @desc print a courtesy receipt (also known as 'scontrino di cortesia', for italians)
         *
         * @param sale - the tilby sale you want to print
         *
         * @return successFunction with printed sale or errorFunction with errors
         */
        printCourtesyReceipt: function(sale, options, successFunction, errorFunction) {
            // Build protocol commands
            var commands = rchUtils.saleToCourtesyReceiptCommands(sale);

            // Convert commands to xml
            var courtesyXML = rchUtils.cmdToXml(commands);
            errorsLogger.debug(courtesyXML);

            rchUtils.isAvailable(scope.printer).then(function() {
                errorsLogger.debug("Print courtesy receipt on a " + scope.printer.name + " " + scope.printer.ip_address + " via ws...");
                rchUtils.sendCommands(scope.printer, courtesyXML).then(function() {
                    successFunction("OK");
                }, errorFunction);
            }, errorFunction);
        },

        /**
         * @desc print a non fiscal receipt (also known as 'preconto', for italians)
         *
         * @param sale - the tilby sale you want to print         
         *
         * @return successFunction with printed sale or errorFunction with errors
         */
        printNonFiscal: function(sale, options, successFunction, errorFunction) {
            // Build protocol commands
            var commands = saleToNonFiscalCommands(sale, options);

            // Convert commands to xml
            var saleXML = rchUtils.cmdToXml(commands);

            rchUtils.isAvailable(scope.printer).then(function() {
                errorsLogger.debug("Print non fiscal receipt on a " + scope.printer.name + " " + scope.printer.ip_address + " via ws...");
                rchUtils.sendCommands(scope.printer, saleXML).then(function() {
                    successFunction("OK");
                }, errorFunction);
            }, errorFunction);
        },

        /**
         * @desc print an order (also known as 'comanda', for italians)
         *
         * @param order - the tilby sale you want to print         
         *
         * @return successFunction with printed sale or errorFunction with errors
         */
        printOrder: function(order, successFunction, errorFunction) {
            // Build protocol commands
            var commands = rchUtils.orderToCommands(order);

            // Convert commands to xml
            var orderXML = rchUtils.cmdToXml(commands);

            rchUtils.isAvailable(scope.printer).then(function() {
                errorsLogger.debug("Printing order on a " + scope.printer.name + " " + scope.printer.ip_address + " via ws...");
                rchUtils.sendCommands(scope.printer, orderXML).then(function() {
                    successFunction("OK");
                }, errorFunction);
            }, errorFunction);
        },

        /**
         * @desc open cash drawer
         * 
         * @return successFunction if drawer is correctly opened or errorFunction with errors
         */
        openCashDrawer: function(successFunction, errorFunction) {
            var commands = ['=K', '=C86'];

            // Convert commands to xml
            var drawerXML = rchUtils.cmdToXml(commands);

            rchUtils.isAvailable(scope.printer).then(function() {
                errorsLogger.debug("Opening cash drawer on a " + scope.printer.name + " " + scope.printer.ip_address + " via ws...");
                rchUtils.sendCommands(scope.printer, drawerXML).then(function() {
                    successFunction("OK");
                }, errorFunction);
            }, errorFunction);
        },

        /**
         * Send a text to the printer display
         * @param {*} printer the target printer
         * @param {*} textLines an array with the textLines to show
         */
        displayText: async (printer, textLines) => {
            if (!Array.isArray(textLines)) {
                return;
            }

            const commands = ['=K'];
            const dispLineLength = 20;

            for (let i = 1; i <= 2; i++) {
                const line = textLines[i - 1];

                if (typeof line === 'string') {
                    commands.push([`=D${i}/(${rchUtils.cleanUpSpecialChars(line).slice(0, dispLineLength)})`]);
                }
            }

            await rchUtils.sendCommands(printer, rchUtils.cmdToXml(commands));
        },


        /**
         * @desc do a daily closing
         * 
         * @return successFunction  (with the closing receipt in ws mode, or 'OK' in bt mode) if close is correctly done or errorFunction with errors
         */
        dailyClosing: function(successFunction, errorFunction) {
            var commands = ['=K', '=C3', '=C10', '=C1'];
            // Convert commands to xml
            var closingXML = rchUtils.cmdToXml(commands);
            errorsLogger.debug(closingXML);

            rchUtils.isAvailable(scope.printer).then(function() {
                readDailyVAT(scope.printer).then(function(vatStats) {
                    errorsLogger.debug("Daily closing on a " + scope.printer.name + " " + scope.printer.ip_address + " via ws...");
                    rchUtils.sendCommands(scope.printer, closingXML).then(function() {
                        lastReceipt(scope.printer).then(function (receipt) {
                            if (!receipt) {
                                errorsLogger.debug("Not fiscalized printer, daily Closing cannot be retrieved");
                                return successFunction([]);
                            }

                            var dailyClosing = {
                                sequential_number: parseReceiptNumber(receipt),
                                date: parseReceiptDate(receipt),
                                printer_serial: parseReceiptPrinterSerial(receipt),
                                document_content: receipt
                            };

                            dailyClosing = _.merge(dailyClosing, vatStats);
                            dailyClosing.unclaimed = parseUnclaimed(receipt) || 0;

                            errorsLogger.debug(dailyClosing);
                            successFunction(dailyClosing);
                        }, function(error) {
                            errorsLogger.debug("ERROR!!!: Fail to retrieve receipt");
                            successFunction([]);
                        });
                    }, errorFunction);
                }, errorFunction);
            }, errorFunction);
        },

        /**
         * @desc do a daily read
         * 
         * @return successFunction if read is correctly done or errorFunction with errors
         */
        dailyRead: function() {
            var d = $q.defer();
            var commands = ['=K', '=C2', '=C10', '=C1'];
            // Convert commands to xml
            var readXML = rchUtils.cmdToXml(commands);
            errorsLogger.debug(readXML);
            
            rchUtils.isAvailable(scope.printer).then(function() {
                errorsLogger.debug("Daily read on a " + scope.printer.name + " " + scope.printer.ip_address + " via ws...");
                rchUtils.sendCommands(scope.printer, readXML).then(function() {
                    d.resolve();
                }, d.reject);
            }, d.reject);

            return d.promise;
        },

        /**
         * @desc do a cash deposit
         * @param {number} cash to deposit (euro)
         * @return promise
         */
        deposit: function(cash) {
            var d = $q.defer();
            var cashXml = Math.round(cash * 100);
            var commands = ['=K', '=C1', '=e/$' + cashXml];

            // Convert commands to xml
            var depositXML = rchUtils.cmdToXml(commands);
            errorsLogger.debug(depositXML);

            rchUtils.isAvailable(scope.printer).then(function() {
                errorsLogger.debug("Deposit on a " + scope.printer.name + " " + scope.printer.ip_address + " via ws...");
                rchUtils.sendCommands(scope.printer, depositXML).then(function() {
                    d.resolve();
                }, d.reject);
            }, d.reject);

            return d.promise;
        },

        /**
         * @desc do a cash withdrawal
         * 
         * @return promise
         */
        withdrawal: function(cash) {
            var d = $q.defer();
            var cashXml = Math.round(cash * 100);
            var commands = ['=K', '=C1', '=p/$' + cashXml];

            // Convert commands to xml
            var withdrawalXML = rchUtils.cmdToXml(commands);
            errorsLogger.debug(withdrawalXML);
            
            rchUtils.isAvailable(scope.printer).then(function() {
                errorsLogger.debug("Withdrawal on a " + scope.printer.name + " " + scope.printer.ip_address + " via ws...");
                rchUtils.sendCommands(scope.printer, withdrawalXML).then(function() {
                    d.resolve();
                }, d.reject);
            }, d.reject);

            return d.promise;
        },

        /**
         * @desc readDgfe
         * 
         * @param mode can be 'print' or 'read' ('read' mode works only in webservice mode)
         * @return successFunction with dgfe value or 'OK' or errorFunction with errors
         */
        readDgfe: function(mode) {
            var d = $q.defer();
            var modeCode;

            if (mode === 'read') {
                modeCode = '0';
            } else if (mode === 'print') {
                modeCode = '1';
            }

            if (!_.isNil(modeCode)) {
                var commands = ['=C450/$' + modeCode];

                // Convert commands to xml
                var dgfeXML = rchUtils.cmdToXml(commands);
                errorsLogger.debug(dgfeXML);

                rchUtils.isAvailable(scope.printer).then(function() {
                    errorsLogger.debug("Read DGFE on a " + scope.printer.name + " " + scope.printer.ip_address + " via ws...");
                    // C3
                    setModZ(scope.printer).then(function() {
                        // Send DGFE
                        rchUtils.sendCommands(scope.printer, dgfeXML).then(function(response) {
                            switch(mode) {
                                case 'read':
                                    d.resolve(parseEJXML(response));

                                    setModReg(scope.printer).then(function(status) {
                                        errorsLogger.debug("Successfully back to C1");
                                    }, function(error) {
                                        errorsLogger.debug("Error back to C1");
                                    });
                                break;
                                case 'print':
                                    d.resolve();
                                break;
                                default:
                                break;
                            }
                        }, d.reject);
                    }, d.reject);
                }, d.reject);
            } else {
                d.reject("INVALID_MODE");
            }

            return d.promise;
        },

        /**
         * @desc readDgfe
         * 
         * @param mode can be 'print' or 'read' ('read' mode works only in webservice mode)
         * @param from: start date, must be string in format DDMMAA
         * @param to: end date, must be string in format DDMMAA
         *
         * @return promise
         */
        readDgfeBetween: function(mode, from, to) {
            var d = $q.defer();

            var modeCode;

            if (mode === 'read') {
                modeCode = '0';
            } else if (mode === 'print') {
                modeCode = '1';
            }

            if (!_.isNil(modeCode)) {
                var commands = ['=C451/$' + modeCode + "/&" + from + "/[" + to];

                // Convert commands to xml
                var dgfeXML = rchUtils.cmdToXml(commands);
                errorsLogger.debug(dgfeXML);

                rchUtils.isAvailable(scope.printer).then(function() {
                    errorsLogger.debug("Read DGFE (between) on a " + scope.printer.name + " " + scope.printer.ip_address + " via ws...");
                    // C3
                    setModZ(scope.printer).then(function() {
                        // Send DGFE
                        rchUtils.sendCommands(scope.printer, dgfeXML).then(function(response) {
                            switch(mode) {
                                case 'read':
                                    d.resolve(parseEJXML(response));

                                    setModReg(scope.printer).then(function(status) {
                                        errorsLogger.debug("Successfully back to C1");
                                    }, function(error) {
                                        errorsLogger.debug("Error back to C1");
                                    });
                                break;
                                case 'print':
                                    d.resolve();
                                break;
                                default:
                                    //Shouldn't happen
                                break;
                            }
                        }, d.reject);
                    }, d.reject);
                }, d.reject);
            } else {
                d.reject("INVALID_MODE");
            }

            return d.promise;
        },

        /**
         * @desc printFiscalMemory
         * 
         * @return successFunction if withdrawal is correctly done or errorFunction with errors
         */
        printFiscalMemory: function() {
            var d = $q.defer();
            var commands = ['=C400'];

            // Convert commands to xml
            var memoryXML = rchUtils.cmdToXml(commands);
            errorsLogger.debug(memoryXML);

            rchUtils.isAvailable(scope.printer).then(function() {
                errorsLogger.debug("Print fiscal memory on a " + scope.printer.name + " " + scope.printer.ip_address + " via ws...");

                setModZ(scope.printer).then(function(message) {
                    rchUtils.sendCommands(scope.printer, memoryXML).then(function() {
                        d.resolve();
                    }, d.reject);
                }, d.reject);
            }, d.reject);

            return d.promise;
        },
        /**
         * @desc printFiscalMemoryBeetween
         *
         * @param corrispettivi: boolean, if true prints 'corrispettivi' 
         * @param from: start date, must be string in format DDMMAA
         * @param to: end date, must be string in format DDMMAA
         *
         * @return promise
         */
        printFiscalMemoryBetween: function(corrispettivi, from, to) {
            var d = $q.defer();
            // C401 comando stampa memoria fiscale tra data (/&ggmmaa) e data(/[ggmmaa)
            var type = '=C401';
            if (corrispettivi === true) {
                // C403 comando stampa totale corrispettivi della memoria fiscale da data (/&ggmmaa) a data (/[ggmmaa)
                type = '=C403';
            }

            var commands = [type + "/&" + from + "/[" + to];

            // Convert commands to xml
            var memoryXML = rchUtils.cmdToXml(commands);
            errorsLogger.debug(memoryXML);

            rchUtils.isAvailable(scope.printer).then(function() {
                errorsLogger.debug("Print fiscal memory (between) on a " + scope.printer.name + " " + scope.printer.ip_address + " via ws...");
                setModZ(scope.printer).then(function(message) {
                    rchUtils.sendCommands(scope.printer, memoryXML).then(function() {
                        d.resolve();
                        // CANNOT TURN BACK ON C1 VIA WS...
                    }, d.reject);
                }, d.reject);
            }, d.reject);

            return d.promise;
        },

        /**
         * @description printFreeNonFiscal
         */
        printFreeNonFiscal: async (lines, options) => {
            // Convert commands to xml
            let freeXML = rchUtils.cmdToXml(rchUtils.linesToCommands(lines));

            errorsLogger.debug(freeXML);

            await rchUtils.isAvailable(scope.printer);
            errorsLogger.debug(`Print free non-fiscal on a ${scope.printer.name} ${scope.printer.ip_address} via ws...`);
            
            await rchUtils.sendCommands(scope.printer, freeXML);
        },

        /**
         * isReachable
         */
        isReachable: function(successFunction, errorFunction) {
            return rchUtils.isAvailable(scope.printer).then(successFunction, errorFunction);
        },
        readDailyVAT: readDailyVAT
    };

    return rchRTDriver;
}]);